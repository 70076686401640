import SecondaryMenu from './SecondaryMenu';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InfinityScroll, Spinner } from 'src/components';
import Toast from './Toast';
import { useLocalStorage } from 'react-use';
import HeadMenu from 'src/pages/Images/List/HeadMenu';
import { ImageEntity } from 'src/types/image';
import ImageCard from 'src/pages/Images/List/ImageCard';
import { PathEntity } from 'src/types/path';
import FolderCard from 'src/pages/Images/List/FolderCard';
import { useGetImageListInPathLazyQuery } from 'src/services/queries/useGetImageListInPathQuery';
import { useGetPathInfoQuery } from 'src/services/queries/useGetPathInfo';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { GetImageListInPathQueryParams, ImageListInPathSortType, TypeModuleImageEnum } from 'src/types';
import useCurrentProject from 'src/hooks/useCurrentProject';
import { useNextConfirm } from 'src/hooks/useNextConfirm';
import useDeleteImagesMutation from 'src/services/mutations/useDeleteImagesMutation';
import { extractDirectoryList, showUnknowErrorMessage } from 'src/utils';
import useDeleteManyPathsMutation from 'src/services/mutations/useDeleteManyPathMutation';
import { useRecoilState } from 'recoil';
import { uploadedImages, uploadedPaths } from 'src/recoil-stores';
import { AppRoutes } from 'src/helpers';
import { useAlert } from 'src/hooks/useAlert';
import { useGetImageInUseDetail } from 'src/services/queries/useGetImageInUseDetail';
import { useImageQuery } from 'src/hooks/useImageQuery';

export enum FileDataEnum {
  Folder = 'Folder',
  Image = 'Image',
}

export interface FolderData {
  draftId?: string;
  selected?: boolean;
  isDraft?: boolean;
  entity?: PathEntity;
  name?: string;
}

export interface ImageData {
  selected?: boolean;
  entity: ImageEntity;
}

export enum ImageFilterEnum {
  UPLOADED = 'UPLOADED',
  PUBLIC = 'PUBLIC',
  ANNOTATED = 'ANNOTATED',
  UNANNOTATED = 'UNANNOTATED',
}

export enum UPLOAD_FAILED_TYPE {
  FILE_TYPE_ERROR = 'FILE_TYPE_ERROR',
  STORAGE_EXCEEDED = 'STORAGE_EXCEEDED',
}

export interface QueryState extends GetImageListInPathQueryParams {
  direction: 'ASC' | 'DESC';
  imageFilters?: ImageFilterEnum[];
}

export interface ImageListProps {}

export default function ImageList(props: ImageListProps) {
  const { pathId, projectId } = useParams<{ pathId: string; projectId: string }>();

  const [displayType, setDisplayType] = useLocalStorage<'GRID' | 'LIST'>('images_view_type', 'GRID');

  const [{ imageFilters, sort, direction, search }, setQuery] = useImageQuery();

  const [images, setImages] = useState<ImageData[]>([]);
  const [folders, setFolders] = useState<FolderData[]>([]);

  const { data: folderData, isLoading: getPathLoading } = useGetPathInfoQuery(pathId, {
    onSuccess: (res) =>
      setFolders(
        res.children.map((path) => ({
          selected: false,
          isDraft: false,
          entity: path,
        })),
      ),
    onError: (e) => showUnknowErrorMessage(e.message),
  });

  const { mutate: getImages, isLoading: loadingGetImages } = useGetImageListInPathLazyQuery({
    onSuccess: (res) => {
      setImages((images) => [
        ...images,
        ...(res
          ?.filter((r) => !images.find((i) => i.entity.id === r.id))
          .map((item) => ({
            selected: false,
            entity: item,
          })) || []),
      ]);
    },
    onError: (e) => showUnknowErrorMessage(e.message),
  });

  useEffect(() => {
    getImages({
      id: pathId,
    });
  }, [getImages, pathId]);

  const annotatedFilter = imageFilters?.includes(ImageFilterEnum.ANNOTATED);
  const unAnnotatedFilter = imageFilters?.includes(ImageFilterEnum.UNANNOTATED);
  const publicFilter = imageFilters?.includes(ImageFilterEnum.PUBLIC);
  const uploadedFilter = imageFilters?.includes(ImageFilterEnum.UPLOADED);

  const getFilterImages = useMemo(() => {
    return images
      .filter((image) => {
        const result = image.entity.name.toLowerCase().includes(search?.toLowerCase() || '');

        if (annotatedFilter && image.entity.annotated) {
          return result;
        }

        if (unAnnotatedFilter && !image.entity.annotated) {
          return result;
        }

        if (publicFilter && image.entity.public) {
          return result;
        }

        if (uploadedFilter && image.entity.uploaded) {
          return result;
        }

        return !annotatedFilter && !unAnnotatedFilter && !publicFilter && !uploadedFilter && result;
      })
      .sort((a, b) => {
        if (!sort) return 0;

        const dir = direction === 'ASC' ? 1 : -1;

        if (sort === 'name') {
          return a.entity.name.toLocaleLowerCase() > b.entity.name.toLocaleLowerCase() ? dir : -dir;
        }

        if (sort === 'created_at') {
          return new Date(a.entity.created_at || '').getTime() > new Date(b.entity.created_at || '').getTime()
            ? dir
            : -dir;
        }

        return (a.entity.creator || '') > (b.entity.creator || '') ? dir : -dir;
      });
  }, [search, images, sort, direction, annotatedFilter, unAnnotatedFilter, publicFilter, uploadedFilter]);

  const getFilterFolders = useMemo(() => {
    return folders
      .filter((folder) => {
        if (publicFilter || uploadedFilter || annotatedFilter || unAnnotatedFilter) {
          return folder.isDraft;
        }

        return !folder.entity?.name || folder.entity?.name.toLowerCase().includes(search?.toLowerCase() || '');
      })
      .sort((a, b) => {
        if (!sort) return 0;

        const dir = direction === 'ASC' ? 1 : -1;

        if (sort === 'name') {
          return (a.entity?.name.toLocaleLowerCase() || '') > (b.entity?.name.toLocaleLowerCase() || '') ? dir : -dir;
        }

        if (sort === 'created_at') {
          return new Date(a.entity?.created_at || '').getTime() > new Date(b.entity?.created_at || '').getTime()
            ? dir
            : -dir;
        }

        return (a.entity?.created_by || '') > (b.entity?.created_by || '') ? dir : -dir;
      });
  }, [search, folders, sort, direction, annotatedFilter, unAnnotatedFilter, publicFilter, uploadedFilter]);

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (selected: boolean) => {
    setSelectAll(selected);
    setImages(
      images.map((item) => ({
        ...item,
        selected: getFilterImages.find((f) => f.entity.id === item.entity.id) ? selected : item.selected,
      })),
    );
    setFolders(
      folders.map((item) => ({
        ...item,
        selected:
          (getFilterFolders.find((f) => f.entity?.id === item.entity?.id) ? selected : item.selected) && !item.isDraft,
      })),
    );
  };

  useEffect(() => {
    setSelectAll(
      !getFilterFolders.find((folder) => !folder.selected && !folder.isDraft) &&
        !getFilterImages.find((image) => !image.selected) &&
        !![...getFilterFolders, ...getFilterImages].length,
    );
  }, [getFilterFolders, getFilterImages]);

  function handleSortChange(sort?: ImageListInPathSortType) {
    setQuery({ sort });
  }

  function handleImageFilterChange(imageFilters?: ImageFilterEnum[]) {
    setQuery({ imageFilters });
  }

  function handleSortDirectionChange(direction?: 'ASC' | 'DESC') {
    setQuery({ direction });
  }

  function handleSearch(search?: string) {
    setQuery({ search: search?.length ? search : undefined });
  }

  const { data: projectData } = useCurrentProject();
  const history = useHistory();

  const paths = useCallback(() => {
    if (folderData && projectData) return extractDirectoryList(projectData, folderData.id, folderData);
    return [];
  }, [folderData, projectData])().map((path) => ({
    ...path,
    push: () => history.push(AppRoutes.images.list(projectId, path.id)),
  }));

  const { mutate: deleteImages } = useDeleteImagesMutation();
  const { mutate: deletePaths } = useDeleteManyPathsMutation({
    onError: (res) => showUnknowErrorMessage(res.message),
  });

  const { alert } = useAlert();

  const { pathname } = useLocation();

  function reload() {
    history.replace(pathname);
  }

  function alertImageCannotDelete(id: number) {
    getImageInUseInfo(
      {
        id,
      },
      {
        onSuccess: ({ annotations, aimodels, aitests, tasks }) => {
          alert(
            <div className="px-40px text-center text-16px text-#BD0034 py-4">
              The image cannot be deleted because it is in use elsewhere.
              <div className="mt-7 text-12px italic text-black text-left px-4">
                <ul className="list-disc list-outside">
                  {!!tasks.length && (
                    <li>
                      <span className="line-clamp-1">
                        Annotation, Task name: {tasks.map((ann, index) => ann + (tasks[index + 1] ? ', ' : ''))}
                      </span>
                    </li>
                  )}
                  {!!aimodels.length && (
                    <li>
                      <span className="line-clamp-1">
                        Training, AI Model name: {aimodels.map((ann, index) => ann + (aimodels[index + 1] ? ', ' : ''))}
                      </span>
                    </li>
                  )}
                  {!!aitests.length && (
                    <li>
                      <span className="line-clamp-1">
                        Test, Test name: {aitests.map((ann, index) => ann + (aitests[index + 1] ? ', ' : ''))}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>,
            {
              onOk: (close) => {
                close();
                reload();
              },
              onCancel: (close) => {
                close();
                reload();
              },
              popupWidth: 500,
            },
          );
        },
        onError: (res) => showUnknowErrorMessage(res.message),
      },
    );
  }

  function handleDeleteSelected() {
    const imageIds = images.filter((i) => i.selected).map((i) => i.entity.id);
    const pathIds = folders.filter((f) => !f.isDraft && f.selected && f.entity).map((f) => f.entity!.id);

    confirm(
      <div className="text-center pb-60px pt-10px text-16px text-#BD0034">Are you sure you want to delete?</div>,
      {
        onOk: (close) => {
          imageIds.length &&
            deleteImages(
              {
                ids: imageIds,
              },
              {
                onError: (res) => showUnknowErrorMessage(res.message),
                onSuccess: ({ in_use }) => {
                  if (in_use.length) {
                    if (imageIds.length === 1 && !pathIds.length) {
                      alertImageCannotDelete(imageIds[0]);
                    } else {
                      alert(
                        <div className="px-40px text-center text-16px text-#BD0034 py-8">
                          The image cannot be deleted because it is in use elsewhere.
                          <div className="mt-7 text-12px italic text-black">
                            If you try to delete undeleted images individually, you can see where the images are being
                            used.
                          </div>
                        </div>,
                        {
                          onOk: (close) => {
                            close();
                            reload();
                          },
                          onCancel: (close) => {
                            close();
                            reload();
                          },
                          popupWidth: 500,
                        },
                      );
                    }
                  }
                },
              },
            );

          pathIds.length &&
            deletePaths(
              {
                ids: pathIds,
              },
              {
                onError: (res) => showUnknowErrorMessage(res.message),
                onSuccess: ({ in_use }) => {
                  !!in_use.length &&
                    alert(
                      <div className="px-40px text-center text-16px text-#BD0034 py-8">
                        The path cannot be deleted because it has image is in use elsewhere.
                        <div className="mt-7 text-12px italic text-black">
                          If you try to delete undeleted images individually, you can see where the images are being
                          used.
                        </div>
                      </div>,
                      {
                        onOk: (close) => {
                          close();
                          reload();
                        },
                        onCancel: (close) => {
                          close();
                          reload();
                        },
                        popupWidth: 500,
                      },
                    );
                },
              },
            );

          setImages(images.filter((i) => !i.selected));
          setFolders(folders.filter((f) => !f.selected));

          close();
        },
        onCancel: (close) => close(),
        okText: 'Delete',
        cancelText: 'Cancel',
      },
    );
  }

  const { confirm } = useNextConfirm();

  const { mutate: getImageInUseInfo } = useGetImageInUseDetail({ onError: (e) => showUnknowErrorMessage(e.message) });

  function handleDeleteImage(image: ImageData) {
    confirm(
      <div className="text-center pb-60px pt-10px text-16px text-#BD0034">Are you sure you want to delete?</div>,
      {
        onOk: (close) => {
          setImages(images.filter((i) => i.entity.id !== image.entity.id));
          deleteImages(
            {
              ids: [image.entity.id],
            },
            {
              onError: (res) => showUnknowErrorMessage(res.message),
              onSuccess: ({ in_use }) => {
                !!in_use.length && alertImageCannotDelete(image.entity.id);
              },
            },
          );
          close();
        },
        onCancel: (close) => close(),
        okText: 'Delete',
        cancelText: 'Cancel',
      },
    );
  }

  function handleDeleteFolder(folder: FolderData) {
    confirm(
      <div className="text-center pb-60px pt-10px text-16px text-#BD0034">Are you sure you want to delete?</div>,
      {
        onOk: (close) => {
          setFolders(folders.filter((d) => d.entity?.id !== folder.entity?.id));

          deletePaths(
            {
              ids: [folder.entity?.id!],
            },
            {
              onError: (res) => showUnknowErrorMessage(res.message),
              onSuccess: ({ in_use }) => {
                !!in_use.length &&
                  alert(
                    <div className="px-40px text-center text-16px text-#BD0034 py-8">
                      The path cannot be deleted because it has image is in use elsewhere.
                      <div className="mt-7 text-12px italic text-black">
                        If you try to delete undeleted images individually, you can see where the images are being used.
                      </div>
                    </div>,
                    {
                      onOk: (close) => {
                        close();
                        reload();
                      },
                      onCancel: (close) => {
                        close();
                        reload();
                      },
                      popupWidth: 500,
                    },
                  );
              },
            },
          );
          close();
        },
        onCancel: (close) => close(),
        okText: 'Delete',
        cancelText: 'Cancel',
      },
    );
  }

  const [uploadedImageList, setUploadedImageList] = useRecoilState(uploadedImages);
  const [uploadedPathList, setUploadedPathList] = useRecoilState(uploadedPaths);

  useEffect(() => {
    if (uploadedPathList && uploadedPathList.parentId === +pathId) {
      setFolders((fls) => [
        ...fls,
        ...(fls.find((folder) => folder.entity?.id === uploadedPathList.path.id)
          ? []
          : [
              {
                entity: uploadedPathList.path,
              },
            ]),
      ]);

      setUploadedPathList(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathId, uploadedPathList]);

  useEffect(() => {
    if (uploadedImageList && uploadedImageList.parentId === +pathId) {
      setImages((ims) => [
        ...ims,
        ...(ims.find((img) => img.entity.id === uploadedImageList.image.id)
          ? []
          : [
              {
                entity: uploadedImageList.image,
              },
            ]),
      ]);

      setUploadedImageList(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathId, uploadedImageList]);

  const [lastSelectedItem, setLastSelectedItem] = useState<ImageData | FolderData>();

  function handleShiftSelectedChange({
    selected,
    index,
    folder,
    image,
  }: {
    selected: boolean;
    index: number;
    folder?: FolderData;
    image?: ImageData;
  }) {
    const totals = [...getFilterFolders.filter((item) => !item.draftId), ...getFilterImages];

    const shiftIndex = totals.findIndex(
      (f) => f.entity?.id === folder?.entity?.id || f.entity?.id === image?.entity.id,
    );

    const lastIndex = totals.findIndex((f) => f.entity?.id === lastSelectedItem?.entity?.id);

    const updateItems =
      !lastSelectedItem || lastIndex === shiftIndex
        ? [totals[shiftIndex]]
        : totals.slice(
            lastIndex < shiftIndex ? lastIndex : shiftIndex,
            (lastIndex < shiftIndex ? shiftIndex : lastIndex) + 1,
          );

    setImages(
      images.map((i) =>
        !!updateItems.find(
          (u) =>
            u.entity?.id === i.entity.id &&
            u.entity.name === i.entity.name &&
            u.entity.created_at === i.entity.created_at,
        )
          ? { ...i, selected }
          : i,
      ),
    );

    setFolders(
      folders.map((i) =>
        !!updateItems.find(
          (u) =>
            u.entity?.id === i.entity?.id &&
            u.entity?.name === i.entity?.name &&
            u.entity?.created_at === i.entity?.created_at,
        ) && !i.draftId
          ? { ...i, selected }
          : i,
      ),
    );

    setLastSelectedItem(totals[shiftIndex]);
  }

  const indeterminate = useMemo(
    () => !selectAll && !!(getFilterFolders.find((i) => i.selected) || getFilterImages.find((f) => f.selected)),
    [selectAll, getFilterFolders, getFilterImages],
  );

  const [page, setPage] = useState(1);

  return (
    <div
      className="m-auto bg-#F6F6F6 select-none"
      style={{ maxWidth: displayType === 'GRID' ? 1676 : 1188, minWidth: displayType === 'GRID' ? 1676 : 1188 }}
    >
      <div className="pt-60px">
        <div className="px-40px text-24px uppercase font-bold mb-20px">Images</div>
        <div className="bg-white p-40px" style={{ borderRadius: 30 }}>
          <HeadMenu
            paths={paths.map((item) => item.name)}
            selectedItems={{
              folders: folders.filter((folder) => !!folder.selected),
              images: images.filter((images) => !!images.selected),
            }}
            displayType={displayType || 'GRID'}
            onDisplayTypeChange={setDisplayType}
            onNewFolderButtonClick={() =>
              setFolders([
                {
                  isDraft: true,
                  name: '',
                  draftId: nanoid(),
                },
                ...folders,
              ])
            }
            searchString={search}
            onSearchStringChange={handleSearch}
            onDeleteSelected={handleDeleteSelected}
            onCreateNewFolder={(folder) => setFolders((folders) => [folder, ...folders])}
            onCompleteMoveImages={() => setImages((imgs) => imgs.filter((f) => !f.selected))}
            onCompleteMovePaths={() => setFolders((fls) => fls.filter((f) => !f.selected))}
            selectAll={selectAll}
            onSelectAllChange={handleSelectAllChange}
            indeterminate={indeterminate}
          />
          <SecondaryMenu
            selectAll={selectAll}
            onSelectAllChange={handleSelectAllChange}
            imageFilters={imageFilters}
            onImageFilterChange={handleImageFilterChange}
            onSortChange={handleSortChange}
            sort={sort}
            onSortDirectionChange={handleSortDirectionChange}
            sortDirection={direction}
            paths={paths}
            indeterminate={!selectAll && !!(folders.find((f) => f.selected) || images.find((i) => i.selected))}
            isSelectPath={false}
            filterType="IMAGE"
          />
          <div className="mt-20px">
            <InfinityScroll loadMore={() => setPage(page + 1)} hasMore={page * 50 <= getFilterImages.length}>
              <Row gutter={displayType === 'GRID' ? [24, 24] : [0, 24]} style={{ paddingBottom: 50 }}>
                {getFilterFolders.map((folder, index) => (
                  <Col
                    span={displayType === 'GRID' ? undefined : 24}
                    key={'folder_' + (folder.entity?.id || folder.draftId)}
                  >
                    <FolderCard
                      displayType={displayType}
                      folder={folder}
                      onSelectedChange={(selected) => {
                        setFolders(
                          folders.map((d) => (d.entity?.id === folder.entity?.id ? { ...folder, selected } : d)),
                        );
                        setLastSelectedItem(folder);
                      }}
                      onShiftSelectedChange={(selected) => handleShiftSelectedChange({ selected, folder, index })}
                      onFolderChange={({ type, entity }) =>
                        type === 'CANCEL_CREATE'
                          ? setFolders(folders.filter((d) => d.draftId !== entity.draftId))
                          : type === 'EDIT'
                          ? setFolders(folders.map((d) => (d.entity?.id === entity.entity?.id ? entity : d)))
                          : type === 'CANCEL_EDIT'
                          ? setFolders(
                              folders.map((d) => (d.entity?.id === entity.entity?.id ? { ...d, isDraft: false } : d)),
                            )
                          : setFolders(folders.map((d) => (d.draftId === entity.draftId ? entity : d)))
                      }
                      onChangeToggleFolderDraft={(id) =>
                        setFolders(folders.map((f) => (f.entity?.id === id ? { ...f, isDraft: true } : f)))
                      }
                      onDelete={() => handleDeleteFolder(folder)}
                      typeActions={TypeModuleImageEnum.IMAGE}
                    />
                  </Col>
                ))}
                {getFilterImages.slice(0, page * 50).map((image, index) => (
                  <Col span={displayType === 'GRID' ? undefined : 24} key={'image_' + image.entity?.id}>
                    <ImageCard
                      displayType={displayType}
                      image={image}
                      onSelectedChange={(selected) => {
                        setImages(images.map((i) => (i.entity?.id === image.entity?.id ? { ...image, selected } : i)));
                        setLastSelectedItem(image);
                      }}
                      onShiftSelectedChange={(selected) => handleShiftSelectedChange({ selected, image, index })}
                      onUpdateImage={(entity) =>
                        setImages((images) =>
                          images.map((i) => (i.entity?.id === entity.id ? { ...image, entity } : i)),
                        )
                      }
                      onDeleteImage={() => handleDeleteImage(image)}
                      typeActions={TypeModuleImageEnum.IMAGE}
                    />
                  </Col>
                ))}
              </Row>
            </InfinityScroll>
            {(loadingGetImages || getPathLoading) && <Spinner />}
          </div>
          <Toast counts={images.filter((d) => d.selected).length + folders.filter((d) => d.selected).length} />
        </div>
      </div>
    </div>
  );
}
