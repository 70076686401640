import { useQuery, UseQueryOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { AuthEndpoint } from 'src/services/endpoints';
import { RetrieveMyInformationResponse } from 'src/types';

export const queryMeKey = 'retrieve_my_information';

export type RetrieveMyInformationOptions = UseQueryOptions<RetrieveMyInformationResponse>;

export function useMeQuery(options?: RetrieveMyInformationOptions) {
  return useQuery<RetrieveMyInformationResponse>(
    queryMeKey,
    () => request.get(AuthEndpoint.retrieveMyInformation).then((res) => res.data),
    options,
  );
}
