import { Link, NavLink } from 'react-router-dom';
import { useClickOutside } from 'src/hooks';
import { Fragment, useRef, useState } from 'react';
import UserDropdown from '../MainLayout/UserDropdown';
import { AppRoutes } from 'src/helpers';
export interface HeaderProps {}

export default function Header(props: HeaderProps) {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const detailContainerRef = useRef<HTMLDivElement>(null);

  useClickOutside(detailContainerRef, () => isDetailOpen && setIsDetailOpen(false));

  const NavList = [
    {
      label: 'Profile',
      href: AppRoutes.myPage.account,
    },
    {
      label: 'Plan',
      href: AppRoutes.myPage.plan,
    },
    {
      label: 'Billing',
      href: AppRoutes.myPage.billing,
    },
  ];

  return (
    <Fragment>
      <div className="sticky top-0 left-0 w-full z-50" style={{ minWidth: 1024 }}>
        <div className="h-74px flex bg-white">
          <div className="w-82px flex-center flex-shrink-0">
            <Link to={AppRoutes.discover.home}>
              <img src="/v2/logo/Icon-x4.png" className="w-40px relative top-1" alt="logo" />
            </Link>
          </div>
          <div className="bg-#1C3F49 pl-40px pr-80px flex justify-between items-center flex-grow">
            <div className="flex items-center space-x-4">
              <span className="text-36px font-medium text-white whitespace-nowrap">ACCOUNT</span>
            </div>
            <div className="flex items-center">
              <div className="text-36px font-medium mr-30px text-white">
                <Link to={AppRoutes.discover.product} className="hover:text-white">
                  PRODUCTS
                </Link>
              </div>
              <div className="text-36px font-medium mr-30px text-white">
                <Link to={AppRoutes.discover.plan} className="hover:text-white">
                  PLANS
                </Link>
              </div>
              <div className="text-36px font-medium mr-74px text-white">
                <Link to={AppRoutes.discover.support} className="hover:text-white">
                  SUPPORT
                </Link>
              </div>
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed top-74px left-0 w-full z-40" style={{ minWidth: 875 }}>
        <div className="h-65px bg-#E4E4E4 pl-140px">
          <ul className="flex m-0 h-full">
            {NavList.map((item) => (
              <li key={item.label} className="text-24px cursor-pointer hover:text-white hover:bg-#00BEEB font-light">
                <NavLink
                  to={item.href}
                  className="text-inherit block px-20px h-full pt-4 uppercase text-center"
                  activeClassName="text-white bg-#00BEEB"
                  style={{ width: 131 }}
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Fragment>
  );
}
