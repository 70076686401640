import { ArrowUp } from 'akar-icons';
import React, { useEffect, useState } from 'react';

export interface SortDirectionProps {
  onSortDirectionChange: (val?: 'ASC' | 'DESC') => void;
  direction?: 'ASC' | 'DESC';
}

export default function SortDirection({ onSortDirectionChange, direction: dp = 'ASC' }: SortDirectionProps) {
  const [direction, setDirection] = useState<'ASC' | 'DESC'>(dp);

  useEffect(() => {
    onSortDirectionChange(direction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  return (
    <div
      className="w-32px h-32px flex-center border border-#CCCCCC cursor-pointer select-none hover:bg-#F5F5F5"
      onClick={() => setDirection(direction === 'ASC' ? 'DESC' : 'ASC')}
    >
      <ArrowUp
        className={`w-4 text-#CCCCCC transform ${direction === 'ASC' ? 'rotate-0' : 'rotate-180'} transition-all`}
      />
    </div>
  );
}
