import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useConfirm } from 'src/hooks';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useUILogin } from '../context/modal';
import { RULE_EMAIL, RULE_PASSWORD } from '../helper/validate';
import useLoginMutation from 'src/services/mutations/useLoginMutation';
import { LoginInput, RetrieveMyInformationResponse } from 'src/types';
import { AppRoutes } from 'src/helpers';
import { EnvelopeSVGP, EyeSlashSVGP, EyeSVGP, LockSVGP } from 'src/components/icons';
import useQueryString from 'src/hooks/useQuerystring';
import { queryMeKey } from 'src/services/queries/useMeQuery';
import '../styles/index.less';
import { Button } from 'src/components';
import { Form } from 'antd';
import { Input } from 'antd';

const SignInForm: React.FC = () => {
  const [form] = Form.useForm<LoginInput>();
  const [fields, setFields] = useState<LoginInput>({
    email: '',
    password: '',
  });
  const { openModal, setModalView } = useUILogin();
  const { confirm } = useConfirm();

  const client = useQueryClient();

  const { redirect } = useQueryString<{ redirect: string }>();

  const { mutate, isLoading } = useLoginMutation({
    onError: (error) => {
      if (error.response?.data?.message === 'Invalid status') {
        confirm(
          <div className="text-center text-#00BEEB">
            Please activate your account before signing in. <br />
            <br />
            Lost your activation email?
            <br />
            {`Don't worry, we sent a new one for you!`}
            <br />
            Check your email and activate your account.
          </div>,
        );
      } else
        confirm(
          <div className="text-center text-#00BEEB">{`Your E-mail and password didn't match. Please try again.`}</div>,
        );
    },
    onSuccess: (res) => {
      client.setQueryData<RetrieveMyInformationResponse>(queryMeKey, res);
      window.location.href = redirect || AppRoutes.projectList; // TODO: workaround for refresh csrf token
    },
  });

  const openForgotPassword = () => {
    setModalView('FORGOT_PASSWORD');
    openModal();
  };

  const handleFinishFailed = ({ errorFields }: ValidateErrorEntity<LoginInput>) => {
    confirm(<div className="text-center text-#00BEEB">{errorFields?.[0]?.errors?.[0]}</div>);
  };

  return (
    <Form
      form={form}
      onFinish={mutate}
      className="form-custom"
      validateTrigger={false}
      onFinishFailed={handleFinishFailed}
      onValuesChange={(_, allValues) => {
        setFields(allValues);
      }}
    >
      <Form.Item name="email" rules={RULE_EMAIL} noStyle>
        <Input
          prefix={<EnvelopeSVGP />}
          placeholder="Email"
          className={`${fields?.email?.length > 0 ? 'selected' : ''}`}
          bordered={false}
        />
      </Form.Item>
      <Form.Item name="password" rules={RULE_PASSWORD} noStyle>
        <Input.Password
          prefix={<LockSVGP />}
          placeholder="Password"
          className={`mb-14px ${fields?.password?.length > 0 ? 'selected' : ''}`}
          bordered={false}
          iconRender={(visible) => (visible ? <EyeSVGP /> : <EyeSlashSVGP />)}
        />
      </Form.Item>
      <div className="link" onClick={openForgotPassword}>
        <span className="cursor-pointer">Forgot password?</span>
      </div>
      <Form.Item className="text-center max-w-120px mx-auto button-wrapper">
        <Button
          shape="round"
          type="_00BEEB"
          htmlType="submit"
          className="uppercase text-12px"
          loading={isLoading}
          disabled={isLoading}
          block
        >
          Sign in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignInForm;
