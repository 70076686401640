import { useMutation, UseMutationOptions } from 'react-query';
import { PathEndpoint } from '../endpoints';
import { APIErrorResponse, DeleteManyPathsInput, DeleteManyPathsResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { stringify } from 'qs';

export type DeleteManyPathsMutationOptions = UseMutationOptions<
  DeleteManyPathsResponse,
  APIErrorResponse,
  DeleteManyPathsInput
>;

export default function useDeleteManyPathsMutation(options?: DeleteManyPathsMutationOptions) {
  return useMutation<DeleteManyPathsResponse, APIErrorResponse, DeleteManyPathsInput>(
    (params) =>
      request
        .delete(PathEndpoint.deletePaths, {
          params,
          paramsSerializer: (params) => {
            return stringify(params, { arrayFormat: 'comma' });
          },
        })
        .then((res) => res.data),
    options,
  );
}
