import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AppRoutes } from 'src/helpers';
import { QueryState } from 'src/pages/Images/List';

type ContextTypes = {
  setState: React.Dispatch<Partial<QueryState>>;
  state: QueryState;
};

const ImageQueryContext = createContext<ContextTypes>({
  state: {
    id: '',
    direction: 'ASC',
  },
  setState: () => {},
});

type Props = {
  children: ReactNode;
};

export const ImageQueryProvider = ({ children }: Props) => {
  const [state, setState] = useReducer(
    (current: QueryState, next: Partial<QueryState>) => ({
      ...current,
      ...next,
    }),
    {
      id: '',
      direction: 'ASC',
    },
  );

  const matchImage = useRouteMatch(AppRoutes.images.list());
  const matchAnnotation = useRouteMatch(AppRoutes.annotation.task.selectImages());

  useEffect(() => {
    if (!matchImage) {
      setState({
        id: '',
        direction: 'ASC',
        filter: undefined, // annotated filter
        search: undefined,
        sort: undefined,
        imageFilters: [],
      });
    }
  }, [matchImage]);

  useEffect(() => {
    if (!matchAnnotation) {
      setState({
        id: '',
        direction: 'ASC',
        filter: undefined,
        search: undefined,
        sort: undefined,
        imageFilters: [],
      });
    }
  }, [matchAnnotation]);

  return <ImageQueryContext.Provider value={{ state, setState }}>{children}</ImageQueryContext.Provider>;
};

export const useImageQuery = (): [QueryState, React.Dispatch<Partial<QueryState>>] => {
  const { state, setState } = useContext(ImageQueryContext);

  return [state, setState];
};
