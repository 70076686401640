import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { SignInForm, SignUpForm } from '../elements';
import useQueryString from 'src/hooks/useQuerystring';
import { QUERY_LOGIN, ACTION_LOGIN } from 'src/pages/Login/helper/type';
import { useUILogin } from 'src/pages/Login/context/modal';
import '../styles/tabs.less';
import { useNavigation } from 'src/hooks';

const { TabPane } = Tabs;

export const LoginTab: React.FC = () => {
  const query = useQueryString<QUERY_LOGIN>();
  const { openModal, setModalView, setModalAction } = useUILogin();

  useEffect(() => {
    const { action } = query;
    switch (action) {
      case ACTION_LOGIN.VERIFY: {
        setModalView('ACTIVATE');
        setModalAction({ width: 1050, closable: false });
        openModal();
        break;
      }
      case ACTION_LOGIN.RESET_PASSWORD: {
        setModalView('FORGOT_PASSWORD');
        setModalAction({ width: 950, closable: false });
        openModal();
        break;
      }
      case ACTION_LOGIN.RESET_PASSWORD_CONFIRM: {
        setModalView('RESET_PASSWORD');
        setModalAction({ width: 950, closable: false });
        openModal();
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { navigate } = useNavigation<{ tab: string }>();
  const { tab = 'SIGN_IN' } = useQueryString<{ tab: string }>();

  return (
    <div className="auth-content-wrapper">
      <div className="custom-tabs">
        <Tabs activeKey={tab} centered onChange={(tab) => navigate({ tab })} size="large">
          <TabPane tab="Sign In" key="SIGN_IN">
            <SignInForm />
          </TabPane>
          <TabPane tab="Sign Up" key="SIGN_UP">
            <SignUpForm />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
