import { useMutation, UseMutationOptions } from 'react-query';
import { ImageEndpoint } from '../endpoints';
import { APIErrorResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { ImageEntity } from 'src/types/image';

export type EditImageNameResponse = ImageEntity;

export interface EditImageNameInput {
  name: string;
  id: number | string;
}

export type EditImageNameMutationOptions = UseMutationOptions<
  EditImageNameResponse,
  APIErrorResponse,
  EditImageNameInput
>;

export default function useEditImageNameMutation(options?: EditImageNameMutationOptions) {
  return useMutation<EditImageNameResponse, APIErrorResponse, EditImageNameInput>(
    ({ id, name }) =>
      request
        .post(ImageEndpoint.edit_image_name(id), {
          name,
        })
        .then((res) => res.data),
    options,
  );
}
