import React, { FC, memo, SVGProps } from 'react';
interface Props extends SVGProps<SVGSVGElement> {}

const UserSVGP: FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" {...props}>
      <g transform="translate(-4 -4)">
        <ellipse id="Ellipse_41" data-name="Ellipse 41" cx="3.5" cy="4" rx="3.5" ry="4" transform="translate(7.5 4)" />
        <path
          d="M18,19v1a.944.944,0,0,1-.875,1H4.875A.944.944,0,0,1,4,20V19c0-3.314,2.35-6,5.25-6h3.5C15.65,13,18,15.686,18,19Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export default memo(UserSVGP);
