import { ReactNode } from 'react';
import Header from './Header';

export interface MainLayoutProps {
  children: ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
  return (
    <div>
      <Header />
      <div className="pt-65px">{children}</div>
    </div>
  );
}
