import { ArrowLeft } from 'akar-icons';
import { useHistory } from 'react-router-dom';

export interface SimpleBackButtonProps {
  onClick?: () => void;
  isLoading?: boolean;
}

function SimpleBackButton({ onClick, isLoading }: SimpleBackButtonProps) {
  const history = useHistory();

  return (
    <div
      className={`border-#AAAAAA border rounded-full w-32px h-32px flex-center select-none ${
        isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={() => !isLoading && (onClick ? onClick() : history.goBack())}
    >
      <ArrowLeft className="text-#AAAAAA w-20px h-20px" />
    </div>
  );
}

export default SimpleBackButton;
