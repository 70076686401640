import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CarretDownSVG, CloseSVG, ImageUploadSVG, UploadFolderSVG, UploadSVG } from 'src/assets/icons';
import { AppModal, Button } from 'src/components';
import { useClickOutside, useConfirm } from 'src/hooks';
import { useUploadImagePopupProgress } from 'src/pages/Images/List/UploadSection/UploadImageProgressPopup';
import './index.less';
import { useUploadFolderPopupProgress } from 'src/pages/Images/List/UploadSection/UploadFolderProgressPopup';
import { useDropzone } from 'react-dropzone';
import { Permission, PlanTypeEnum } from 'src/types';
import { AppRoutes } from 'src/helpers';
import { useSetRecoilState } from 'recoil';
import { activeChangePlanModal } from 'src/recoil-stores';
import { useCurrentProjectQuotaQuery } from 'src/services/queries/useProjectQuotaQuery';
import useCurrentProject from 'src/hooks/useCurrentProject';
import { FILE_TYPE_LIST } from 'src/helpers/common';

type UploadType = 'Image' | 'Folder';
type UploadSelectedFileType = { fileList: File[] };

export interface UploadButtonProps {
  paths: string[];
}

export default function UploadButton({ paths }: UploadButtonProps) {
  const [visible, setVisible] = useState(false);
  const [uploadType, setUploadType] = useState<UploadType>('Image');

  const [selectedFiles, setSelectedFiles] = useState<UploadSelectedFileType>();
  const [fileTypeInvalid, setFileTypeInvalid] = useState(false);

  const history = useHistory();
  const { confirm: _confirm } = useConfirm();
  const { data: projectInfo } = useCurrentProject();
  const isPlanPro = projectInfo?.owner_plan === PlanTypeEnum.PLAN_PRO;
  const isOwner = projectInfo?.permission === Permission.OWNER;

  useEffect(() => {
    if (!visible) {
      setSelectedFiles(undefined);
      setFileTypeInvalid(false);
    } else {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const { data: userQuota, refetch } = useCurrentProjectQuotaQuery();

  const directories = () => {
    const output = {};
    let current;

    for (const file of selectedFiles?.fileList || []) {
      current = output;

      const paths = (file['webkitRelativePath'] || file['path']).split('/');

      for (let i = 0; i < paths.length; i++) {
        const segment = paths[i];
        if (segment !== '') {
          if (!(segment in current)) {
            current[segment] = {
              isDirectory: true,
            };
          }

          if (paths[paths.length - 1] === segment) {
            current[segment] = {
              entity: file,
              isFile: true,
            };
          }

          current = current[segment];
        }
      }
    }

    return output;
  };

  directories();

  const { setImages, setProjectId } = useUploadImagePopupProgress();
  const { setDirectories, setProjectIdFolder } = useUploadFolderPopupProgress();
  const { pathId } = useParams<{ pathId: string }>();
  const imgUploadable = Number(userQuota?.IMAGE_COUNT_MAX) - Number(userQuota?.IMAGE_COUNT_USER);
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    if (!!projectId && !!setProjectId) {
      setProjectId(projectId!);
      setProjectIdFolder(projectId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const setOpenPlanModal = useSetRecoilState(activeChangePlanModal);

  function handleAddFileToQueue() {
    if (!!selectedFiles?.fileList.length) {
      const totalFilesSize = selectedFiles?.fileList?.reduce(
        (previousValue, currentValue) => previousValue + currentValue?.size,
        0,
      );
      if (
        selectedFiles?.fileList?.[0]?.size / (1024 * 1024) >
        userQuota?.DATA_STORAGE_MAX! - userQuota?.DATA_STORAGE_USER!
      ) {
        setVisible(false);
        _confirm(
          <div>
            <div className="">
              <p className="text-center">
                You don’t have enough storage to
                <br />
                upload images.​
                <br />
                {isPlanPro ? (
                  <p>
                    ​Please contact
                    <br />
                    <a href={`mailto:${process.env.REACT_APP_STRATIO_AI_EMAIL}`} className="text-black underline">
                      {process.env.REACT_APP_STRATIO_AI_EMAIL}
                    </a>
                    <br />
                    if you want to have more storage. ​
                  </p>
                ) : (
                  <p>
                    Please upgrade your plan to upload
                    <br />
                    more images.​
                  </p>
                )}
              </p>
            </div>
          </div>,
          !isPlanPro
            ? {
                okText: 'Upgrade',
                onOk: (close) => {
                  setOpenPlanModal(true);
                  !!history && history.push(AppRoutes.myPage.plan);
                  close();
                },
                onCancel: (close) => close(),
                popupWidth: 374,
                centered: true,
              }
            : {
                onOk: (close) => {
                  close();
                },
                popupWidth: 374,
                centered: true,
              },
        );
        return;
      }

      if (selectedFiles?.fileList.length > imgUploadable) {
        setVisible(false);
        _confirm(
          <div>
            <div className="mx-30px">
              <p className="text-center">
                You have reached the maximum number of image uploads.​
                <div className="items-start">
                  <br />• Images selected : {selectedFiles?.fileList.length}
                  <br />• Images uploadable: {imgUploadable > 0 ? imgUploadable : 0}
                </div>
                <br />
                <p>
                  {isPlanPro ? (
                    <>
                      Please contact{' '}
                      <a href={`mailto:${process.env.REACT_APP_STRATIO_AI_EMAIL}`} className="text-black">
                        {process.env.REACT_APP_STRATIO_AI_EMAIL}
                      </a>{' '}
                      if you want to upload more images.
                    </>
                  ) : (
                    'Please upgrade your plan to upload more images.​'
                  )}
                </p>
              </p>
            </div>
          </div>,
          !isPlanPro
            ? {
                okText: isOwner ? 'Upgrade' : 'OK',
                onOk: (close) => {
                  if (isOwner) {
                    setOpenPlanModal(true);
                    history.push(AppRoutes.myPage.plan);
                  }
                  close();
                },
                onCancel: isOwner ? (close) => close() : undefined,
              }
            : {
                onOk: (close) => {
                  close();
                },
              },
        );
        return;
      }
      if (uploadType === 'Image' && setImages) {
        setImages((imgs) => [
          ...imgs,
          {
            id: new Date().toISOString(),
            images: selectedFiles.fileList.map((file) => ({
              file,
              path: pathId,
            })),
          },
        ]);
      } else {
        setDirectories &&
          setDirectories((dirs) => [
            ...dirs,
            {
              key: new Date().toISOString(),
              tree: directories(),
              path: pathId,
              totalItems: selectedFiles.fileList.length,
            },
          ]);
      }

      setVisible(false);
      setSelectedFiles(undefined);
    }
  }

  // function formatBytes(bytes: number, decimals = 2) {
  //   if (bytes === 0)
  //     return {
  //       value: 0,
  //       type: 'GB',
  //     };

  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));

  //   return {
  //     value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
  //     type: sizes[i],
  //   };
  // }

  // const formatBytesObject = formatBytes(selectedFiles?.fileList.map((el) => el.size).reduce((p, c) => p + c, 0) || 0);

  const onFileChange = async (files: UploadSelectedFileType) => {
    const { fileList = [] } = files;
    const isInvalid = fileList?.some((e) => {
      const filetype = e.name.split('.')[e.name.split('.').length - 1].toLowerCase();
      return !FILE_TYPE_LIST.includes(filetype);
    });

    if (isInvalid || fileList?.length === 0) {
      setFileTypeInvalid(true);
      return;
    }
    setFileTypeInvalid(false);
    setSelectedFiles(files);
  };

  return (
    <div>
      <Button type="_00BEEB" className="rounded-full" onClick={() => setVisible(!visible)}>
        <div className="flex space-x-10px">
          <UploadSVG className="w-4" /> <span className="text-12px text-inherit font-normal">UPLOAD</span>
          <UploadOption
            onSelectUploadType={(type) => {
              setUploadType(type);
              setVisible(true);
            }}
          />
        </div>
      </Button>
      <AppModal
        header={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={900}
        className="modal-upload"
        style={{ minWidth: 700 }}
        wrapClassName="border-none"
      >
        <div className="relative p-15px">
          <CloseSVG className="absolute top-0 right-0 cursor-pointer" onClick={() => setVisible(false)} />
          <div className="px-60px pt-40px pb-2 flex flex-col items-center justify-between">
            <div className="text-#00BEEB font-bold uppercase text-42px relative top-4">Upload</div>
            <UploadHandler type={uploadType} onFilesChange={onFileChange as any} />
            {fileTypeInvalid && (
              <p className={`text-14px text-#F80303 mt-10px text-center`}>
                Please confirm if the file format is in one of the following:
                <br /> jpg, jpeg, png, bmp, tif, tiff, and webp.​
              </p>
            )}

            <div
              className="border border-#707070 relative py-3 px-6 text-12px leading-20px text-left mt-20px"
              style={{ minWidth: 300 }}
            >
              <div>Selected Image: {selectedFiles?.fileList.length}</div>
              {/* <div>
                Selected Image Size({formatBytesObject.type}): {formatBytesObject.value}
              </div> */}
              <div>
                File Path:{' '}
                {!!paths?.length &&
                  paths.map((item, index) => (
                    <Fragment key={item}>
                      <span
                        className={`text-12px cursor-pointer font-light ${
                          paths[index + 1] ? 'text-#707070' : 'text-#00BEEB font-medium'
                        }`}
                      >
                        {item}
                        {paths[index + 1] && <span className="inline-block mx-1">/</span>}
                      </span>
                    </Fragment>
                  ))}
              </div>
            </div>
            <Button
              disabled={fileTypeInvalid}
              type={fileTypeInvalid ? '_707070' : '_00BEEB'}
              className="w-120px h-32px rounded-full mt-40px"
              onClick={handleAddFileToQueue}
            >
              <div className="flex-center space-x-2">
                <UploadSVG className="w-14px" />
                <span className="text-12px text-inherit font-normal">UPLOAD</span>
              </div>
            </Button>
          </div>
        </div>
      </AppModal>
    </div>
  );
}

interface UploadOptionProps {
  onSelectUploadType: (val: UploadType) => void;
}

function UploadOption({ onSelectUploadType }: UploadOptionProps) {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => active && setActive(false));

  function handleSelect(type: UploadType) {
    onSelectUploadType(type);
    setActive(false);
  }

  return (
    <div className="relative z-20 text-left" onClick={(e) => e.stopPropagation()} ref={ref}>
      <div
        className={`flex-center rounded-full ${active ? 'bg-#009EC4' : ''}`}
        style={{ width: 18, height: 18 }}
        onClick={() => setActive(!active)}
      >
        <CarretDownSVG className="w-3" />
      </div>
      <AnimatePresence>
        {active && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute w-128px py-1 bg-white shadow-drop z-20 top-30px"
          >
            <div
              className="text-12px font-normal text-black hover:bg-#F5F5F5 px-5 py-2"
              onClick={() => handleSelect('Image')}
            >
              File Upload
            </div>
            <div
              className="text-12px font-normal text-black hover:bg-#F5F5F5 px-5 py-2"
              onClick={() => handleSelect('Folder')}
            >
              Folder Upload
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface UploadHandlerProps {
  type: UploadType;
  onFilesChange: React.Dispatch<React.SetStateAction<UploadSelectedFileType | undefined>>;
}

function UploadHandler({ type, onFilesChange }: UploadHandlerProps) {
  const onDrop = useCallback((acceptedFiles) => {
    onFilesChange({
      fileList: acceptedFiles,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: 'image/jpeg,image/jpg,image/png,image/bmp,image/webp,image/tiff,image/tif',
  });

  return (
    <div {...getRootProps()} className="drag-upload-wapper w-full py-1px">
      <input
        {...getInputProps()}
        // @ts-ignore
        directory={type === 'Folder' ? '' : undefined}
        webkitdirectory={type === 'Folder' ? '' : undefined}
      />
      <div
        className="border border-#707070 bg-#F5F5F5 flex flex-col justify-end pt-90px pb-24px items-center w-full mt-30px cursor-pointer"
        style={{ height: 358 }}
      >
        {type === 'Folder' ? <UploadFolderSVG className="w-32 mb-auto" /> : <ImageUploadSVG className="w-32 mb-auto" />}
        <div className="text-#888888 font-light text-20px">Drag your {type === 'Folder' ? 'Folder' : 'File'} here</div>
        <div className="text-12px font-light text-#000000 bg-#E4E4E4 rounded-full mt-4" style={{ padding: '4px 10px' }}>
          from your computer
        </div>
        <p className="text-12px font-light text-#000000 mx-50px text-center mt-15px">
          Once uploaded, all images will be converted and saved as jpg formats.
          <br /> Images will be resized if any of the smaller of width or height is larger than 2,000 pixels.
        </p>
      </div>
    </div>
  );
}
