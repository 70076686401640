import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { AnnotationEntity } from 'src/types/annotation';
import { ImageEntity } from 'src/types/image';
import { Color } from 'src/utils';
import { changeOpacityRGBA, hexToRGBA } from 'src/utils/color';

interface AnnotationProps {
  annotation: AnnotationEntity;
  scale: number;
  image?: ImageEntity;
  onHover: (val: boolean) => void;
  isHover: boolean;
  isShowLabel: boolean;
  zoomScale: number;
}

const AnnotationBox = ({ annotation, scale, image, onHover, isHover, isShowLabel, zoomScale }: AnnotationProps) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    onHover(hover);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hover]);

  const clientWidth = image?.width || 0,
    clientHeight = image?.height || 0;

  const [boxWidth, setBoxWidth] = useState(0);

  const textRef = useRef<SVGTextElement>(null);

  const labelColor = annotation.label.color || Color.PRIMARY;

  const color = hexToRGBA(labelColor.toUpperCase() === Color.WHITE ? Color.BLACK : labelColor);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBoxWidth((textRef.current?.getBBox().width || 0) + 10);
    }, 0.5);

    return () => clearTimeout(timeout);
  }, [scale, isShowLabel]);

  const initialBox = {
    x: annotation.bx - 3,
    y: annotation.by - 20 / scale,
    bh: 20 / scale,
    bw: boxWidth,
  };

  const textBox = {
    x: annotation.bx * zoomScale - 3,
    y: annotation.by * zoomScale - 20 / scale,
    bh: 20 / scale,
    bw: boxWidth,
  };

  if (initialBox.y < 0) {
    textBox.y = annotation.bh * zoomScale;
  }

  if (clientHeight - initialBox.y < 20 / scale) {
    textBox.y = annotation.by * zoomScale - 3;
    textBox.x = annotation.bx * zoomScale + annotation.bw * zoomScale + 3 / scale;
  }

  if (boxWidth !== 0 && clientWidth - annotation.bw - annotation.bx < boxWidth) {
    textBox.y = annotation.by * zoomScale;
    textBox.x = annotation.bx * zoomScale - boxWidth - 3 / scale;
  }

  return (
    <g className="cursor-pointer relative" id={annotation.id.toString()} z={10 - annotation.id}>
      <motion.rect
        width={annotation.bw * zoomScale}
        height={annotation.bh * zoomScale}
        x={annotation.bx * zoomScale}
        y={annotation.by * zoomScale}
        stroke={color}
        animate={{
          strokeWidth: (isHover ? 10 : 3) / scale,
          fill: changeOpacityRGBA(color, isHover ? 0.5 : 0),
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      />
      {isShowLabel && (
        <g>
          <rect
            x={textBox.x}
            y={textBox.y}
            width={textBox.bw}
            height={textBox.bh}
            fill={labelColor.toUpperCase() === Color.WHITE ? Color.BLACK : labelColor}
          ></rect>
          <text
            x={textBox.x + 4}
            y={textBox.y + 15 / scale}
            fontSize={12 / scale}
            fill={labelColor.toUpperCase() === Color.WHITE ? Color.BLACK : Color.WHITE}
            ref={textRef}
          >
            {annotation.label.name}
          </text>
        </g>
      )}
    </g>
  );
};

export default AnnotationBox;
