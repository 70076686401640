/* eslint-disable jsx-a11y/interactive-supports-focus */
import { ReactNode, useEffect, useState } from 'react';
import { MinusSVG } from 'src/assets/icons';
import { Check } from 'akar-icons';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';

export interface CheckboxProps {
  children?: ReactNode;
  onCheckedChange?: (checked: boolean) => void;
  checked?: boolean;
  indeterminate?: boolean;
  checkboxClassName?: string;
  size?: 'sm' | 'md';
}

export default function Checkbox({
  children,
  checked: check,
  onCheckedChange,
  indeterminate,
  checkboxClassName,
  size = 'md',
}: CheckboxProps) {
  const [checked, setChecked] = useState(false);

  // useEffect(() => {
  //   onCheckedChange && onCheckedChange(checked);
  // }, [checked]);

  useEffect(() => {
    setChecked(!!check);
  }, [check]);

  return (
    <div
      className="flex items-center"
      onClick={() => (onCheckedChange ? onCheckedChange(!checked) : setChecked(!checked))}
      style={{ userSelect: 'none' }}
    >
      <div
        className={classNames(
          'bg-white w-20px h-20px flex-center border border-#707070 hover:border-black',
          checkboxClassName,
        )}
      >
        <AnimatePresence exitBeforeEnter>
          {checked ? (
            <motion.div className="bg-#0269B7 w-20px h-20px flex-center" key="_check" exit={{ opacity: 0 }}>
              <Check className="w-4 text-white" />
            </motion.div>
          ) : indeterminate ? (
            <motion.div className="bg-#0269B7 w-20px h-20px flex-center" key="_uncheck" exit={{ opacity: 0 }}>
              <MinusSVG className="w-8px text-white" />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
      {children}
    </div>
  );
}
