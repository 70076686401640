import { message } from 'antd';
import { AIModelStatusEnum, ClassesEntity, ProjectsEntity } from 'src/types';
import { PathEntity } from 'src/types/path';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import timeZones from 'src/pages/Login/helper/time_zone.json';

dayjs.extend(tz);
dayjs.extend(utc);

export const Color = {
  PRIMARY: '#00BEEB',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLACK_65: 'rgba(0, 0, 0, 0.65)',
  BLACK_35: 'rgba(0, 0, 0, 0.35)',
  BLACK_8: 'rgba(0, 0, 0, 0.08)',
  BLACK_3: 'rgba(0, 0, 0, 0.03)',
  BLUE: '#4285F4',
};
export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const Constant = {
  IMAGE: (w: number = 200, h: number = 300) => `https://picsum.photos/seed/${Math.random()}/${w}/${h}`,
  CURRENT_USER: 'user',
};

export function showUnknowErrorMessage(text?: string) {
  message.error(text || 'Oops! Something went wrong!');
}

export const EnuStatusText = (key: AIModelStatusEnum) => {
  switch (key) {
    case AIModelStatusEnum.NEWLY_CREATED:
      return 'Newly Created';
    case AIModelStatusEnum.TRAINING_READY:
      return 'Training Ready';
    case AIModelStatusEnum.TRAINING:
      return 'Training';
    case AIModelStatusEnum.COMPLETED:
      return 'Completed';
    default:
      return 'Newly Created';
  }
};

export function getVideoResolution(file: File): Promise<[number, number]> {
  return new Promise((resole, reject) => {
    try {
      const rd = new FileReader();

      rd.onload = (e) => {
        const blob = new Blob([e.target?.result || ''], { type: file.type });
        const url = URL.createObjectURL(blob);
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.addEventListener('loadedmetadata', () => {
          resole([video.videoWidth, video.videoHeight]);
        });

        video.src = url;
      };

      rd.readAsArrayBuffer(file);
    } catch (error) {
      reject(error);
    }
  });
}

export function getVideoDuration(file: File): Promise<number> {
  return new Promise((resole, reject) => {
    try {
      const rd = new FileReader();

      rd.onload = (e) => {
        const blob = new Blob([e.target?.result || ''], { type: file.type });
        const url = URL.createObjectURL(blob);
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.addEventListener('loadedmetadata', () => {
          resole(video.duration);
        });

        video.src = url;
      };

      rd.readAsArrayBuffer(file);
    } catch (error) {
      reject(error);
    }
  });
}

export function getImageResolution(file: File): Promise<[number, number]> {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.onload = function () {
        resolve([img.width, img.height]);
      };
      img.src = objectUrl;
    } catch (error) {
      reject(error);
    }
  });
}

export function thousandSeperator(num = 0) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getImageThumbnailUrl(imageId: string | number, type: 'Thumbnail' | 'Preview' = 'Thumbnail') {
  //type: 'Thumbnail' | 'Raw' | 'Preview' = 'Thumbnail'
  return `/api/images/${imageId}/data/?type=${type}`;
}
export function getAnnotationThumbnailUrl(imageId: string | number, type: 'Thumbnail' | 'Cropped' = 'Thumbnail') {
  return `/api/annotations/${imageId}/data/?type=${type}`;
}

export function extractDirectoryList(projectData: ProjectsEntity, pathId: string | number, folderData: PathEntity) {
  if (!projectData || !pathId || !folderData) {
    return [];
  }

  return projectData?.root_path === +pathId
    ? [
        {
          name: projectData.name,
          id: +pathId,
        },
      ]
    : projectData && folderData
    ? [
        {
          name: projectData.name,
          id: projectData.root_path,
        },
        ...folderData.path_parents
          .filter((path) => path[0] !== projectData.root_path)
          .map((path) => ({
            name: path[1],
            id: path[0],
          })),
        {
          name: folderData.name,
          id: folderData.id,
        },
      ].filter((f) => !!f)
    : [];
}

export const delay = (n: number) => new Promise((resolve) => setTimeout(resolve, n));

export const extractMLClassColor = (item?: ClassesEntity) => {
  if (!item) return Color.PRIMARY;

  if (item.labels.length && item.labels.find((l) => l.color === item.color)) {
    return item.color;
  }

  return item.labels?.[0]?.color || Color.PRIMARY;
};

export const dateTZConverter = (data: string) => {
  const userTZ = localStorage.getItem('TZ');

  if (userTZ && timeZones.some((item) => item.value === userTZ)) {
    return dayjs(data).tz(userTZ);
  }

  return dayjs(data);
};
