import React, { FC, memo, useEffect, useRef } from 'react';
import cls from 'classnames';

export interface Thumbnail {
  id: string;
  src: string;
}
interface Props {
  thumbnails?: Thumbnail[];
  selectedId?: Thumbnail['id'];
  onSelect?: (id: Thumbnail['id']) => void;
}

const ThumbnailRow: FC<Props> = (props) => {
  const { thumbnails = [], selectedId, onSelect } = props;
  const divRef = useRef<HTMLDivElement>(null);

  function mapImageId(id: string) {
    return `thumbnail-${id}`;
  }

  async function scrollToImage() {
    if (!divRef.current || !selectedId) return;
    const divElem = divRef.current;
    const screenWidth = window.innerWidth;
    const thumbnailElem = document.getElementById(mapImageId(selectedId));

    if (!thumbnailElem) return;
    const parentOffset = thumbnailElem.parentElement?.offsetLeft;
    const thumbnailWidth = thumbnailElem.clientWidth;
    if (!parentOffset) return;
    divElem.scrollTo({
      left: parentOffset - (screenWidth - thumbnailWidth) / 2,
      behavior: 'smooth',
    });
  }
  useEffect(() => {
    scrollToImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, thumbnails.length]);
  return (
    <div ref={divRef} className="whitespace-nowrap overflow-x-auto space-x-15px text-center">
      {thumbnails.map((v, i) => (
        <div
          onClick={() => onSelect?.(v.id)}
          tabIndex={-1}
          key={v.id}
          className={cls('inline-block w-40 h-40 mx-4 cursor-pointer relative')}
        >
          <div
            id={mapImageId(v.id)}
            className={cls('absolute h-40 w-full', {
              'bg-#35A7CF opacity-50': selectedId === v.id,
            })}
          />
          <img
            alt={v.id}
            width="140"
            height="140"
            className="w-40 h-40 object-cover rounded-sm"
            src={v.src}
            loading="lazy"
          />
        </div>
      ))}
    </div>
  );
};

export default memo(ThumbnailRow);
