/* eslint-disable no-loop-func */
import { Progress } from 'antd';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { memo } from 'react';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { ImageSVG } from 'src/assets/icons';
import { useNextConfirm } from 'src/hooks/useNextConfirm';
import { activeChangePlanModal, uploadedImages } from 'src/recoil-stores';
import { uploadImage } from 'src/services/mutations/useUploadImageMutation';
import { useConfirm } from 'src/hooks/useConfirm';

import './progress.less';
import { ERROR_CODE } from 'src/helpers/common';
import { UPLOAD_FAILED_TYPE } from 'src/pages/Images/List';
import { Permission, PlanTypeEnum } from 'src/types';
import { AppRoutes } from 'src/helpers';
import { useHistory } from 'react-router-dom';
import useCurrentProject from 'src/hooks/useCurrentProject';
import isFileValid from 'src/pages/Images/List/UploadSection/isFileValid';

interface UploadImagePopupContextProps {
  images?: ImageStateEntity[];
  setImages?: Dispatch<SetStateAction<ImageStateEntity[]>>;
  projectId?: string | number;
  setProjectId?: any;
}

const UploadImagePopupContext = createContext<UploadImagePopupContextProps>({});

interface UploadImagePopupProviderProps {
  children: ReactNode;
}

interface ImageStateEntity {
  id: string;
  images: FileType[];
}

interface FileType {
  file: File | RcFile;
  path: string | number;
}

export function UploadImagePopupProgressProvider({ children }: UploadImagePopupProviderProps) {
  const [images, setImages] = useState<ImageStateEntity[]>([]);
  const [projectId, setProjectId] = useState(0);

  return (
    <UploadImagePopupContext.Provider value={{ images, setImages, projectId, setProjectId }}>
      {images.map((list) => (
        <Node key={list.id} images={list.images} uid={list.id} projectId={projectId} />
      ))}
      {children}
    </UploadImagePopupContext.Provider>
  );
}

const Node = memo(({ images, uid, projectId }: { images: FileType[]; uid: string; projectId: number }) => {
  const toastId = useRef('');
  const setUploadedImages = useSetRecoilState(uploadedImages);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [error, setError] = useState(false);
  const [uploadErrType, setUploadErrType] = useState('');

  const [unsupportedFiles, setUnsupportedFiles] = useState<FileType[]>([]);

  const cancelSource = useMemo(() => axios.CancelToken.source(), []);

  useEffect(() => {
    toastId.current = toast(
      <div className="py-4 px-30px relative">
        <div className="flex items-center space-x-30px">
          <div className="h-90px flex-center rounded-full flex-shrink-0">
            <ImageSVG className="w-16 h-50px text-#AAAAAA" />
          </div>
          <div>
            <div className="text-20px font-light">Uploading {images.length} files ...</div>
          </div>
        </div>
        <div className="absolute text-#888888 text-12px font-light" style={{ bottom: '4px', right: '14px' }}>
          0%
        </div>
        <div className="absolute right-0 w-full text-8px" style={{ lineHeight: '8px', bottom: '-1px' }}>
          <Progress percent={0} size="small" showInfo={false} />
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_LEFT,
        className: 'upload-wrapper',
        toastId: uid,
        closeOnClick: false,
      },
    ) as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const { confirm: _confirm } = useConfirm();

  const { data: projectDetail } = useCurrentProject(projectId?.toString());
  const isOwner = projectDetail?.permission === Permission.OWNER;
  const isPlanPro = projectDetail?.owner_plan === PlanTypeEnum.PLAN_PRO;

  const history = useHistory();
  const setOpenPlanModal = useSetRecoilState(activeChangePlanModal);

  useEffect(() => {
    const upload = async () => {
      const internalFileTypeErrors: File[] = [];

      let index = 0;
      for (const image of images) {
        const formData = new FormData();
        formData.append('name', image.file.name || '');
        formData.append('file_content', image.file || '');
        formData.append('path', `${image.path}`);

        try {
          const isValid = await isFileValid(image.file);

          if (!isValid) {
            setUnsupportedFiles((files) => [...files, image]);
            internalFileTypeErrors.push(image.file);

            setUploadProgress((progress) => Math.floor(100 / (images.length / (index + 1))));
            index++;

            continue;
          }

          const { data } = await uploadImage(
            formData,
            (p) => images.length === 1 && setUploadProgress((progress) => p - 1),
            {
              cancelToken: cancelSource.token,
            },
          );

          setUploadedImages({
            parentId: +image.path,
            image: data,
          });
        } catch (error: any) {
          const errCode = error?.response?.data?.code;

          if (errCode === ERROR_CODE.IMAGE_UPLOAD.UNSUPPORTED_IMAGE) {
            setUnsupportedFiles((files) => [...files, image]);
            internalFileTypeErrors.push(image.file);
            setUploadErrType(UPLOAD_FAILED_TYPE.FILE_TYPE_ERROR);
          } else {
            if (errCode === ERROR_CODE.PERMISSION.DISK_QUOTA_EXCEED) {
              index--;
            }
            throw error;
          }
        } finally {
          setUploadProgress((progress) => Math.floor(100 / (images.length / (index + 1))));

          index++;
        }
      }

      if (internalFileTypeErrors.length) {
        _confirm(
          <div>
            <p className="text-center mb-20px text-16px mx-30px font-bold">{'Some images were not uploaded.'}</p>
            <div className="mx-30px">
              <p className="text-center">
                Please ensure to upload uncorrupted images files in the following formats: jpg, jpeg, png, bmp, tif,
                tiff, webp.
              </p>
              <p className="mt-15px">{`Failed uploads (${internalFileTypeErrors?.length}):`}​</p>
              <div className="scrollbar-custom overflow-y-auto max-h-85px pr-10px">
                {internalFileTypeErrors?.map((e, i: number) => (
                  <div key={i} className="my-5px">
                    • {e.name}
                  </div>
                ))}
              </div>
            </div>
          </div>,
          {
            onOk: (close) => {
              close();
            },
          },
        );
      }
    };

    upload().catch(async (error) => {
      const errCode = error?.response?.data?.code;

      if (
        errCode === ERROR_CODE.PERMISSION.DISK_QUOTA_EXCEED ||
        errCode === ERROR_CODE.PERMISSION.UPLOAD_IMAGE_EXCEED
      ) {
        setUploadErrType(UPLOAD_FAILED_TYPE.STORAGE_EXCEEDED);
        _confirm(
          <div>
            <div className="">
              <p className="text-center">
                You don’t have enough storage to upload images.​
                <br />
                {isPlanPro ? (
                  <p>
                    ​Please contact{' '}
                    <a href={`mailto:${process.env.REACT_APP_STRATIO_AI_EMAIL}`} className="text-black underline">
                      {process.env.REACT_APP_STRATIO_AI_EMAIL}
                    </a>
                    <br />
                    if you want to have more storage. ​
                  </p>
                ) : (
                  <p>Please upgrade your plan to upload more images.​</p>
                )}
              </p>
            </div>
          </div>,
          !isPlanPro
            ? {
                okText: isOwner ? 'Upgrade' : 'OK',
                onOk: (close) => {
                  if (isOwner) {
                    setOpenPlanModal(true);
                    !!history && history.push(AppRoutes.myPage.plan);
                  }
                  close();
                },
                onCancel: isOwner ? (close) => close() : undefined,
                popupWidth: 374,
              }
            : {
                onOk: (close) => {
                  close();
                },
                popupWidth: 374,
              },
        );
        return;
      }
      setError(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const { confirm } = useNextConfirm();

  useEffect(() => {
    if (uid) {
      const percent = uploadProgress < 0 ? 0 : uploadProgress > 100 ? 100 : uploadProgress;
      const strokeColor =
        uploadErrType === UPLOAD_FAILED_TYPE.FILE_TYPE_ERROR
          ? '#EB9A00'
          : uploadErrType === UPLOAD_FAILED_TYPE.STORAGE_EXCEEDED
          ? '#FF0000'
          : percent < 100
          ? '#2b94ed'
          : percent >= 100 && unsupportedFiles.length
          ? '#EB9A00'
          : '#52c41a';

      toast.update(uid, {
        render: (
          <div className="py-4 px-30px relative">
            <div className="flex items-center space-x-30px">
              <div className="h-90px flex-center rounded-full flex-shrink-0">
                <ImageSVG className="w-16 h-50px text-#AAAAAA" />
              </div>
              <div>
                <div className="text-12px font-light">
                  {error
                    ? 'Failed'
                    : percent < 100 || uploadErrType === UPLOAD_FAILED_TYPE.STORAGE_EXCEEDED
                    ? 'Uploading...'
                    : 'Uploaded'}
                </div>
                <div className="text-20px font-light">
                  {error
                    ? 'Failed'
                    : percent < 100 || uploadErrType === UPLOAD_FAILED_TYPE.STORAGE_EXCEEDED
                    ? `Uploading ${images.length} files ...`
                    : `Uploaded ${images.length - unsupportedFiles.length} files.`}
                </div>
              </div>
            </div>
            <div className="absolute text-#888888 text-12px font-light" style={{ bottom: '4px', right: '14px' }}>
              {percent}%
            </div>
            <div className="absolute right-0 w-full text-8px" style={{ lineHeight: '8px', bottom: '-5px' }}>
              <Progress
                strokeColor={strokeColor}
                percent={percent}
                size="small"
                showInfo={false}
                status={error ? 'exception' : undefined}
              />
            </div>
          </div>
        ),
        position: toast.POSITION.BOTTOM_LEFT,
        className: 'upload-wrapper',
        toastId: uid,
        closeOnClick: false,
        autoClose: false,
        style: {
          width: 368,
        },
        closeButton: ({ closeToast }) => (
          <span
            className="text-20px cursor-pointer text-#888888 underline font-thin Toastify__close-button"
            onClick={() =>
              percent < 100 && !uploadErrType
                ? confirm(
                    <div className="text-center pb-60px pt-10px text-16px text-#BD0034">
                      Are you sure you want to
                      <br /> cancel upload reupload?
                    </div>,
                    {
                      onOk: (close) => {
                        setError(true);
                        cancelSource.cancel();
                        close();
                        closeToast();
                      },
                    },
                  )
                : closeToast()
            }
          >
            {percent < 100 && !uploadErrType ? 'CANCEL' : 'CLOSE'}
          </span>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSource, uid, uploadProgress, images, error, uploadErrType]);

  return null;
});

export const useUploadImagePopupProgress = () => {
  const context = useContext(UploadImagePopupContext);

  if (context === undefined) {
    throw new Error(`useUI must be used within a Provider`);
  }
  return context;
};
