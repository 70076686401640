import { AnimatePresence, motion } from 'framer-motion';
import { memo, useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks';
import { DotMoreSVG, FolderFillSVG } from 'src/assets/icons';
import CheckboxOutline from './CheckboxOutline';
import { Input } from 'antd';
import { Button } from 'src/components';
import { FolderData } from 'src/pages/Images/List';
import { useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from 'src/helpers';
import useCreatePathMutation from 'src/services/mutations/useCreatePathMutation';
import { showUnknowErrorMessage } from 'src/utils';
import useUpdatePathMutation from 'src/services/mutations/useUpdatePathMutation';
import { TypeModuleImageEnum } from 'src/types';
import DbClick from 'src/components/DbClick';
import TextTooltip from 'src/components/TextTooltip';

export interface FolderCardProps {
  displayType?: 'LIST' | 'GRID';
  onSelectedChange?: (select?: boolean) => void;
  folder: FolderData;
  onFolderChange: (params: { type?: 'CANCEL_CREATE' | 'CREATE' | 'EDIT' | 'CANCEL_EDIT'; entity: FolderData }) => void;
  onChangeToggleFolderDraft: (folderId?: number) => void;
  onDelete: () => void;
  typeActions: TypeModuleImageEnum;
  onShiftSelectedChange: (selected: boolean) => void;
}

export default memo(function FolderCard({
  displayType = 'GRID',
  onSelectedChange,
  folder,
  onFolderChange,
  onChangeToggleFolderDraft,
  onDelete,
  typeActions,
  onShiftSelectedChange,
}: FolderCardProps) {
  const [selected, setSelected] = useState(!!folder.selected);
  const [folderName, setFolderName] = useState(folder.entity?.name || '');
  const ref = useRef<Input>(null);

  const { projectId, pathId } = useParams<{ projectId: string; pathId: string }>();

  const history = useHistory();

  // useEffect(() => {
  //   onSelectedChange && onSelectedChange(selected);
  // }, [selected]);

  useEffect(() => {
    setSelected(!!folder.selected);
  }, [folder]);

  const { mutate: createPath, isLoading: createPathLoading } = useCreatePathMutation({
    onSuccess: (res) =>
      onFolderChange && onFolderChange({ type: 'CREATE', entity: { ...folder, entity: res, isDraft: false } }),
    onError: (res) => showUnknowErrorMessage(res.message),
  });

  const { mutate: updatePath, isLoading: updatePathLoading } = useUpdatePathMutation({
    onSuccess: (res) =>
      onFolderChange && onFolderChange({ type: 'EDIT', entity: { ...folder, entity: res, isDraft: false } }),
    onError: (res) => showUnknowErrorMessage(res.message),
  });

  if (folder.isDraft) {
    if (displayType === 'LIST') {
      return (
        <div className="w-full h-80px px-30px flex items-center relative bg-#F5F5F5 border border-#CCCCCC">
          <div className="flex space-x-30px">
            <div className="w-20px" />
            <FolderFillSVG className="h-40px text-#1F94ED" />
          </div>
          <div className="border-b ml-30px">
            <Input
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              placeholder="Folder name"
              bordered={false}
              ref={ref}
              className="text-20px placeholder-italic px-0"
            />
          </div>
          <div className="ml-auto flex space-x-4">
            <Button
              type="_FFFFFF"
              className="w-32 rounded-full flex-center"
              style={{ paddingTop: 2 }}
              disabled={updatePathLoading || createPathLoading}
              onClick={() =>
                onFolderChange &&
                onFolderChange({
                  type: folder.entity ? 'CANCEL_EDIT' : 'CANCEL_CREATE',
                  entity: { ...folder, name: folderName },
                })
              }
            >
              <span className="text-12px font-normal text-inherit">CANCEL</span>
            </Button>
            <Button
              type="_00BEEB"
              className="w-32 rounded-full flex-center"
              style={{ paddingTop: 2 }}
              disabled={!folderName || updatePathLoading || createPathLoading}
              loading={updatePathLoading || createPathLoading}
              onClick={() =>
                folder.entity
                  ? updatePath({
                      id: folder.entity.id,
                      name: folderName,
                    })
                  : createPath({
                      name: folderName,
                      parent: +pathId,
                    })
              }
            >
              <span className="text-12px font-normal text-inherit">{folder.entity ? 'EDIT' : 'CREATE'}</span>
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="w-138px h-259px p-10px flex flex-col select-none bg-#F5F5F5">
        <div className="w-20px h-20px" />
        <div className="px-3 mt-10px mb-2">
          <FolderFillSVG className="h-74px text-#1F94ED" />
        </div>
        <div className="border-b mx-20px">
          <Input
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="Folder name"
            bordered={false}
            ref={ref}
            className="text-10px font-medium px-0 text-center"
          />
        </div>
        <div className="mt-auto flex flex-col items-center justify-center space-y-2">
          <Button
            type="_FFFFFF"
            className="h-30px rounded-full flex-center w-80px"
            style={{ paddingTop: 2 }}
            disabled={updatePathLoading || createPathLoading}
            onClick={() =>
              onFolderChange &&
              onFolderChange({
                type: folder.entity ? 'CANCEL_EDIT' : 'CANCEL_CREATE',
                entity: { ...folder, name: folderName },
              })
            }
          >
            <span className="text-10px font-normal text-inherit">CANCEL</span>
          </Button>
          <Button
            type="_00BEEB"
            className="h-30px rounded-full flex-center w-80px"
            style={{ paddingTop: 2 }}
            disabled={!folderName || updatePathLoading || createPathLoading}
            loading={updatePathLoading || createPathLoading}
            onClick={() =>
              folder.entity
                ? updatePath({
                    id: folder.entity.id,
                    name: folderName,
                  })
                : createPath({
                    name: folderName,
                    parent: +pathId,
                  })
            }
          >
            <span className="text-10px font-normal text-inherit">{folder.entity ? 'EDIT' : 'CREATE'}</span>
          </Button>
        </div>
      </div>
    );
  }

  if (displayType === 'GRID')
    return (
      <DbClick
        className={`w-138px h-259px p-10px flex flex-col select-none cursor-pointer ${
          selected ? 'bg-#00BEEB' : 'bg-#F5F5F5'
        } transition duration-300 ease-in-out`}
        onClick={(e) => {
          if (e.shiftKey) {
            onShiftSelectedChange(!selected);
          } else {
            onSelectedChange ? onSelectedChange(!selected) : setSelected(!selected);
          }
        }}
        onDoubleClick={() => {
          // if (typeActions === TypeModuleImageEnum.IMAGE)
          history.push(AppRoutes.images.list(projectId, folder.entity?.id));
        }}
      >
        <div className="flex items-center justify-between">
          <CheckboxOutline checked={selected} />
          {typeActions === TypeModuleImageEnum.IMAGE && (
            <ItemAction onChangeToggleFolderDraft={onChangeToggleFolderDraft} folder={folder} onDelete={onDelete} />
          )}
        </div>

        <div className="px-3 mt-10px mb-2">
          <FolderFillSVG className="h-74px text-#1F94ED" />
        </div>
        <div className="text-center flex flex-col justify-between items-center flex-grow">
          <TextTooltip lineClamp={2} className="text-10px font-medium">
            {folder.entity?.name}
          </TextTooltip>
        </div>
      </DbClick>
    );

  return (
    <DbClick
      className={`w-full h-80px px-30px flex items-center space-x-30px relative cursor-pointer ${
        selected ? 'bg-#00BEEB' : 'bg-#F5F5F5 border border-#CCCCCC'
      } transition duration-300 ease-in-out`}
      onClick={(e) => {
        if (e.shiftKey) {
          onShiftSelectedChange(!selected);
        } else {
          onSelectedChange ? onSelectedChange(!selected) : setSelected(!selected);
        }
      }}
      onDoubleClick={() => {
        // if (typeActions === TypeModuleImageEnum.IMAGE)
        history.push(AppRoutes.images.list(projectId, folder.entity?.id));
      }}
    >
      <div className="flex items-center space-x-30px" style={{ width: '40%' }}>
        <CheckboxOutline checked={selected} />

        <FolderFillSVG className="h-40px text-#1F94ED flex-shrink-0" />
        <TextTooltip lineClamp={1} className="text-20px font-bold">
          {folder.entity?.name}
        </TextTooltip>
      </div>
      <div className="flex items-center justify-end" style={{ width: '30%' }}></div>
      {typeActions === TypeModuleImageEnum.IMAGE && (
        <div className="flex items-center justify-end space-x-40px" style={{ width: '30%' }}>
          <ItemAction
            size="large"
            onChangeToggleFolderDraft={onChangeToggleFolderDraft}
            folder={folder}
            onDelete={onDelete}
          />
        </div>
      )}
    </DbClick>
  );
});
interface ItemActionProps {
  onChangeToggleFolderDraft: (folderId?: number) => void;
  onDelete: () => void;
  size?: 'large';
  folder: FolderData;
}

function ItemAction({ size, onChangeToggleFolderDraft, folder, onDelete }: ItemActionProps) {
  const [visible, setVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => visible && setVisible(false));

  const isLarge = size === 'large';

  return (
    <div className="relative flex-center" ref={ref} onClick={(e) => e.stopPropagation()}>
      <div
        className={`flex-center cursor-pointer hover:bg-#DDDDDD ${isLarge ? 'w-40px h-40px' : 'w-18px h-18px'} ${
          visible ? 'bg-#DDDDDD' : ''
        }`}
        onClick={() => setVisible(!visible)}
      >
        <DotMoreSVG className={`text-black ${isLarge ? 'w-20px h-20px' : 'w-10px h-10px'}`} />
      </div>
      <AnimatePresence>
        {visible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute w-24 py-1 bg-white cursor-pointer shadow-drop -bottom-2 right-0 transform translate-y-full"
            style={{ zIndex: 1 }}
          >
            <div
              className="text-12px cursor-pointer px-4 py-2 hover:bg-#F5F5F5"
              onClick={() => {
                setVisible(false);
                onChangeToggleFolderDraft(folder.entity?.id);
              }}
            >
              Edit Name
            </div>
            <div
              className="text-12px text-#FF0000 cursor-pointer px-4 py-2 hover:bg-#F5F5F5"
              onClick={() => {
                setVisible(false);
                onDelete();
              }}
            >
              Delete
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
