import { Spin } from 'antd';
import React, { ReactChild } from 'react';
import { MainLayout } from 'src/layouts';
import { useRecoilValue } from 'recoil';
import SelectedSideBar from 'src/pages/Training/CreateClasses/SelectedSideBar';
import { updatingState } from 'src/recoil-stores/create-training';
import '../CreateAnnotationLayout/index.less';
export interface SelectTrainingLayoutProps {
  children: ReactChild;
}

export default function SelectTrainingLayout({ children }: SelectTrainingLayoutProps) {
  const updating = useRecoilValue(updatingState);

  return (
    <MainLayout>
      <Spin spinning={updating} wrapperClassName="overflow-none">
        <div className="relative flex">
          <SelectedSideBar />
          {children}
        </div>
      </Spin>
    </MainLayout>
  );
}
