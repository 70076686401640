import { dateTZConverter } from 'src/utils';
export const FORMAT_DATE = 'YYYY.MM.DD';
export const FILE_TYPE_LIST = ['jpg', 'jpeg', 'png', 'bmp', 'tif', 'tiff', 'webp'];

export const ERROR_CODE = {
  PERMISSION: {
    DISK_QUOTA_EXCEED: 1001,
    UPLOAD_IMAGE_EXCEED: 1002,
    GPU_TIME_EXCEED: 1003,
    PROJECT_COLLABORATOR_EXCEED: 1004,
    AUTO_ANNOTATION_PERMISSION: 1005,
    TRAINING_MODEL_EXCEED: 1006,
    USE_PUBLIC_IMAGE_PERMISSION: 1007,
    DATA_AUGMENTATION_PERMISSION: 1008,
    SIMULTANEOUS_TRAINING_PERMISSION: 1009,
    RESUME_TRAINING_PERMISSION: 1010,
    AIMODEL_CLASSES_EXCEED: 1011,
  },
  AUTHENTICATE: {},
  IMAGE_UPLOAD: {
    UNSUPPORTED_IMAGE: 3001,
  },
  ANNOTATION: {
    STORAGE_EXCEED: 4001,
  },
  ACCOUNT: {},
};

export const formatDate = (date: any, format: string = FORMAT_DATE) => {
  return date ? dateTZConverter(date).format(format) : null;
};

export const convertPermissionToString = (permission: string) => {
  switch (permission) {
    case 'OWNER':
      return 'Owner';
    case 'STAFF':
      return 'Staff';
    case 'DEVELOPER':
      return 'Developer';
    case 'DATAENGINEER':
      return 'Data Engineer';
    default:
      return '-';
  }
};

export const formatNumber = (number: number) => {
  return number.toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
  });
};

export const formatCurrency = (n: number, currency?: string) => {
  return (
    n.toFixed(0).replace(/./g, function (c: any, i: any, a: any) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
    }) + (currency || '원')
  );
};

// Unit default: MB
export const kilobytesToSize = (megabytes: number | 0) => {
  const sizes = ['KB', 'MB', 'GB', 'TB'];
  const kilobytes = megabytes * 1024; // MB to KB

  if (kilobytes === 0 || Math.log(kilobytes) < 0) return '0 KB';

  const i = Math.floor(Math.log(kilobytes) / Math.log(1024));
  const result = Number((kilobytes / Math.pow(1024, i)).toFixed(1));

  return result.toLocaleString('ko-KR') + sizes[i];
};

export const convertHoursToString = (date: string) => {
  const arrDate = date.split(':');
  const hours = parseInt(arrDate[0]);
  const minutes = parseInt(arrDate[1]);
  if (hours === 0 && minutes === 0) return '0:00:00';

  return (hours > 0 ? hours + ' Hour' : '') + ' ' + (minutes > 0 ? minutes + ' minute' : '') || '';
};

export const convertHoursToNumber = (date: string) => {
  const arrDate = date.split(':');
  const hours = parseInt(arrDate[0]);
  const minutes = parseInt(arrDate[1]) / 60;

  return +hours + +minutes.toFixed(1) || '';
};

export const truncateString = (str: string, length: number, lastText?: string) => {
  return str && str?.length > length ? str.substring(0, length) + (lastText || '...') : str;
};
