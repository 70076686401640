import { Spinner } from 'src/components';
import React from 'react';
import Scroll from 'react-infinite-scroller';

interface Props {
  element?: string;
  hasMore?: boolean;
  initialLoad?: boolean;
  isReverse?: boolean;
  loadMore(page: number): void;
  pageStart?: number;
  threshold?: number;
  useCapture?: boolean;
  useWindow?: boolean;
  loader?: React.ReactElement;
  getScrollParent?(): HTMLElement | null;
  children?: any;
}

export default function InfinityScroll({
  pageStart = 0,
  children,
  loader = <Spinner height={100} />,
  ...props
}: Props) {
  return (
    <Scroll loader={loader} pageStart={pageStart} {...props}>
      {children}
    </Scroll>
  );
}
