import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { AuthEndpoint } from 'src/services/endpoints';
import { ActivateRegisterInput, ActivateRegisterResponse, APIErrorResponse } from 'src/types';

export type ActivateRegisterOptions = UseMutationOptions<
  ActivateRegisterResponse,
  APIErrorResponse,
  ActivateRegisterInput
>;

export default function useActivateRegisterMutation(options?: ActivateRegisterOptions) {
  return useMutation<ActivateRegisterResponse, APIErrorResponse, ActivateRegisterInput>(
    (values) => request.post(AuthEndpoint.activateRegister, values).then((res) => res.data),
    options,
  );
}
