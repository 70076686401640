import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AppRoutes } from 'src/helpers';
import {
  createTaskInput,
  selectedPathForAnnotation,
  updatingState,
  useResetAnnotation,
} from 'src/recoil-stores/create-annotation';
import { useCreateAnnotationTaskMutation } from 'src/services/mutations/useCreateAnnotationTaskMutation';
import { showUnknowErrorMessage } from 'src/utils';

function useCreateAnnotationTask() {
  const [selectedPathAnnotations] = useRecoilState(selectedPathForAnnotation);

  const setUpdatingState = useSetRecoilState(updatingState);

  const selectedList = selectedPathAnnotations.filter(
    (s) => (s.selected && s.count) || (s.images?.length && !s.selected),
  );

  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();
  const reset = useResetAnnotation();

  const { mutate: createAnnotationTask, isLoading } = useCreateAnnotationTaskMutation(projectId, {
    onError: (e) => showUnknowErrorMessage(e.message),
    onSuccess: () => {
      history.push(AppRoutes.annotation.task.list(projectId));
      reset();
    },
  });

  useEffect(() => {
    setUpdatingState(isLoading);
  }, [isLoading]);

  const taskInput = useRecoilValue(createTaskInput);

  function handleCreateAnnotationTask() {
    const imageIds: number[] = [];

    selectedList
      .filter((s) => s.images?.length)
      .map((s) => s.images)
      .forEach((images) => imageIds.push(...images!));

    createAnnotationTask({
      description: taskInput?.description || '',
      name: taskInput?.name || '',
      images_id: {
        project_id: [],
        path_id: selectedList.filter((s) => s.selected).map((s) => s.path!.id),
        image_id: imageIds,
      },
    });
  }

  return {
    handleCreateAnnotationTask,
    isLoading,
  };
}

export default useCreateAnnotationTask;
