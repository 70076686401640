import React, { createContext, useState, ReactNode, useContext } from 'react';
import { Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { CloseSVG } from 'src/assets/icons';
import '../assets/styles/modal.less';

type ContextTypes = {
  content: string | ReactNode;
  setContent: React.Dispatch<React.SetStateAction<string | ReactNode>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onOk: () => void;
  setOnOk: React.Dispatch<React.SetStateAction<() => void>>;
  setOnCancel: any;
  setOkText: any;
  setCancelText: any;
  width: number;
  setWidth: any;
  footer: ReactNode;
  setFooter: any;
  okButtonProps?: ButtonProps;
  setOkButtonProps?: any;
  cancelButtonProps?: ButtonProps;
  setCancelButtonProps?: any;
  setCentered?: any;
};

const ConfirmContext = createContext<ContextTypes>({
  visible: false,
  setVisible: () => {},
  content: '',
  setContent: () => {},
  onOk: () => {},
  setOnOk: () => {},
  setOnCancel: () => {},
  setOkText: () => {},
  setCancelText: () => {},
  width: 450,
  setWidth: () => {},
  footer: '',
  setFooter: () => {},
});

type Props = {
  children: ReactNode;
};

export const ConfirmProvider = ({ children }: Props) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<string | ReactNode>('');
  const [onOk, setOnOk] = useState<(option?: any) => void>(() => {});
  const [onCancel, setOnCancel] = useState<(option?: any) => void>();
  const [okText, setOkText] = useState('Confirm');
  const [cancelText, setCancelText] = useState('Cancel');
  const [width, setWidth] = useState(450);
  const [footer, setFooter] = useState();
  const [okButtonProps, setOkButtonProps] = useState();
  const [cancelButtonProps, setCancelButtonProps] = useState();
  const [centered, setCentered] = useState(false);

  return (
    <ConfirmContext.Provider
      value={{
        visible,
        setVisible,
        content,
        setContent,
        onOk,
        setOnOk,
        setOnCancel,
        setOkText,
        setCancelText,
        width,
        setWidth,
        footer,
        setFooter,
        okButtonProps,
        cancelButtonProps,
        setOkButtonProps,
        setCancelButtonProps,
        setCentered,
      }}
    >
      <Modal
        visible={visible}
        title={null}
        onCancel={() => (onCancel ? onCancel(() => setVisible(false)) : setVisible(false))}
        okButtonProps={{ style: { width: 100 }, size: 'small', ...(okButtonProps || {}) }}
        cancelButtonProps={{ style: { width: 100 }, size: 'small', ...(cancelButtonProps || {}) }}
        onOk={() => (onOk ? onOk(() => setVisible(false)) : setVisible(false))}
        cancelText={cancelText}
        okText={okText}
        closeIcon={<CloseSVG />}
        closable={true}
        maskClosable={false}
        width={width}
        style={{ top: '30%' }}
        footer={[
          <button
            className="text-#000000"
            key="ok"
            onClick={() => (onOk ? onOk(() => setVisible(false)) : setVisible(false))}
          >
            {okText || 'OK'}
          </button>,
          onCancel && (
            <button
              className="text-#000000"
              key="cancel"
              onClick={() => (onCancel ? onCancel(() => setVisible(false)) : setVisible(false))}
            >
              Cancel
            </button>
          ),
        ]}
        className="modal-custom"
        wrapClassName="z-99999"
      >
        <div className="text-#000000">{content}</div>
      </Modal>
      {children}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const {
    setContent,
    setOnOk,
    setVisible,
    setOnCancel,
    setOkText,
    setCancelText,
    setWidth,
    setFooter,
    setOkButtonProps,
    setCancelButtonProps,
    setCentered,
  } = useContext(ConfirmContext);

  const confirm = (
    content: string | ReactNode,
    action?: {
      onOk: (close: () => void) => void;
      onCancel?: (close: () => void) => void;
      okText?: string;
      cancelText?: string;
      popupWidth?: number;
      footer?: ReactNode;
      okButtonProps?: ButtonProps;
      cancelButtonProps?: ButtonProps;
      setModalType?: any;
      modalType?: string;
      centered?: boolean;
    },
  ) => {
    setContent(content);
    setVisible(true);

    if (action) {
      const {
        onOk,
        onCancel,
        okText,
        cancelText,
        popupWidth,
        footer,
        okButtonProps,
        cancelButtonProps,
        centered,
      } = action;

      setOnOk(() => onOk);
      setOnCancel(() => onCancel);
      setOkText(okText);
      setCancelText(cancelText);
      setWidth(popupWidth);
      setFooter(footer);
      setOkButtonProps(okButtonProps);
      setCancelButtonProps(cancelButtonProps);
      setCentered(!!centered);
    }
  };

  return {
    confirm,
  };
};
