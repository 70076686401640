import { useMutation, UseMutationOptions } from 'react-query';
import { PathEndpoint } from '../endpoints';
import { APIErrorResponse, UpdatePathInput, UpdatePathResponse } from 'src/types';
import { request } from 'src/helpers/request';

export type UpdatePathMutationOptions = UseMutationOptions<UpdatePathResponse, APIErrorResponse, UpdatePathInput>;

export default function useUpdatePathMutation(options?: UpdatePathMutationOptions) {
  return useMutation<UpdatePathResponse, APIErrorResponse, UpdatePathInput>(
    (values) => request.put(PathEndpoint.pathInfo(values.id), { name: values.name }).then((res) => res.data),
    options,
  );
}
