import React, { Fragment } from 'react';
import Footer from 'src/layouts/Discover/Footer';
import Header from 'src/layouts/Discover/Header';

export interface DiscoverLayoutProps {
  children: React.ReactNode;
}

export default function DiscoverLayout({ children }: DiscoverLayoutProps) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow relative">{children}</div>
      <Footer />
    </div>
  );
}
