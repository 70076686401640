import React, { FC, memo, SVGProps } from 'react';
interface Props extends SVGProps<SVGSVGElement> {}

const MapMarkerAltSVGP: FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16.94" viewBox="0 0 14 16.94" {...props}>
      <g transform="translate(0 -0.889)">
        <path d="M5.88,16.6a4.336,4.336,0,0,0-1.443-2.019L2.8,13.311A7.246,7.246,0,0,1,0,7.889a7,7,0,1,1,14,0,7.246,7.246,0,0,1-2.8,5.422L9.563,14.585A4.336,4.336,0,0,0,8.12,16.6l-.193.563a.98.98,0,0,1-1.854,0ZM7,4.343a3.266,3.266,0,1,0,3.266,3.266A3.266,3.266,0,0,0,7,4.343Z" />
      </g>
    </svg>
  );
};

export default memo(MapMarkerAltSVGP);
