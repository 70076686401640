import React, { FC, memo, useMemo } from 'react';
import { Permission } from 'src/types';
import { useProjectAccessGrantsQuery } from 'src/services/queries/useProjectAccessGrantsQuery';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from 'src/hooks/useCurrentUser';

interface Props {
  permissions?: Permission[];
  projectId?: string;
}

const PermissionRank = {
  [Permission.OWNER]: 3,
  [Permission.STAFF]: 2,
  [Permission.DEVELOPER]: 1,
  [Permission.DATAENGINEER]: 0,
};

export function useProjectPermission(permissions?: Permission[], projectId?: string) {
  const { projectId: projectIdParam } = useParams<{ projectId: string }>();

  const user = useCurrentUser();
  const currentProjectId = projectId || projectIdParam;
  const { data: accessGrants } = useProjectAccessGrantsQuery(currentProjectId, {
    onSuccess: (res) => {},
  });

  const userAccess = useMemo(() => {
    return accessGrants?.find((a) => a.user.id === user?.user.id);
  }, [user, accessGrants]);

  // const isAdmin = user?.is_admin;

  function checkPermissionGranted(allowAdmin = true) {
    // if (isAdmin && allowAdmin) return true;

    if (!userAccess || !permissions || permissions.length === 0) return false;
    const permissionRank = Math.min(...permissions.map((p) => PermissionRank[p]));
    const userPermissionRank = PermissionRank[userAccess.permission];
    return userPermissionRank >= permissionRank;
  }

  const accessGranted = checkPermissionGranted();
  return { checkPermissionGranted, accessGranted, userAccess };
}

const ProjectPermission: FC<Props> = (props) => {
  const { projectId, permissions, children } = props;

  const { checkPermissionGranted } = useProjectPermission(permissions, projectId);

  if (!checkPermissionGranted()) return null;
  return <>{children}</>;
};

export default memo(ProjectPermission);
