export const AppRoutes = {
  replaceParams(route: string, params: Record<string, string> = {}) {
    let finalRoute = route;
    Object.keys(params).forEach((key) => {
      finalRoute = finalRoute.replace(`:${key}`, params[key]);
    });
    return finalRoute;
  },
  home: '/',

  projectList: '/projects',
  projectDashboards: (id: number | string = ':projectId') => '/projects/' + id,

  login: '/login',
  logout: '/logout',
  setting: '/setting',
  about: '/about',

  images: {
    index: (projectId = ':projectId') => '/projects/' + projectId + '/images',
    list: (projectId: number | string = ':projectId', pathId: number | string = ':pathId') =>
      '/projects/' + projectId + '/images/folders/' + pathId,
    detail: '/projects/:projectId/images/:imageId/folders/:pathId',
  },

  annotation: {
    index: (projectId = ':projectId') => '/projects/' + projectId + '/annotation',
    task: {
      list: (projectId = ':projectId') => '/projects/' + projectId + '/annotation/tasks',
      selectProjects: (projectId = ':projectId') => '/projects/' + projectId + '/annotation/tasks/p',
      selectImages: (projectId = ':projectId', selectedProjectId = ':selectedProjectId', pathId = ':pathId') =>
        '/projects/' + projectId + '/annotation/tasks/p/' + selectedProjectId + '/folders/' + pathId,
      jobList: '/projects/:projectId/annotation/tasks/:taskId/jobs',
      jobAiModelList: '/projects/:projectId/annotation/tasks/:taskId/jobs/:jobId/aimodels',
    },
    images: {
      detail: '/projects/:projectId/annotation/p/:selectedProjectId/folders/:pathId/images/:imageId',
    },
  },

  training: {
    index: (projectId = ':projectId') => `/projects/${projectId}/training`,
    classes: {
      selectProjects: (projectId = ':projectId', aimodeId = ':aimodeId', classId = ':classId') =>
        '/projects/' + projectId + '/training/aimodels/' + aimodeId + '/classes/' + classId + '/sp',
      selectImages: (
        projectId = ':projectId',
        selectedProjectId = ':selectedProjectId',
        pathId = ':pathId',
        classId = ':classId',
        aimodeId = ':aimodeId',
      ) =>
        '/projects/' +
        projectId +
        '/training/aimodels/' +
        aimodeId +
        '/classes/' +
        classId +
        '/sp/' +
        selectedProjectId +
        '/folders/' +
        pathId,
    },
    images: {
      detail:
        '/projects/:projectId/training/aimodels/:aimodeId/classes/:classId/sp/:selectedProjectId/folders/:pathId/images/:imageId',
    },
    listAiModel: (projectId = ':projectId') => `/projects/${projectId}/training/aimodels/`,
    listClassPath: `/projects/:projectId/training/aimodels/:aimodeId/classes`,
    listProject: (projectId = ':projectId', classId = ':classId') =>
      `/projects/${projectId}/training/classes/${classId}/sp`,
    listProjectParam: `/projects/:projectId/training/aimodels/:aimodeId/classes/:classId/sp`,
    setting: (p = ':projectId', a: number | string = ':aiModelId') => `/projects/${p}/training/aimodels/${a}/setting`,
    matrix: '/projects/:projectId/training/aimodels/:testId/matrix',
    progress: (projectId = ':projectId', aiModelId = ':aiModelId') =>
      `/projects/${projectId}/training/aimodels/${aiModelId}/progress`,
    result: (projectId = ':projectId', aiModelId = ':aiModelId') =>
      `/projects/${projectId}/training/aimodels/${aiModelId}/result`,
    imageDetail: (projectId = ':projectId', aiModelId = ':aiModelId') =>
      `/projects/${projectId}/training/aimodels/${aiModelId}/result/images`,
  },

  tests: '/projects/:projectId/tests',
  testsParam: (projectId: number | string) => `/projects/${projectId}/tests`,
  testsClasses: {
    list: '/projects/:projectId/tests/:testId/:aimodeId/classes',
    listParam: (projectId: number, testId: number, aimodeId: number) =>
      `/projects/${projectId}/tests/${testId}/${aimodeId}/classes`,
  },
  testMethod: {
    selectProject: (projectId = ':projectId', testId = ':testId', classId = ':classId') =>
      `/projects/${projectId}/tests/${testId}/classes/${classId}/sp`,
    selectImage: (
      projectId = ':projectId',
      testId = ':testId',
      classId = ':classId',
      selectedProjectId = ':selectedProjectId',
      pathId = ':pathId',
    ) => `/projects/${projectId}/tests/${testId}/classes/${classId}/sp/${selectedProjectId}/folders/${pathId}`,

    // evaluation: '/projects/:projectId/tests/:testId/:methodType/:classId/:pathId',
    // evaluationParam: (
    //   projectId: number,
    //   testId: number,
    //   methodType: string,
    //   classId: number | string,
    //   pathId: number | string,
    // ) => `/projects/${projectId}/tests/${testId}/${methodType}/${classId}/${pathId}`,

    // prediction: '/projects/:projectId/tests/:testId/:methodType/:classId/:pathId',
    // predictionParam: (
    //   projectId: number,
    //   testId: number,
    //   methodType: string,
    //   classId: number | string,
    //   pathId: number | string,
    // ) => `/projects/${projectId}/tests/${testId}/${methodType}/${classId}/${pathId}`,
    // selectImages: (
    //   projectId = ':projectId',
    //   testId = ':testId',
    //   classId: string | number = ':classId',
    //   pathId: string | number = ':pathId',
    // ) => '/projects/' + projectId + `/tests/${testId}/evaluation/${classId}/p/folders/` + pathId,

    imagesDetail: '/projects/:projectId/tests/:testId/classId/:classId/images/:imageId/folders/:pathId',
    imagesDetailParam: (
      projectId: number | string,
      testId: number | string,
      classId: number | string,
      imageId: number | string,
      pathId: string,
    ) => `/projects/${projectId}/tests/${testId}/classId/${classId}/images/${imageId}/folders/${pathId}`,
  },
  testResultDetail: `/projects/:projectId/tests/:testId/:methodType/result-detail-view`,
  testResultDetailParam: (
    projectId = ':projectId',
    testId = ':testId',
    methodType = ':methodType',
    // classId = ':classId',
    // imageId = ':imageId',
    // pathId = ':pathId',
  ) => `/projects/${projectId}/tests/${testId}/${methodType}/result-detail-view`,
  myPage: {
    account: '/me/account',
    admin: '/me/admin',
    statistics: '/me/statistics',
    newAdmin: '/me/new-admin',
    plan: '/me/subscription',
    billing: '/me/billing',
    orderGPU: '/me/subscription/order-gpu',
  },
  results: '/projects/:projectId/tests/:testId/results',
  resultsParam: (projectId = ':projectId', testId: string = ':testId') =>
    `/projects/${projectId}/tests/${testId}/results`,

  discover: {
    home: '/discover',
    product: '/discover/product',
    plan: '/discover/plan',
    support: '/discover/support',
  },
};

export const AppTitles = {
  // home: 'Home',
  // projectList: 'Project List',
  // projectDashboards: 'Project Dashboards',
  // login: 'Login',
  // about: 'About us',
  // images: {
  //   list: 'Images',
  // },
  // trainingTitle: {
  //   listAiModel: 'Training',
  //   listClass: 'Training Classes management',
  //   listProject: 'Training Project management',
  //   Setting: 'Training Setting',
  //   Matrix: 'Training Matrix',
  // },
  // tests: 'Test management',
  // classes: {
  //   list: 'Classes management',
  // },
  // myPage: {
  //   dashboard: 'Dashboard - My page',
  //   account: 'Account - My page',
  //   admin: 'Admin - My page',
  //   statistics: 'Statistics - My page',
  // },
  // results: 'Results',
  // testMethod: {
  //   evaluation: 'Test Method Evaluation',
  //   prediction: 'Test Method Prediction',
  // },
  // resultsDetail: 'Result Detail',
};
