import React, { FC, memo, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const CaseOutlineSVGP: FC<Props> = (props) => {
  const { fill, ...rest } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="27.953" viewBox="0 0 34 27.953" {...rest}>
      <g id="Group_632" data-name="Group 632" transform="translate(0 -12.347)">
        <path
          id="Path_288"
          data-name="Path 288"
          d="M9.231,12.347v5.64H0V40.3H34V17.987H24.767v-5.64Zm2.118,2.125H22.642v3.515H11.349Zm-9.224,5.64h29.75v5.311H29.051V22.66H22.877v2.762H11.113V22.66H4.941v2.762H2.125Zm4.941,4.673H8.988v3.389H7.066Zm17.938,0h1.922v3.389H25ZM2.125,27.548H4.941V30.3h6.172V27.548H22.877V30.3h6.174V27.548h2.824V38.175H2.125Z"
        />
      </g>
    </svg>
  );
};

export default memo(CaseOutlineSVGP);
