import { Link, NavLink } from 'react-router-dom';
import { useClickOutside } from 'src/hooks';
import { Fragment, useRef, useState } from 'react';
import UserDropdown from '../MainLayout/UserDropdown';
import { AppRoutes } from 'src/helpers';
export interface HeaderProps {
  isStatistic?: boolean;
}

export default function Header(props: HeaderProps) {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const detailContainerRef = useRef<HTMLDivElement>(null);

  useClickOutside(detailContainerRef, () => isDetailOpen && setIsDetailOpen(false));

  return (
    <Fragment>
      <div className="sticky top-0 left-0 w-full z-50" style={{ minWidth: 1024 }}>
        <div className="h-74px flex bg-white">
          <div className="w-82px flex-center">
            <Link to={AppRoutes.discover.home}>
              <img src="/v2/logo/Icon-x4.png" className="w-40px relative top-1" alt="logo" />
            </Link>
          </div>
          <div className="bg-#1C3F49 pl-40px pr-80px flex justify-between items-center flex-grow">
            <div className="flex items-center space-x-4">
              <span className="text-36px font-medium text-white">STATISTICS</span>
            </div>
            <div className="flex items-center">
              <div className="text-36px font-medium mr-30px text-white">
                <Link to={AppRoutes.discover.product} className="hover:text-white">
                  PRODUCTS
                </Link>
              </div>
              <div className="text-36px font-medium mr-30px text-white">
                <Link to={AppRoutes.discover.plan} className="hover:text-white">
                  PLANS
                </Link>
              </div>
              <div className="text-36px font-medium mr-74px text-white">
                <Link to={AppRoutes.discover.support} className="hover:text-white">
                  SUPPORT
                </Link>
              </div>
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
