import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, memo, SetStateAction, useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks';
import { DotMoreSVG, ImageThumbnailSVG } from 'src/assets/icons';
import CheckboxOutline from './CheckboxOutline';
import { ImageData } from 'src/pages/Images/List';
import { useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from 'src/helpers';

import { getImageThumbnailUrl, showUnknowErrorMessage } from 'src/utils';
import { TypeModuleImageEnum } from 'src/types';
import React from 'react';
import { Col, Input, Row } from 'antd';
import { Button } from 'src/components';
import useEditImageNameMutation from 'src/services/mutations/useEditImageName';
import { ImageEntity } from 'src/types/image';
import DbClick from 'src/components/DbClick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TextTooltip from 'src/components/TextTooltip';
import { formatDate } from 'src/helpers/common';

export interface ImageCardProps {
  displayType?: 'LIST' | 'GRID';
  onSelectedChange?: (select?: boolean) => void;
  image: ImageData;
  typeActions: TypeModuleImageEnum;
  onDeleteImage: () => void;
  onUpdateImage?: (image: ImageEntity) => void;
  onShiftSelectedChange: (selected: boolean) => void;
}

export default memo(function ImageCard({
  displayType = 'GRID',
  onSelectedChange,
  image,
  typeActions,
  onDeleteImage,
  onUpdateImage,
  onShiftSelectedChange,
}: ImageCardProps) {
  const [selected, setSelected] = useState(!!image.selected);

  const [draft, setDraft] = useState(false);

  const { projectId, pathId } = useParams<{ projectId: string; pathId: string }>();

  const [name, setName] = useState(image.entity.name);

  const history = useHistory();

  useEffect(() => {
    if (!draft) {
      setName(image.entity.name);
    }
  }, [draft, image]);

  useEffect(() => {
    setSelected(!!image.selected);
  }, [image]);

  const { mutate: editImageName, isLoading } = useEditImageNameMutation({
    onError: (res) => showUnknowErrorMessage(res.message),
    onSuccess: (res) => {
      onUpdateImage && onUpdateImage(res);
      setDraft(false);
    },
  });

  function handleEdit() {
    editImageName({
      id: image.entity.id,
      name,
    });
  }

  if (draft) {
    if (displayType === 'LIST') {
      return (
        <div className="w-full h-80px px-30px flex items-center relative bg-#F5F5F5 border border-#CCCCCC">
          <div className="flex space-x-30px flex-shrink-0s">
            <div className="w-20px" />
            <ImageThumbnailSVG className="flex-shrink-0" />
          </div>
          <div className="border-b ml-30px">
            <Input
              value={name}
              onChange={(e) => setName(e.target.value.trimLeft())}
              placeholder="Image name"
              bordered={false}
              className="text-20px placeholder-italic px-0 text-center"
            />
          </div>
          <div className="ml-auto flex space-x-4">
            <Button
              type="_FFFFFF"
              className="w-32 rounded-full flex-center"
              style={{ paddingTop: 2 }}
              disabled={isLoading}
              onClick={() => setDraft(false)}
            >
              <span className="text-12px font-normal text-inherit">CANCEL</span>
            </Button>
            <Button
              type="_00BEEB"
              className="w-32 rounded-full flex-center"
              style={{ paddingTop: 2 }}
              disabled={!name || isLoading}
              loading={isLoading}
              onClick={handleEdit}
            >
              <span className="text-12px font-normal text-inherit">EDIT</span>
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="w-138px h-259px p-10px flex flex-col select-none bg-#F5F5F5">
        <div className="w-20px h-20px" />
        <div className="px-3 mt-10px mb-2">
          <LazyLoadImage
            alt={image.entity.name}
            src={getImageThumbnailUrl(image.entity.id)}
            className="w-full h-74px object-cover text-8px"
            placeholder={<ImageThumbnailSVG />}
          />
        </div>
        <div className="border-b mx-20px">
          <Input
            value={name}
            onChange={(e) => setName(e.target.value.trimLeft())}
            placeholder="Image name"
            bordered={false}
            className="text-10px font-medium px-0 text-center"
          />
        </div>
        <div className="mt-auto flex flex-col items-center justify-center space-y-2">
          <Button
            type="_FFFFFF"
            className="h-30px rounded-full flex-center w-80px"
            style={{ paddingTop: 2 }}
            disabled={isLoading}
            onClick={() => setDraft(false)}
          >
            <span className="text-10px font-normal text-inherit">CANCEL</span>
          </Button>
          <Button
            type="_00BEEB"
            className="h-30px rounded-full flex-center w-80px"
            style={{ paddingTop: 2 }}
            disabled={!name || isLoading}
            loading={isLoading}
            onClick={handleEdit}
          >
            <span className="text-10px font-normal text-inherit">EDIT</span>
          </Button>
        </div>
      </div>
    );
  }

  if (displayType === 'GRID')
    return (
      <DbClick
        className={`w-138px h-259px p-10px flex flex-col select-none cursor-pointer ${
          selected ? 'bg-#00BEEB' : 'bg-#F5F5F5'
        } transition duration-300 ease-in-out`}
        onClick={(e) => {
          if (e.shiftKey) {
            onShiftSelectedChange(!selected);
          } else {
            onSelectedChange ? onSelectedChange(!selected) : setSelected(!selected);
          }
        }}
        onDoubleClick={() => {
          if (typeActions === TypeModuleImageEnum.IMAGE)
            history.push(
              AppRoutes.replaceParams(AppRoutes.images.detail, {
                projectId: projectId,
                imageId: image.entity.id.toString(),
                pathId: pathId,
              }),
            );
        }}
      >
        <div className="flex items-center justify-between">
          <CheckboxOutline checked={selected} />
          {typeActions === TypeModuleImageEnum.IMAGE && (
            <ItemAction onDelete={onDeleteImage} onToggleDraftChange={setDraft} />
          )}
        </div>

        <div className="px-2 mt-10px mb-1">
          <LazyLoadImage
            alt={image.entity.name}
            src={getImageThumbnailUrl(image.entity.id)}
            className="w-full h-74px object-cover text-8px"
            placeholder={<ImageThumbnailSVG />}
          />
        </div>
        <div className="text-center flex flex-col justify-between items-center flex-grow">
          <TextTooltip className="text-10px font-medium line-clamp-2 break-all" lineClamp={2}>
            {image.entity.name}
          </TextTooltip>
          <div className={`flex flex-col space-y-10px ${selected ? 'text-black' : 'text-#888888'}`}>
            {typeActions === TypeModuleImageEnum.IMAGE ? (
              <>
                <span className="text-10px font-light">{image.entity.creator}</span>
                <span className="text-10px font-light">{formatDate(image.entity.created_at!)}</span>
                <span className="text-10px font-light">
                  {image.entity.width} x {image.entity.height}
                </span>
              </>
            ) : (
              <>
                <span className="text-10px font-light">{image.entity.creator}</span>
                <span className="text-10px font-light">
                  {image.entity.width} x {image.entity.height}
                </span>
                <span className="text-10px font-light">{formatDate(image.entity.created_at!)}</span>
              </>
            )}
          </div>
          <div className="flex h-18px justify-center space-x-5px">
            {image.entity.public && (
              <span
                className="text-8px bg-#0E7E99 rounded-full inline-block leading-none text-white p-2px"
                style={{ padding: '5px 12px' }}
              >
                Public
              </span>
            )}
            {image.entity.annotated && (
              <span
                className="text-8px bg-#FCC103 rounded-full inline-block leading-none"
                style={{ padding: '5px 12px' }}
              >
                Annotated
              </span>
            )}
          </div>
        </div>
      </DbClick>
    );

  return (
    <DbClick
      className={`w-full h-80px px-30px flex items-center space-x-30px relative cursor-pointer ${
        selected ? 'bg-#00BEEB' : 'bg-#F5F5F5 border border-#CCCCCC'
      } transition duration-300 ease-in-out`}
      onClick={(e) => {
        if (e.shiftKey) {
          onShiftSelectedChange(!selected);
        } else {
          onSelectedChange ? onSelectedChange(!selected) : setSelected(!selected);
        }
      }}
      onDoubleClick={() => {
        // if (typeActions === TypeModuleImageEnum.IMAGE)
        history.push(
          AppRoutes.replaceParams(AppRoutes.images.detail, {
            projectId: projectId,
            imageId: image.entity.id.toString(),
            pathId: pathId,
          }),
        );
      }}
    >
      <div className="flex items-center space-x-30px" style={{ width: '50%' }}>
        <CheckboxOutline checked={selected} />
        <ImageThumbnailSVG className="flex-shrink-0" />
        <TextTooltip lineClamp={1} className="text-20px font-bold line-clamp-1 break-words">
          {image.entity.name}
        </TextTooltip>
      </div>
      <div
        className={`${typeActions === TypeModuleImageEnum.IMAGE ? 'flex items-center justify-end' : ''}`}
        style={{ width: typeActions === TypeModuleImageEnum.IMAGE ? '30%' : '60%' }}
      >
        {typeActions === TypeModuleImageEnum.IMAGE ? (
          <>
            <div className="mr-auto text-12px" style={{ width: '30%' }}>
              {image.entity.creator}
            </div>
            <div className="text-12px" style={{ width: '35%' }}>
              {formatDate(image.entity.created_at!)}
            </div>
            <div className="text-12px" style={{ width: '35%' }}>
              {image.entity.width} x {image.entity.height}
            </div>
          </>
        ) : (
          <Row gutter={18}>
            <Col span={7}>
              <div className="text-base text-left">
                {image.entity.width} x {image.entity.height}
              </div>
            </Col>
            <Col span={9}>
              <div className="mr-auto text-base text-left">{image.entity.creator}</div>
            </Col>
            <Col span={8}>
              <div className="text-base text-right">{formatDate(image.entity.created_at!)}</div>
            </Col>
          </Row>
        )}
      </div>
      <div
        className="flex items-center justify-end space-x-40px"
        style={{ width: typeActions === TypeModuleImageEnum.IMAGE ? '30%' : '0' }}
      >
        <div className="flex items-center space-x-5px">
          {image.entity.public && (
            <div
              className="text-8px bg-#0E7E99 rounded-full inline-block flex-shrink-0 text-center leading-none text-white"
              style={{ padding: '4px 9px' }}
            >
              Public
            </div>
          )}
          {image.entity.annotated && (
            <div
              className="text-8px bg-#FCC103 rounded-full inline-block flex-shrink-0 text-center leading-none"
              style={{ padding: '4px 9px' }}
            >
              Annotated
            </div>
          )}
        </div>

        {typeActions === TypeModuleImageEnum.IMAGE && (
          <ItemAction size="large" onDelete={onDeleteImage} onToggleDraftChange={setDraft} />
        )}
      </div>
    </DbClick>
  );
});
interface ItemActionProps {
  size?: 'large';
  onDelete: () => void;
  onToggleDraftChange: Dispatch<SetStateAction<boolean>>;
}

function ItemAction({ size, onDelete, onToggleDraftChange }: ItemActionProps) {
  const [visible, setVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => visible && setVisible(false));

  const isLarge = size === 'large';

  return (
    <div className="relative flex-center" ref={ref} onClick={(e) => e.stopPropagation()}>
      <div
        className={`flex-center cursor-pointer hover:bg-#DDDDDD ${isLarge ? 'w-40px h-40px' : 'w-18px h-18px'} ${
          visible ? 'bg-#DDDDDD' : ''
        }`}
        onClick={() => setVisible(!visible)}
      >
        <DotMoreSVG className={`text-black ${isLarge ? 'w-20px h-20px' : 'w-10px h-10px'}`} />
      </div>
      <AnimatePresence>
        {visible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute w-24 py-1 bg-white shadow-drop -bottom-2 right-0 transform translate-y-full"
            style={{ zIndex: 1 }}
          >
            <div
              className="text-12px px-4 py-2 cursor-pointer hover:bg-#F5F5F5"
              onClick={() => onToggleDraftChange((draft) => !draft)}
            >
              Edit Name
            </div>
            <div className="text-12px text-#FF0000 px-4 py-2 cursor-pointer hover:bg-#F5F5F5" onClick={onDelete}>
              Delete
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
