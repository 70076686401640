import React, { FC, memo, SVGProps } from 'react';
interface Props extends SVGProps<SVGSVGElement> {}

const EnvelopeSVGP: FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11.2" viewBox="0 0 14 11.2" {...props}>
      <g transform="translate(-2 -4)">
        <path
          d="M9.744,9.642a.7.7,0,0,1-.938,0L2.87,4.245A1.4,1.4,0,0,1,3.661,4h11.2a1.4,1.4,0,0,1,.791.245Z"
          transform="translate(-0.261)"
        />
        <path
          d="M16,6.26v7.518a2.1,2.1,0,0,1-2.1,2.1H4.1a2.1,2.1,0,0,1-2.1-2.1V6.26L8.076,11.8a1.4,1.4,0,0,0,1.855-.014Z"
          transform="translate(0 -0.678)"
        />
      </g>
    </svg>
  );
};

export default memo(EnvelopeSVGP);
