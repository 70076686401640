import React, { useState } from 'react';
import { Checkbox, Form, Input, Select, Tooltip } from 'antd';
import produce from 'immer';
import { useUILogin } from '../context/modal';
import {
  RULE_CONFIRM_PASSWORD,
  RULE_EMAIL,
  RULE_NAME,
  RULE_PASSWORD,
  RULE_TIME_ZONE,
  RULE_USERNAME,
} from '../helper/validate';
import useRegisterMutation from 'src/services/mutations/useRegisterMutation';
import { PlanTypeEnum, RegisterInput, RegisterResponse } from 'src/types';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useConfirm } from 'src/hooks';
import { EnvelopeSVGP, LockSVGP, MapMarkerAltSVGP, SearchSVGP, UserSVGP } from 'src/components/icons';
import data from '../helper/time_zone.json';
import '../styles/index.less';
import { Button } from 'src/components';
import { InfoSVG } from 'src/icons';
import { useAlert } from 'src/hooks/useAlert';
import useQueryString from 'src/hooks/useQuerystring';

const { Option } = Select;

const SignUpForm: React.FC = () => {
  const [form] = Form.useForm<RegisterInput>();
  const { openModal, setModalView, setModalContent, setModalAction } = useUILogin();
  const { confirm } = useConfirm();
  const [fields, setFields] = useState<RegisterInput>({
    user: { email: '', first_name: '', last_name: '', username: '' },
    password1: '',
    password2: '',
    time_zone: '',
  });

  const [checked, setChecked] = useState(false);

  const isFormValid =
    fields.password1 &&
    fields.password2 &&
    fields.time_zone &&
    fields.user.email &&
    fields.user.first_name &&
    fields.user.last_name &&
    fields.user.username;

  const { alert } = useAlert();

  const { plan } = useQueryString<{ plan?: PlanTypeEnum }>();

  const { mutate, isLoading } = useRegisterMutation({
    onError: ({ response }) => {
      const error = response?.data.message || {};

      confirm(
        <div className="text-center text-#00BEEB">
          {error[Object.keys(error)?.[0]]?.[0] || 'Oops! Something went wrong!'}
        </div>,
      );
    },
    onSuccess: (res) => {
      if (plan === PlanTypeEnum.PLAN_PRO || plan === PlanTypeEnum.PLAN_BASIC) {
        alert(
          <div className="text-16px leading-20px text-center px-40px pb-55px">
            Go to Account {'>'} Plan and upgrade to {plan === PlanTypeEnum.PLAN_PRO ? 'Professional' : 'Basic'} plan
            upon sign-in
          </div>,
          {
            onOk: (close) => {
              close();
              registerSuccess(res);
            },
            onCancel: (close) => {
              close();
              registerSuccess(res);
            },
          },
        );
      } else {
        registerSuccess(res);
      }
    },
  });

  const registerSuccess = (res: RegisterResponse) => {
    setModalContent({ email: res.user.email });
    setModalAction({ closable: true, width: 1050 });
    setModalView('ALMOST_THERE');
    openModal();
  };

  const handleFinish = (values: RegisterInput) => {
    mutate(
      produce(values, (draft) => {
        draft.user.first_name = values.user.first_name.trim();
        draft.user.last_name = values.user.last_name.trim();
        draft.user.username = values.user.username.trim();
      }),
    );
  };

  const handleFinishFailed = ({ errorFields }: ValidateErrorEntity<RegisterInput>) => {
    confirm(
      <div className="text-center text-#00BEEB" dangerouslySetInnerHTML={{ __html: errorFields?.[0]?.errors?.[0] }} />,
    );
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      className="form-custom sign-up-form"
      onFinishFailed={handleFinishFailed}
      validateTrigger={false}
      onValuesChange={(_, allValues) => {
        setFields(allValues);
      }}
    >
      <Form.Item name={['user', 'username']} rules={RULE_USERNAME} noStyle>
        <Input
          prefix={<UserSVGP />}
          placeholder="Username"
          bordered={false}
          className={`username ${fields?.user?.username?.length > 0 && 'selected'}`}
        />
      </Form.Item>
      <div className="flex justify-between name-wrapper">
        <Form.Item className="input-inline" name={['user', 'first_name']} rules={RULE_NAME} noStyle>
          <Input
            prefix={<UserSVGP />}
            placeholder="First Name"
            bordered={false}
            className={`w-full ${fields?.user?.first_name?.length > 0 && 'selected'}`}
          />
        </Form.Item>
        <Form.Item className="input-inline" name={['user', 'last_name']} rules={RULE_NAME} noStyle>
          <Input
            placeholder="Last Name"
            bordered={false}
            className={`w-full ${fields?.user?.last_name?.length > 0 && 'selected'}`}
          />
        </Form.Item>
      </div>
      <Form.Item name={['user', 'email']} rules={RULE_EMAIL} noStyle>
        <Input
          prefix={<EnvelopeSVGP />}
          placeholder="Email"
          bordered={false}
          className={`${fields?.user?.email?.length > 0 && 'selected'}`}
        />
      </Form.Item>
      <Form.Item name="password1" rules={RULE_PASSWORD} noStyle>
        <Input
          prefix={<LockSVGP />}
          placeholder="Password"
          bordered={false}
          type="password"
          className={`password ${fields?.password1?.length > 0 && 'selected'}`}
          suffix={
            <Tooltip
              overlayClassName="password-info"
              placement="bottomRight"
              title={<span>At least 8 characters. Mix of letters and numbers.</span>}
            >
              <InfoSVG />
            </Tooltip>
          }
        />
      </Form.Item>
      <Form.Item name="password2" dependencies={['password1']} rules={RULE_CONFIRM_PASSWORD('password1')} noStyle>
        <Input
          prefix={<LockSVGP />}
          placeholder="Retype Password"
          bordered={false}
          type="password"
          className={`${fields?.password2?.length > 0 && 'selected'}`}
        />
      </Form.Item>

      <Form.Item noStyle>
        <div className={`select-custom ${fields?.time_zone?.length > 0 && 'selected'}`}>
          <div className="left-icon">
            <MapMarkerAltSVGP />
          </div>
          <div className="selection-wrapper">
            <Form.Item name="time_zone" rules={RULE_TIME_ZONE} noStyle>
              <Select
                showSearch
                placeholder="Choose Your Time zone"
                size="large"
                bordered={false}
                suffixIcon={null}
                optionFilterProp="children"
                allowClear={true}
                dropdownClassName="selection-dropdown"
              >
                {data.map((i) => (
                  <Option value={i.value} key={i.value}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="right-icon search-svg">
            <SearchSVGP className="fill-white" />
          </div>
        </div>
      </Form.Item>
      <Checkbox onChange={(e) => setChecked(e.target.checked)}>
        <span>
          By signing up with CamerAI, you agree with <br /> CamerAI’s{' '}
          <a href={process.env.REACT_APP_CAMERAI_TERMS_URL} target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href={process.env.REACT_APP_CAMERAI_PRIVACY_URL} target="_blank">
            Privacy Policy
          </a>
          .
        </span>
      </Checkbox>
      <Form.Item className="text-center mx-auto button-wrapper">
        <Button
          block
          shape="round"
          type="_00BEEB"
          htmlType="submit"
          className="uppercase text-12px"
          loading={isLoading}
          disabled={isLoading || !checked || !isFormValid}
        >
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignUpForm;
