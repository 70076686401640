import { Spin } from 'antd';
import React, { ReactChild } from 'react';
import { useRecoilValue } from 'recoil';
import { MainLayout } from 'src/layouts';
import SelectedSideBar from 'src/pages/Annotation/CreateTask/SelectedSideBar';
import { updatingState } from 'src/recoil-stores/create-annotation';

import './index.less';
import SelectedSideBarForTest from 'src/modules/DataSelected/SelectedSideBar';

export interface SelectAnnotationLayoutProps {
  children: ReactChild;
}

export default function SelectAnnotationLayout({ children }: SelectAnnotationLayoutProps) {
  const updating = useRecoilValue(updatingState);

  return (
    <MainLayout>
      <Spin spinning={updating} wrapperClassName="overflow-none">
        <div className="relative flex">
          <SelectedSideBar />
          {children}
        </div>
      </Spin>
    </MainLayout>
  );
}

export function SelectTestLayout({ children }: SelectAnnotationLayoutProps) {
  const updating = useRecoilValue(updatingState);

  return (
    <MainLayout>
      <Spin spinning={updating} wrapperClassName="overflow-none">
        <div className="relative flex">
          <SelectedSideBarForTest />
          {children}
        </div>
      </Spin>
    </MainLayout>
  );
}
