import { FC, memo, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const TimesCircleSVGP: FC<Props> = (props) => {
  return (
    <svg
      id="Group_220"
      data-name="Group 220"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        id="Path_219"
        data-name="Path 219"
        d="M30.878,21.079a.478.478,0,0,0-.677,0l-4.3,4.3-4.3-4.3a.479.479,0,1,0-.677.677l4.3,4.3-4.3,4.3a.479.479,0,0,0,.677.677l4.3-4.3,4.3,4.3a.479.479,0,0,0,.677-.677l-4.3-4.3,4.3-4.3A.478.478,0,0,0,30.878,21.079ZM25.9,14.054a12,12,0,1,0,12,12A12.013,12.013,0,0,0,25.9,14.054Zm0,23.043h0A11.042,11.042,0,1,1,36.945,26.054,11.056,11.056,0,0,1,25.9,37.1Z"
        transform="translate(-13.903 -14.054)"
        fill="#888"
      />
    </svg>
  );
};

export default memo(TimesCircleSVGP);
