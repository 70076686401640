import React, { FC, memo, SVGProps } from 'react';
interface Props extends SVGProps<SVGSVGElement> {}

const LockSVGP: FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17.345" viewBox="0 0 14 17.345" {...props}>
      <path
        d="M36.244,17.69a6.593,6.593,0,0,0,.1-1.135,6.206,6.206,0,1,0-12.406,0,6.58,6.58,0,0,0,.1,1.135,1.934,1.934,0,0,0-.9,1.632v6.253a1.937,1.937,0,0,0,1.937,1.937H35.2a1.936,1.936,0,0,0,1.936-1.937h0V19.322A1.933,1.933,0,0,0,36.244,17.69ZM31,23.15v1.359a.855.855,0,1,1-1.71,0v-1.36a1.775,1.775,0,1,1,1.71,0Zm2.988-5.765H26.3a4.3,4.3,0,0,1-.081-.83,3.928,3.928,0,1,1,7.847,0A4.291,4.291,0,0,1,33.984,17.385Z"
        transform="translate(-23.141 -10.167)"
      />
    </svg>
  );
};

export default memo(LockSVGP);
