import React, { createContext, useState, ReactNode, useContext } from 'react';
import { Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { CloseSVG } from 'src/assets/icons';
import './index.less';
import classNames from 'classnames';

type ContextTypes = {
  content: string | ReactNode;
  setContent: React.Dispatch<React.SetStateAction<string | ReactNode>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onOk: () => void;
  setOnOk: React.Dispatch<React.SetStateAction<() => void>>;
  setOnCancel: any;
  width: number;
  setWidth: any;
  footer: ReactNode;
  setFooter: any;
  okButtonProps?: ButtonProps;
  setOkButtonProps?: any;
  cancelButtonProps?: ButtonProps;
  setCancelButtonProps?: any;
  modalClassName?: any;
  setModalClassName?: any;
};

const AlertContext = createContext<ContextTypes>({
  visible: false,
  setVisible: () => {},
  content: '',
  setContent: () => {},
  onOk: () => {},
  setOnOk: () => {},
  setOnCancel: () => {},
  width: 450,
  setWidth: () => {},
  footer: '',
  setFooter: () => {},
});

type Props = {
  children: ReactNode;
};

export const AlertProvider = ({ children }: Props) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<string | ReactNode>('');
  const [onOk, setOnOk] = useState<(option?: any) => void>(() => {});
  const [onCancel, setOnCancel] = useState<(option?: any) => void>();
  const [width, setWidth] = useState(450);
  const [footer, setFooter] = useState();
  const [okButtonProps, setOkButtonProps] = useState();
  const [cancelButtonProps, setCancelButtonProps] = useState();
  const [modalClassName, setModalClassName] = useState();

  return (
    <AlertContext.Provider
      value={{
        visible,
        setVisible,
        content,
        setContent,
        onOk,
        setOnOk,
        setOnCancel,
        width,
        setWidth,
        footer,
        setFooter,
        okButtonProps,
        cancelButtonProps,
        setOkButtonProps,
        setCancelButtonProps,
        modalClassName,
        setModalClassName,
      }}
    >
      <Modal
        visible={visible}
        zIndex={1001}
        title={null}
        closable={false}
        maskClosable={false}
        width={374}
        footer={null}
        bodyStyle={{ padding: 0, borderRadius: 30, overflow: 'hidden' }}
        style={{ top: '30%' }}
        className={classNames('modal-rounded-30', modalClassName)}
      >
        <div className="p-20px flex justify-end">
          <CloseSVG
            className="cursor-pointer"
            onClick={() => (onCancel ? onCancel(() => setVisible(false)) : setVisible(false))}
          />
        </div>
        {content}
        <div className="flex border-t border-#E2E4E5">
          <div
            className="w-full flex-center cursor-pointer select-none py-20px border-r border-#E2E4E5"
            onClick={() => (onOk ? onOk(() => setVisible(false)) : setVisible(false))}
          >
            <span className="text-16px">OK</span>
          </div>
        </div>
      </Modal>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const {
    setContent,
    setOnOk,
    setVisible,
    setOnCancel,
    setWidth,
    setFooter,
    setOkButtonProps,
    setCancelButtonProps,
    setModalClassName,
  } = useContext(AlertContext);

  const alert = (
    content: string | ReactNode,
    action?: {
      onOk: (close: () => void) => void;
      onCancel?: (close: () => void) => void;
      popupWidth?: number;
      footer?: ReactNode;
      okButtonProps?: ButtonProps;
      cancelButtonProps?: ButtonProps;
      setModalType?: any;
      modalType?: string;
      modalClassName?: string;
    },
  ) => {
    setContent(content);
    setVisible(true);

    if (action) {
      const { onOk, onCancel, popupWidth, footer, okButtonProps, cancelButtonProps, modalClassName } = action;

      setOnOk(() => onOk);
      setOnCancel(() => onCancel);
      setWidth(popupWidth);
      setFooter(footer);
      setOkButtonProps(okButtonProps);
      setCancelButtonProps(cancelButtonProps);
      setModalClassName?.(modalClassName);
    }
  };

  const close = () => {
    setVisible(false);
  };

  return {
    alert,
    close,
  };
};
