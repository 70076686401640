import { Button } from 'src/components';
import { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useClickOutside } from 'src/hooks';
import { useLogoutQuery } from 'src/services/queries/useLogoutQuery';
import { useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { AppRoutes } from 'src/helpers';
import { useCurrentUser } from 'src/hooks/useCurrentUser';

export interface UserDropdownProps {}

export default function UserDropdown(props: UserDropdownProps) {
  const [visible, setVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => visible && setVisible(false));

  const client = useQueryClient();
  const history = useHistory();

  const { mutate: logout, isLoading } = useLogoutQuery({
    onSuccess: () => {
      client.clear();
      localStorage.clear();
      history.push(AppRoutes.login);
    },
  });

  function handleLogout() {
    !isLoading && logout();
  }

  const user = useCurrentUser();

  const color = colorTypes[(user?.user.id || 0) % 20];

  const links = [
    {
      label: 'Account',
      href: AppRoutes.myPage.account,
    },
    user?.is_admin
      ? {
          label: 'Admin Page',
          href: AppRoutes.myPage.admin,
        }
      : {
          label: 'Statistics',
          href: AppRoutes.myPage.statistics,
        },
  ];

  return (
    <div className="relative" ref={containerRef} style={{ zIndex: 51 }}>
      <Button
        type={color as any}
        className={`w-30px h-30px rounded-full text-20px text-inherit font-medium leading-none p-0 ${
          visible ? 'active' : ''
        }`}
        onClick={() => setVisible(!visible)}
      >
        <span className="uppercase">{user?.user.username.slice(0, 1)}</span>
        <AnimatePresence>
          {visible && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="user_dropdown"
              className="absolute w-100px -bottom-3 right-0 transform translate-y-full text-left"
            >
              <div className="py-1 bg-white" style={{ boxShadow: '0px 6px 10px #0000001A' }}>
                {links.map((item) => (
                  <Link key={item.href} to={item.href}>
                    <div
                      className="text-12px px-4 py-2 cursor-pointer hover:bg-#F5F5F5 text-black"
                      onClick={() => setVisible(false)}
                    >
                      {item.label}
                    </div>
                  </Link>
                ))}
                <div
                  className="text-12px text-#E6263D px-4 py-2 cursor-pointer hover:bg-#F5F5F5"
                  onClick={handleLogout}
                >
                  Sign Out
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </Button>
    </div>
  );
}

export const colorTypes = [
  '_707070',
  '_BD0034',
  '_FCC103',
  '_42DABF',
  '_00BEEB',
  '_925DD8',
  '_EE3554',
  '_404DFF',
  '_FF8EA1',
  '_5CA5FF',
  '_6A5F00',
  '_000000',
  '_0E7E99',
  '_008704',
  '_FF7700',
  '_FFDD00',
  '_7A4A25',
  '_D649C4',
  '_989834',
  '_BE7944',
];
