import { Button } from 'antd';
import React from 'react';
import { useNavigation } from 'src/hooks';
import { AuthTabEnum } from 'src/pages/Login';
import { useUILogin } from '../context/modal';
import '../styles/modal.less';

interface Props {
  email?: string;
}

const AlmostThere: React.FC<Props> = ({ email }) => {
  const { closeModal } = useUILogin();

  const navigation = useNavigation<{ tab: AuthTabEnum }>();

  const handleClose = () => {
    closeModal();
    navigation.navigate({ tab: AuthTabEnum.SIGN_IN });
  };

  return (
    <div className="modal-almost-there">
      <h4>Check your email</h4>
      <div className="description">
        <p>For your security, an email verification has been sent to</p>
        <span>{email}</span>
        <p>Please follow instructions in the email to verify your account.</p>
      </div>
      <div className="button">
        <Button onClick={handleClose} type="primary" shape="round">
          <span className="text-12px">Return to Sign In</span>
        </Button>
      </div>
    </div>
  );
};

export default AlmostThere;
