import { FC, memo, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const EyeSVGP: FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="9.599" viewBox="0 0 18 9.599" {...props}>
      <g transform="translate(0 0)">
        <circle cx="1.835" cy="1.835" r="1.835" transform="translate(7.165 2.95)" />
        <path
          d="M0,4.762s8.084,10.884,18,0C8.084-5.953,0,4.762,0,4.762ZM9.278,8.378a3.135,3.135,0,0,1-.37.024A3.1,3.1,0,0,1,7.6,8.111a3.615,3.615,0,1,1,1.678.267Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export default memo(EyeSVGP);
