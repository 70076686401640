import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { APIErrorResponse, GetPathInfoQueryParams, GetPathInfoQueryResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { PathEndpoint } from '../endpoints';
import { PathEntity } from 'src/types/path';

export type GetPathInfoQueryOptions = UseQueryOptions<GetPathInfoQueryFunc, APIErrorResponse, GetPathInfoQueryResponse>;
export type GetPathInfoQueryFunc = () => GetPathInfoQueryResponse;

export function useGetPathInfoQuery(id: string, options?: GetPathInfoQueryOptions) {
  return useQuery<GetPathInfoQueryFunc, APIErrorResponse, GetPathInfoQueryResponse>(
    ['path_info_query', id],
    () => request.get(PathEndpoint.pathInfo(id)).then((res) => res.data),
    options,
  );
}

export type GetPathInfoLazyQueryOptions = UseMutationOptions<
  GetPathInfoQueryResponse,
  APIErrorResponse,
  GetPathInfoQueryParams
>;

export function useGetPathInfoLazyQuery(options?: GetPathInfoLazyQueryOptions) {
  return useMutation<GetPathInfoQueryResponse, APIErrorResponse, GetPathInfoQueryParams>(
    'GetPathInfo_lazy_query',
    (params) => request.get(PathEndpoint.pathInfo(params.id)).then((res) => res.data),
    options,
  );
}

export async function getPathInfo(id: string | number) {
  return await request.get<PathEntity>(PathEndpoint.pathInfo(id)).then((res) => res.data);
}
