import { FC, memo, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const EyeSlashSVGP: FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.295" viewBox="0 0 18 13.295" {...props}>
      <g id="Group_1040" data-name="Group 1040" transform="translate(-1.188 -3.225)">
        <path
          id="Path_98"
          data-name="Path 98"
          d="M6.836,10.427a3.769,3.769,0,0,1-.1-.818,3.456,3.456,0,0,1,3.456-3.456h.277L11.4,5.069A8.492,8.492,0,0,0,10.188,5C6.029,5,2.515,8.076,1.363,9.205a.553.553,0,0,0-.025.781l.025.025a17.684,17.684,0,0,0,3.549,2.673Z"
          transform="translate(0 0.27)"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M17.978,9A17.684,17.684,0,0,0,14.429,6.33L12.5,8.634a3.793,3.793,0,0,1,.1.772,3.456,3.456,0,0,1-3.456,3.456H8.876L8,13.923a8.381,8.381,0,0,0,1.152.092c4.159,0,7.673-3.076,8.825-4.205A.553.553,0,0,0,18,9.028Z"
          transform="translate(1.036 0.472)"
        />
        <path
          id="Path_100"
          data-name="Path 100"
          d="M15.533,4.118l-.968-.83a.253.253,0,0,0-.334,0L4.011,15.271a.242.242,0,0,0-.028.341.157.157,0,0,0,.028.028l.956.818a.253.253,0,0,0,.334,0L15.533,4.475a.218.218,0,0,0,.052-.305A.221.221,0,0,0,15.533,4.118Z"
          transform="translate(0.417)"
        />
      </g>
    </svg>
  );
};

export default memo(EyeSlashSVGP);
