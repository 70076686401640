import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { ProjectsEndpoint } from 'src/services/endpoints';
import { APIErrorResponse, CreateAnnotationTaskMutationInput, CreateAnnotationTaskMutationResponse } from 'src/types';

export type LoginMutationOptions = UseMutationOptions<
  CreateAnnotationTaskMutationResponse,
  APIErrorResponse,
  CreateAnnotationTaskMutationInput
>;

export function useCreateAnnotationTaskMutation(projectId: string, options?: LoginMutationOptions) {
  return useMutation<CreateAnnotationTaskMutationResponse, APIErrorResponse, CreateAnnotationTaskMutationInput>(
    (values) => request.post(ProjectsEndpoint.annotationTasks(projectId), values).then((res) => res.data),
    options,
  );
}
