import { Rule } from 'antd/lib/form';

export const RULE_USERNAME: Rule[] = [
  { required: true, message: 'Username is required.' },
  { min: 5, message: 'Username must have at least 5 characters.' },
  { pattern: /^[A-Za-z0-9-_]*$/, message: 'Only characters</br> (a-z), (A-Z), (0-9), -, _ are available.' },
];
export const RULE_EMAIL: Rule[] = [
  { required: true, message: 'E-mail is required.' },
  { max: 64 },
  { min: 1 },
  { type: 'email', message: 'The email address you provided appears to be invalid.' },
];
export const RULE_PASSWORD: Rule[] = [
  { required: true, message: 'Password is required.' },
  { max: 16, message: 'Password is not valid' },
  { min: 8, message: 'Password is not valid' },
  { whitespace: true, message: 'Password is required.' },
];
export const RULE_NAME: Rule[] = [
  { required: true, message: 'First/Last name is required.' },
  { min: 1 },
  { whitespace: true, message: 'First/Last name is required.' },
];
export const RULE_TIME_ZONE: Rule[] = [{ required: true, message: 'Time Zone is required.' }, { min: 1 }, { max: 128 }];

export const RULE_CONFIRM_PASSWORD = (dependency: string): Rule[] => [
  {
    required: true,
    message: 'Retype password is required.',
  },
  { whitespace: true, message: 'Retype password is required.' },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue(dependency) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Password does not match!'));
    },
  }),
];
