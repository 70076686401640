import { Input, Progress } from 'antd';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import AppButton from 'src/components/Button';
import { useAlert } from 'src/hooks/useAlert';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { PolygonSVG } from 'src/icons';
import Page404 from 'src/pages/Error/404';
import ChangeCardInfo from 'src/pages/MyPage/OrderGPU/ChangeCardInfo';
import { useGetPlanQuery } from 'src/services/queries/useGetPlanQuery';
import { CardEntity, useGetCardInfoQuery, usePayExtraGPUMutation } from 'src/services/stripe';
import { PlanTypeEnum } from 'src/types';

import countries from '../Billing/countries.json';
import './index.less';

export interface OrderGPUProps {}

export default function OrderGPU(props: OrderGPUProps) {
  const [quantity, setQuantity] = useState(3);
  const [isReview, setIsReview] = useState(false);
  const [isChangeCard, setIsChangeCard] = useState(false);

  const { isLoading: getCardLoading, data: cardData, refetch } = useGetCardInfoQuery();

  const currentUser = useCurrentUser();

  const { data, isLoading } = useGetPlanQuery(Number(currentUser?.user.id));

  const gpuTimeValue =
    Number(data?.quota.GPU_TIME_USER) < Number(data?.quota.GPU_TIME_MAX)
      ? data?.quota.GPU_TIME_USER
      : data?.quota.GPU_TIME_MAX;

  const gpuTimeUser = ((gpuTimeValue || 0) / 60 / 60).toFixed(2);
  const gpuTimeMax = ((data?.quota.GPU_TIME_MAX || 0) / 60 / 60).toFixed(2);

  const gpuTimePercent = (+gpuTimeUser / +gpuTimeMax) * 100;

  const gpuPurchasedPercent = data?.quota.EXTRA_GPU_TIME
    ? (((data?.quota.EXTRA_GPU_TIME_MAX || 0) - (data?.quota.EXTRA_GPU_TIME || 0)) /
        (data?.quota.EXTRA_GPU_TIME_MAX || 0)) *
      100
    : 0;

  const gpuTimeAvailable = ((data?.quota.EXTRA_GPU_TIME || 0) / 60 / 60).toFixed(2);

  const { alert } = useAlert();

  const { mutate: orderGPU, isLoading: orderLoading } = usePayExtraGPUMutation({
    onSuccess: () => window.location.reload(),
    onError: () =>
      alert(
        <div className="text-16px leading-26px text-center px-40px pb-55px pt-4">
          <div className="text-center font-medium text-#FF0000">Something Went Wrong!</div>
          <div className="text-center mt-35px">
            Your payment information has not been processed.
            <br />
            Please make sure the information provided is correct.
            <br />
            <br />
            If you continue to see this message,
            <br /> please contact{' '}
            <a href={`mailto:${process.env.REACT_APP_STRATIO_AI_EMAIL}`} className="text-black">
              {process.env.REACT_APP_STRATIO_AI_EMAIL}
            </a>
          </div>
        </div>,
        {
          onOk: (close) => {
            close();
          },
          onCancel: (close) => {
            close();
          },
        },
      ),
  });

  function handleOrder() {
    orderGPU({
      hour: quantity,
    });
  }

  function handleRefetchCard(entity: CardEntity) {
    refetch();
    setIsChangeCard(false);
  }

  if (isLoading || getCardLoading || !cardData) {
    return null;
  }

  if (!data || data.plan_type === PlanTypeEnum.PLAN_FREE) {
    return <Page404 />;
  }

  return (
    <div style={{ width: 1092 }} className="mx-auto mt-84px pb-80px">
      <div>
        <div className="text-24px font-bold uppercase pl-60px">Personal GPU TIME</div>
        <div className="rounded-30px bg-white text-center pt-65px pb-84px px-60px mt-30px">
          <div>
            <div className="text-right text-14px">
              Used <strong>{gpuTimeUser} hours</strong> of {gpuTimeMax} hours
            </div>
            <div className="flex items-center mt-2px">
              <div className="w-230px pr-26px text-left">GPU Time</div>
              <div className="flex-grow flex">
                <Progress
                  percent={gpuTimePercent}
                  showInfo={false}
                  strokeLinecap="square"
                  strokeColor="#00BEEB"
                  trailColor="#FFFFFF"
                  className="progress-personal-space"
                />
              </div>
            </div>
          </div>

          <div className="mt-45px">
            <div className="text-right text-14px">
              Have <strong>{gpuTimeAvailable} hours</strong> available
            </div>
            <div className="flex items-center  mt-2px">
              <div className="w-230px pr-26px text-left">Extra GPU Time Purchased</div>
              <div className="flex-grow flex">
                <Progress
                  percent={gpuPurchasedPercent}
                  showInfo={false}
                  strokeLinecap="square"
                  strokeColor="#00BEEB"
                  trailColor="#FFFFFF"
                  className="progress-personal-space"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isChangeCard ? (
        <ChangeCardInfo
          plan={data}
          quantity={quantity}
          setQuantity={setQuantity}
          onCancel={() => setIsChangeCard(false)}
          initialValue={cardData}
          onSaveCard={handleRefetchCard}
        />
      ) : isReview ? (
        <div className="mt-65px select-none">
          <div className="text-24px font-bold uppercase pl-60px">REVIEW ORDER</div>
          <div className="rounded-30px bg-white text-center px-145px pt-55px pb-20px mt-30px">
            <div>
              <div>
                <div className="pl-30px text-left">
                  <div className="text-20px font-medium leading-26px">Payment Method</div>
                  <div className="text-14px font-light mt-2">
                    {cardData?.brand === 'visa' ? cardData.brand.toUpperCase() : capitalize(cardData?.brand)} ending in{' '}
                    {cardData?.last4} exp:{cardData?.exp_month}/{cardData?.exp_year}
                  </div>
                  <div className="flex items-center mt-40px">
                    <div className="text18px leading-24px font-medium">Extra GPU Time</div>
                    <div className="text18px leading-24px ml-40px" style={{ color: '#434141' }}>
                      ${data.prices.extra_gpu}/hour
                    </div>
                    <div className="text18px leading-24px ml-auto" style={{ color: '#434141' }}>
                      {quantity}hour(s)
                    </div>
                  </div>
                </div>

                <div className="h-px bg-#DDDDDD mt-14px" />

                <div className="text-16px leading-20px mt-12px">
                  <div className="flex justify-end">
                    <table className="flex-grow">
                      <tr>
                        <td className="text-left text-transparent">&</td>
                        <td className="text-left"> </td>
                      </tr>
                      <tr>
                        <td className="text-left border-b border-#DDDDDD"></td>
                        <td className="text-left border-b border-#DDDDDD text-transparent">&</td>
                      </tr>
                      <tr>
                        <td className="text-left text-transparent text-24px pt-3">&</td>
                        <td className="text-left text-22px font-normal"> </td>
                      </tr>
                    </table>
                    <table className="flex-shrink-0">
                      <tr>
                        <td className="text-left w-183px uppercase">Bag Subtotal</td>
                        <td className="text-left">${(quantity * +data.prices.extra_gpu).toFixed(2)}</td>
                      </tr>
                      <tr className="pb-3">
                        <td className="text-left border-b border-#DDDDDD">Tax</td>
                        <td className="text-#DDDDDD text-left border-b border-#DDDDDD">N/A</td>
                      </tr>
                      <tr>
                        <td className="text-left w-183px text-24px font-medium pt-3">Order Total</td>
                        <td className="text-left text-22px font-normal pt-3">
                          ${(quantity * +data.prices.extra_gpu).toFixed(2)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="text-24px font-medium leading-20px flex justify-end space-x-20px mt-44px">
                  <AppButton
                    type="_FFFFFF"
                    className="h-30px w-96px text-12px font-normal rounded-full"
                    onClick={() => setIsReview(false)}
                  >
                    BACK
                  </AppButton>
                  <AppButton
                    type="_00BEEB"
                    className="h-30px w-96px text-12px font-normal rounded-full"
                    onClick={handleOrder}
                    loading={orderLoading}
                    disabled={orderLoading}
                  >
                    ORDER
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-65px select-none">
          <div className="text-24px font-bold uppercase pl-60px">YOUR ORDER</div>
          <div className="rounded-30px bg-white text-center p-60px mt-30px">
            <div className="flex">
              <div>
                <div className="w-74px h-74px p-4 rounded-full border border-#00BEEB">
                  <img src="/v2/images/cpu.png" alt="cpu" className="w-full h-full" />
                </div>
              </div>
              <div className="flex-grow pt-3">
                <div className="flex items-center pl-30px ml-6px pb-70px">
                  <div className="text-left">
                    <div className="text-24px font-medium leading-31px">Extra GPU Time</div>
                    <div className="text-14px font-light">
                      <span className="text-#FF0000">*</span> Extra GPU Time purchased will not be refunded.
                    </div>
                  </div>
                  <div className="text-24px font-medium ml-60px">${data.prices.extra_gpu}/hour</div>
                  <div className="text-20px font-medium flex items-center ml-auto space-x-24px">
                    <Input
                      className="gpu-quantity-input"
                      suffix={
                        <div className="flex-col flex">
                          <PolygonSVG
                            fill="currentColor"
                            className="transform -rotate-90 text-#E4E4E4 cursor-pointer hover:text-#00BEEB"
                            onClick={() => setQuantity(quantity + 1 > 999 ? 999 : quantity + 1)}
                          />
                          <PolygonSVG
                            fill="currentColor"
                            className="transform rotate-90 text-#E4E4E4 cursor-pointer hover:text-#00BEEB"
                            onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
                          />
                        </div>
                      }
                      value={quantity}
                      onChange={(e) =>
                        setQuantity(
                          (+e.target.value.replace(/[^0-9.]/g, '') || 1) > 999
                            ? 999
                            : +e.target.value.replace(/[^0-9.]/g, '') || 1,
                        )
                      }
                    />

                    <span>hour(s)</span>
                  </div>
                </div>

                <div className="mr-74px h-px ml-6px bg-#DDDDDD" />

                <div className="pr-74px mt-20px">
                  <div className="flex justify-end items-center">
                    <div className="text-24px font-medium leading-31px">Bag Subtotal</div>
                    <div className="text-22px ml-45px">${(quantity * +data.prices.extra_gpu).toFixed(2)}</div>
                  </div>
                </div>

                <div className="mt-51px flex justify-between pl-30px ml-6px pr-74px">
                  <div className="text-22px font-medium leading-29px">Payment Information</div>
                  <div
                    className="text-20px leading-29px text-#00BEEB underline cursor-pointer"
                    onClick={() => setIsChangeCard(true)}
                  >
                    Change
                  </div>
                </div>

                <div className="text-16px leading-20px mt-30px text-left pl-30px ml-6px">
                  {cardData?.hasCard && (
                    <>
                      <div>
                        {cardData?.brand === 'visa' ? cardData.brand.toUpperCase() : capitalize(cardData?.brand)} ending
                        in {cardData.last4}, exp: {cardData.exp_month}/{cardData.exp_year}
                      </div>
                      <div>{cardData?.first_name + ' ' + cardData?.last_name}</div>
                      <div>{cardData['street']}</div>
                      <div>{cardData['state'] + ' ,' + cardData['city'] + ' ' + cardData['zip']}</div>
                      <div>{countries[cardData.country!]}</div>
                    </>
                  )}
                </div>

                <div className="text-16px leading-20px mt-30px pr-74px">
                  <div className="flex justify-end">
                    <table className="flex-grow">
                      <tr>
                        <td className="text-left text-transparent">&</td>
                        <td className="text-left"> </td>
                      </tr>
                      <tr>
                        <td className="text-left border-b border-#DDDDDD"></td>
                        <td className="text-left border-b border-#DDDDDD text-transparent">&</td>
                      </tr>
                      <tr>
                        <td className="text-left text-transparent text-24px pt-3">&</td>
                        <td className="text-left text-22px font-normal"> </td>
                      </tr>
                    </table>
                    <table className="flex-shrink-0">
                      <tr>
                        <td className="text-left w-183px uppercase">Bag Subtotal</td>
                        <td className="text-left">${(quantity * +data.prices.extra_gpu).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td className="text-left border-b border-#DDDDDD uppercase">Estimated Tax</td>
                        <td className="text-#DDDDDD text-left border-b border-#DDDDDD ">N/A</td>
                      </tr>
                      <tr>
                        <td className="text-left w-183px text-24px font-medium pt-3">Order Total</td>
                        <td className="text-left text-22px font-normal pt-3">
                          ${(quantity * +data.prices.extra_gpu).toFixed(2)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="text-24px font-medium leading-20px mt-70px pr-74px flex justify-end">
                  <AppButton
                    type="_00BEEB"
                    className="h-30px w-125px text-12px font-normal rounded-full"
                    onClick={() => setIsReview(true)}
                    disabled={!cardData?.hasCard}
                  >
                    REVIEW ORDER
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
