import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-use';
import { CloseOutLinedSVG } from 'src/assets/icons';
import { Text } from 'src/components';
import AppButton from 'src/components/Button';
import { AppRoutes } from 'src/helpers';
import { useClickOutside } from 'src/hooks';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { HumbugerMenuSVG } from 'src/icons';

import './index.less';

export interface HeaderProps {}

export default function Header(props: HeaderProps) {
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const user = useCurrentUser();

  return (
    <section>
      <header className="bg-white relative top-0">
        <div className="max-w-1920px mx-auto h-57px px-30px flex items-center sm:h-84px sm:px-84px md:h-132px md:px-130px lg:h-132px">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <Link to={AppRoutes.home}>
                <img
                  src="/v2/images/camera-ai-logo.png"
                  alt="camera-ai-logo"
                  className="w-71px mr-13px sm:w-122px sm:mr-22px lg:w-162px lg:mr-26px"
                />
              </Link>
              <Text className="text7px text-#393939 sm:text-13px lg:text-18px">by</Text>
              <a href={process.env.REACT_APP_STRATIO_HOMEPAGE} target="_blank">
                <img
                  src="/v2/images/stlabs-logo.png"
                  alt="STLabs Logo"
                  className="w-47px ml-6px sm:w-80px sm:ml-8px lg:w-110px lg:ml-10px"
                />
              </a>
            </div>
            <div className="flex items-center">
              <ul className="hidden md:flex items-center">
                <li className="text15px leading-18px text-#989898 mr-40px lg:text-20px lg:mr-50px">
                  <NavLink
                    to={AppRoutes.discover.product}
                    className={classNames(
                      'inline-block pl-10px border-l-2',
                      location.pathname === AppRoutes.discover.product ? 'border-#00BEEB font-medium' : 'border-white',
                    )}
                  >
                    Product
                  </NavLink>
                </li>
                <li className="text15px leading-18px text-#989898 mr-40px lg:text-20px lg:mr-50px">
                  <NavLink
                    to={AppRoutes.discover.plan}
                    className={classNames(
                      'inline-block pl-10px border-l-2',
                      location.pathname === AppRoutes.discover.plan ? 'border-#00BEEB' : 'border-white',
                    )}
                  >
                    Plans
                  </NavLink>
                </li>
                <li className="text15px leading-18px text-#989898 mr-40px lg:text-20px lg:mr-50px">
                  <NavLink
                    to={AppRoutes.discover.support}
                    className={classNames(
                      'inline-block pl-10px border-l-2',
                      location.pathname === AppRoutes.discover.support ? 'border-#00BEEB' : 'border-white',
                    )}
                  >
                    Support
                  </NavLink>
                </li>
              </ul>
              <Link to={AppRoutes.projectList}>
                <AppButton
                  type="_00BEEB"
                  className="rounded-full w-75px h-21px text-8px leading-none sm:w-125px sm:h-36px sm:text-14px lg:w-162px lg:h-45px lg:text-19px px-0"
                >
                  {user ? 'MY PROJECTS' : 'SIGN IN'}
                </AppButton>
              </Link>
              <HumbugerMenuSVG
                className="w-13px text-#C4C4C4 ml-14px sm:w-20px sm:ml-35px md:hidden"
                onClick={() => setOpen(!open)}
              />
              {open && <MenuBar setOpen={setOpen} isOpen={open} />}
            </div>
          </div>
        </div>
      </header>
    </section>
  );
}

interface MenuBarProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
}

function MenuBar({ setOpen, isOpen }: MenuBarProps) {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => isOpen && setOpen(false));

  return (
    <motion.div ref={ref} className="absolute top-0 right-0 overlay-menu-bar z-10 pt-60px pl-44px md:hidden">
      <CloseOutLinedSVG
        className="w-10px h-10px absolute top-26px right-30px cursor-pointer"
        onClick={() => setOpen(false)}
      />
      <ul>
        <li className="text-16px leading-20px mb-24px">
          <NavLink
            to={AppRoutes.discover.home}
            className={classNames(
              'inline-block border-b',
              location.pathname === AppRoutes.discover.home ? 'border-#00BEEB' : 'border-transparent',
            )}
          >
            Home
          </NavLink>
        </li>
        <li className="text-16px leading-20px mb-24px">
          <NavLink
            to={AppRoutes.discover.product}
            className={classNames(
              'inline-block border-b',
              location.pathname === AppRoutes.discover.product ? 'border-#00BEEB' : 'border-transparent',
            )}
          >
            Product
          </NavLink>
        </li>
        <li className="text-16px leading-20px mb-24px">
          <NavLink
            to={AppRoutes.discover.plan}
            className={classNames(
              'inline-block border-b',
              location.pathname === AppRoutes.discover.plan ? 'border-#00BEEB' : 'border-transparent',
            )}
          >
            Plans
          </NavLink>
        </li>
        <li className="text-16px leading-20px">
          <NavLink
            to={AppRoutes.discover.support}
            className={classNames(
              'inline-block border-b',
              location.pathname === AppRoutes.discover.support ? 'border-#00BEEB' : 'border-transparent',
            )}
          >
            Support
          </NavLink>
        </li>
      </ul>
    </motion.div>
  );
}
