import { useMutation, UseMutationOptions } from 'react-query';
import { ImageEndpoint } from '../endpoints';
import { APIErrorResponse, UploadImageMutationInput, UploadImageMutationResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { ImageEntity } from 'src/types/image';
import { AxiosRequestConfig } from 'axios';

export type UploadImageMutationOptions = UseMutationOptions<
  UploadImageMutationResponse,
  APIErrorResponse,
  UploadImageMutationInput
>;

export default function useUploadImageMutation(
  options?: UploadImageMutationOptions,
  onProgress?: (percent: number) => void,
) {
  return useMutation<UploadImageMutationResponse, APIErrorResponse, UploadImageMutationInput>(
    (values) =>
      request
        .post(ImageEndpoint.upload, values, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress && onProgress(percentCompleted);
          },
        })
        .then((res) => res.data),
    options,
  );
}

export function uploadImage(file: FormData, onProgress?: (percent: number) => void, config?: AxiosRequestConfig) {
  return request.post<ImageEntity>(ImageEndpoint.upload, file, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress && onProgress(percentCompleted);
    },
    ...config,
  });
}
