import { useQuery, UseQueryOptions } from 'react-query';
import { APIErrorResponse, GetUserQuotaResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { ProjectsEndpoint } from '../endpoints';
import { useParams } from 'react-router-dom';

export type ProjectQuotaQueryResponse = GetUserQuotaResponse;

export type ProjectQuotaQueryOptions = UseQueryOptions<
  ProjectQuotaQueryFunc,
  APIErrorResponse,
  ProjectQuotaQueryResponse
>;
export type ProjectQuotaQueryFunc = () => ProjectQuotaQueryResponse | null;

export function useProjectQuotaQuery(id: string, options?: ProjectQuotaQueryOptions) {
  return useQuery<ProjectQuotaQueryFunc, APIErrorResponse, ProjectQuotaQueryResponse>(
    ['project_quota', id],
    () => request.get(ProjectsEndpoint.projectQuota(id)).then((res) => res.data),
    options,
  );
}

export function useCurrentProjectQuotaQuery(options?: ProjectQuotaQueryOptions) {
  const { projectId } = useParams<{ projectId: string }>();

  return useQuery<ProjectQuotaQueryFunc, APIErrorResponse, ProjectQuotaQueryResponse>(
    ['current_project_quota'],
    () => request.get(ProjectsEndpoint.projectQuota(projectId)).then((res) => res.data),
    options,
  );
}
