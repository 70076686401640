import { useMutation, UseMutationOptions } from 'react-query';
import { PathEndpoint } from '../endpoints';
import { APIErrorResponse, CreatePathInput, CreatePathResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { PathEntity } from 'src/types/path';
import { AxiosRequestConfig } from 'axios';

export type CreatePathMutationOptions = UseMutationOptions<CreatePathResponse, APIErrorResponse, CreatePathInput>;

export default function useCreatePathMutation(options?: CreatePathMutationOptions) {
  return useMutation<CreatePathResponse, APIErrorResponse, CreatePathInput>(
    (values) =>
      request
        .post(
          PathEndpoint.createPath,
          { name: values.name },
          {
            params: {
              parent: values.parent,
            },
          },
        )
        .then((res) => res.data),
    options,
  );
}

export async function createPath(values: CreatePathInput, config?: AxiosRequestConfig) {
  return await request.post<PathEntity>(
    PathEndpoint.createPath,
    { name: values.name },
    {
      params: {
        parent: values.parent,
      },
      ...config,
    },
  );
}
