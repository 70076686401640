import { InputHTMLAttributes } from 'react';
import { SearchSVG } from 'src/assets/icons';

export interface SimpleSearchBarProps extends InputHTMLAttributes<HTMLInputElement> {}

function SimpleSearchBar(props: SimpleSearchBarProps) {
  return (
    <div className="flex h-32px w-230px border border-#AAAAAA px-10px">
      <input placeholder="Search" className="text-12px flex-grow focus:outline-none" {...props} />
      <SearchSVG className="w-4 text-#AAAAAA ml-10px" />
    </div>
  );
}

export default SimpleSearchBar;
