import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useUILogin } from '../context/modal';
import { RULE_CONFIRM_PASSWORD, RULE_PASSWORD } from '../helper/validate';
import { useConfirm, useNavigation } from 'src/hooks';
import useResetPasswordConfirmMutation from 'src/services/mutations/useResetPasswordConfirmMutation';
import { ResetPasswordConfirmInput } from 'src/types';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import useQueryString from 'src/hooks/useQuerystring';
import { EyeSlashSVGP, EyeSVGP, LockSVGP } from 'src/components/icons';
import '../styles/modal.less';

const ResetPassword: React.FC = () => {
  const query = useQueryString<ResetPasswordConfirmInput>();
  const [form] = Form.useForm<ResetPasswordConfirmInput>();
  const { closeModal } = useUILogin();
  const navigation = useNavigation();
  const { confirm } = useConfirm();
  const [fields, setFields] = useState<ResetPasswordConfirmInput>({
    password1: '',
    password2: '',
    token: '',
  });

  const handleFinishFailed = ({ errorFields }: ValidateErrorEntity<ResetPasswordConfirmInput>) => {
    confirm(<div className="text-center">{errorFields?.[0]?.errors?.[0]}</div>);
  };

  const { mutate } = useResetPasswordConfirmMutation({
    onError: ({ response }) => {
      confirm(<div className="text-center text-#00BEEB">This password reset request has been expired.</div>);
      navigation.reset();
      closeModal();
    },
    onSuccess: () => {
      confirm(<div className="text-center text-#00BEEB">Success</div>);
      navigation.reset();
      closeModal();
    },
  });

  const handleFinish = (values: ResetPasswordConfirmInput) => {
    mutate({ ...values, token: query.token });
    closeModal();
  };

  const handleClose = () => {
    closeModal();
    navigation.reset();
  };

  return (
    <div className="modal-register">
      <div className="text-36px mb-35px text-center text-#707070">Reset Your Password</div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        requiredMark={false}
        className="form-custom"
        validateTrigger={false}
        onFinishFailed={handleFinishFailed}
        onValuesChange={(_, allValues) => {
          setFields(allValues);
        }}
      >
        <div className="text-20px text-#00BEEB">Password</div>
        <Form.Item name="password1" rules={RULE_PASSWORD} noStyle>
          <Input.Password
            prefix={<LockSVGP />}
            placeholder="Password"
            bordered={false}
            className={`mb-14px ${fields?.password1?.length > 0 && 'selected'}`}
            iconRender={(visible) => (visible ? <EyeSVGP /> : <EyeSlashSVGP />)}
          />
        </Form.Item>

        <div className="text-20px text-#00BEEB">Retype Password</div>
        <Form.Item name="password2" dependencies={['password1']} rules={RULE_CONFIRM_PASSWORD('password1')} noStyle>
          <Input.Password
            prefix={<LockSVGP />}
            placeholder="Retype Password"
            bordered={false}
            className={`mb-14px ${fields?.password2?.length > 0 && 'selected'}`}
            iconRender={(visible) => (visible ? <EyeSVGP /> : <EyeSlashSVGP />)}
          />
        </Form.Item>
        <Form.Item className="text-right">
          <div className="space-x-2">
            <Button htmlType="button" type="text" className="text-black" onClick={handleClose}>
              Return to Sign In
            </Button>
            <Button htmlType="submit" shape="round" type="primary">
              Confirm
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPassword;
