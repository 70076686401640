import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { AnnotationEndpoint } from 'src/services/endpoints';
import { APIErrorResponse } from 'src/types';
import { stringify } from 'qs';

export type DeleteAnnotationsInput = {
  ids: number[];
};

export type DeleteAnnotationsMutationOptions = UseMutationOptions<unknown, APIErrorResponse, DeleteAnnotationsInput>;

export function useDeleteAnnotationsMutation(options?: DeleteAnnotationsMutationOptions) {
  return useMutation<unknown, APIErrorResponse, DeleteAnnotationsInput>(
    (params) =>
      request
        .delete(AnnotationEndpoint.annotations, {
          params,
          paramsSerializer: (params) => {
            return stringify(params, { arrayFormat: 'comma' });
          },
        })
        .then((res) => res.data),
    options,
  );
}
