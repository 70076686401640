import { useMutation, UseMutationOptions } from 'react-query';
import { PathEndpoint } from '../endpoints';
import { APIErrorResponse, MovePathsInput, MovePathsResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { stringify } from 'qs';

export type MovePathsMutationOptions = UseMutationOptions<MovePathsResponse, APIErrorResponse, MovePathsInput>;

export default function useMovePathsMutation(options?: MovePathsMutationOptions) {
  return useMutation<MovePathsResponse, APIErrorResponse, MovePathsInput>(
    (params) =>
      request
        .put(PathEndpoint.movePaths, undefined, {
          params,
          paramsSerializer: (params) => {
            return stringify(params, { arrayFormat: 'comma' });
          },
        })
        .then((res) => res.data),
    options,
  );
}
