export const ACTION_LOGIN = {
  VERIFY: 'verify',
  RESET_PASSWORD: 'reset-password',
  RESET_PASSWORD_CONFIRM: 'reset-password-confirm',
};

export interface QUERY_LOGIN {
  action: string;
  email: string;
  password1: string;
  password2: string;
  token: string;
}
