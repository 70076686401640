import { atom, useResetRecoilState } from 'recoil';
import { CreateAnnotationTaskMutationInput, ProjectsEntity } from 'src/types';
import { PathEntity } from 'src/types/path';

export const createTaskInput = atom<CreateAnnotationTaskMutationInput | undefined>({
  key: 'createTrainingInput',
  default: undefined,
});

export interface FolderSelect {
  labels?: string[];
  project: ProjectsEntity;
  selected?: boolean;
  count?: number;
  path?: PathEntity;
  images?: number[];
  dirs?: number[];
}

export const selectedPathForTraining = atom<FolderSelect[]>({
  key: 'selectedPathForTraining',
  default: [],
});

export const correctTrainingRoute = atom<boolean>({
  default: false,
  key: 'correctTrainingRoute',
});

export const initSelectedTrainingData = atom<boolean>({
  key: 'initSelectedTrainingData',
  default: false,
});

export function useResetTraining() {
  const resetCreateTaskInput = useResetRecoilState(createTaskInput);
  const resetSelectedPathForTraining = useResetRecoilState(selectedPathForTraining);
  const resetInit = useResetRecoilState(initSelectedTrainingData);
  const resetRoute = useResetRecoilState(correctTrainingRoute);

  return () => {
    resetCreateTaskInput();
    resetSelectedPathForTraining();
    resetInit();
    resetRoute();
  };
}

export const updatingState = atom<boolean>({
  key: 'updatingTrainingState',
  default: false,
});
