import { useQuery, UseQueryOptions } from 'react-query';
import { APIErrorResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { TestsEndpoint } from '../endpoints';
import { TestDetailInput, TestDetailResponse } from 'src/types/tests';

export type TestsQueryOptions = UseQueryOptions<TestDetailQueryFunc, APIErrorResponse, TestDetailResponse>;
export type TestDetailQueryFunc = () => TestDetailResponse;

export function useTestQuery(params: TestDetailInput, options?: TestsQueryOptions) {
  return useQuery<TestDetailQueryFunc, APIErrorResponse, TestDetailResponse>(
    ['test_detail', params],
    () => request.get(TestsEndpoint.detail(params?.testId)).then((res) => res.data),
    options,
  );
}
