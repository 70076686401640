export const AuthEndpoint = {
  login: '/api/auth/login/',
  logout: '/api/auth/logout/',
  register: '/api/auth/register/',
  activateRegister: '/api/auth/verify/',
  resetPassword: '/api/auth/password/reset/',
  resetPasswordConfirm: '/api/auth/password/reset/confirm/',
  retrieveMyInformation: '/api/auth/me/',
};

export const ProjectsEndpoint = {
  projects: (sort: string, search: string, label?: string) =>
    `/api/projects/?sort=${sort}&search=${search}${label ? `&label=${label}` : ''}`,
  projects_without_query_params: '/api/projects/',
  createProject: () => `/api/projects/`,
  projectDetail: (id: string) => '/api/projects/' + id + '/',
  updateProject: (id: number) => `/api/projects/${id}/`,
  deleteProject: (id: string) => `/api/projects/${id}/`,
  public: (id: number) => `/api/projects/${id}/public/`,
  favorite: (id: number) => `/api/projects/${id}/favorite/`,
  recentActivities: (id: string) => `/api/projects/${id}/recent_activities/`,
  accessGrants: (id: string) => `/api/projects/${id}/access_grants/`,
  updateAccessGrants: (id: string) => `/api/projects/${id}/access_grants/`,
  searchUsername: (username: string) => `/api/users/?username=${username}`,
  usage: (id: string, type: string) => `/api/projects/${id}/usage/?type=${type}`,
  annotationTasks: (projectId: number | string) => `/api/projects/${projectId}/annotationtasks/`,
  annotationTask: (taskId: string | number) => `/api/annotationtasks/${taskId}/`,
  projectsUsageRead: '/api/projects/usage/',
  projectQuota: (id: string | number) => '/api/projects/' + id + '/quota',
};

export const ImageEndpoint = {
  images: '/api/images/',
  image_detail: (id: number | string) => `/api/images/${id}/`,
  upload: '/api/images/upload/',
  moveImages: '/api/images/move/',
  edit_image_name: (id: string | number) => `/api/images/${id}/rename/`,
};
export const AnnotationEndpoint = {
  annotations: '/api/annotations/',
  annotation_detail: (id: number | string) => `/api/annotations/${id}/`,
  apply_annotations: (id: number | string) => `/api/annotationtasks/${id}/apply_annotation/`,
};

export const AnnotationJobEnpoint = {
  annotationJob: (id: number) => '/api/annotationjobs/' + id,
  annotationJobAuto: (id: string) => `/api/annotationjobs/${id}/auto_annotation/`,
  annotationJobOpenCvat: (id: string) => `/api/annotationjobs/${id}/open_cvat/`,
  annotationUpdateAnnotator: (id: string) => `/api/annotationjobs/${id}/annotator/`,
  annotationUpdateReviewer: (id: string) => `/api/annotationjobs/${id}/reviewer/`,
};

export const TestsEndpoint = {
  tests: (projectId: number, search?: string) => `/api/projects/${projectId}/aitests/?search=${search}`,
  add: `/api/aitests/`,
  actions: (projectId: number) => `/api/aitests/${projectId}/`,
  detail: (testId: number) => `/api/aitests/${testId}/`,
  start: `/api/aitests/:id/start_testing/`,
  startParams: (id: string) => `/api/aitests/${id}/start_testing/`,
};

export const UserEndpoint = {
  updateUserProfile: (id: string | number) => `/api/users/${id}/`,
  userDetail: (id: string | number) => `/api/users/${id}/`,
  userStatusDetailForAdminPage: (id: string) => `/api/users/${id}/status/`,
  setOrUnsetUserToAdminForAdminPage: (id: string, set_admin: boolean) =>
    `/api/users/${id}/admin/?set_admin=${set_admin}`,
  blockOrUnblockUserForAdminPage: (id: string, block: boolean) => `/api/users/${id}/block/?block=${block}`,
  deleteUserForAdminPage: (id: String) => `/api/users/${id}/`,
  getUserDiskUsageType: (id: string, type: string) => `/api/users/${id}/usage/disk/?type=${type}`,
  usersUsageDiskGetUsageDiskTypeDetail: (id: string, type: string) => `/api/users/${id}/usage/disk/type/?type=${type}`,
  usersUsageGpuRead: (id: string, type: string, page: number) =>
    `/api/users/${id}/usage/gpu/?type=${type}&page=${page}`,
  usersUsageGpuProjectReact: (type: string, offset: number, id: string) =>
    `/api/users/${id}/usage/gpu/project/?type=${type}&offset=${offset}`,
  usersUsageDiskGetUsageDiskProjectDetail: (id: string, project_id: string) =>
    `/api/users/${id}/usage/disk/project/?project_id=${project_id}`,
  usersBlockUpdate: (block: boolean, id: string) => `/api/users/${id}/block/?block=${block}`,
  deleteAccount: (id: Number) => `/api/users/${id}/delete/`,
  userQuota: (id?: number | string) => `/api/users/${id}/quota/`,
  billingHistory: (id: number | string) => `/api/users/${id}/billing_history/`,
};

export const AiModelsEndpoint = {
  list: `/api/aimodels`,
  getAiModels: (id: string | number) => `/api/projects/${id}/aimodels/`,
  createAiModels: `/api/aimodels/`,
  AiModelItem: (id: string | number) => `/api/aimodels/${id}/`,
  tests: (projectId: number) => `/api/projects/${projectId}/aimodels/`,
  cancel_training: (id: string | number) => `/api/aimodels/${id}/stop/`,
  clone: (id: number) => `/api/aimodels/${id}/clone/`,
  estimatedTrainingTime: (id: string | number) => `/api/aimodels/${id}/estimate_time/`,
  resumeTraining: (id: string | number) => `/api/aimodels/${id}/resume_training/`,
};

export const ClassEndpoint = {
  createClass: (id: string | number) => `/api/aimodels/${id}/mlclass/`,
  ClassItem: (id: string | number) => `/api/mlclasses/${id}/`,
  selectedData: (id: string | number) => `/api/mlclasses/${id}/data/`,
  labels: (id: string | number) => `/api/mlclasses/${id}/labels/`,
};

export const SettingEndpoint = {
  startSetting: (id: string | number) => `/api/aimodels/${id}/start_training/`,
  updateSetting: (id: string | number) => `/api/aimodels/${id}/option_save/`,
  learningCurve: (id: string | number) => `/api/aimodels/${id}/accuracy_graph/`,
  learningCurveLoss: (id: string | number) => `/api/aimodels/${id}/loss_graph/`,
  learningCurveCancel: (id: string | number) => `/api/aimodels/${id}/cancel/`,
};

export const TrainingResultEndpoint = {
  accuracy_graph: (id: string | number) => `/api/aimodels/${id}/accuracy_graph/`,
  loss_graph: (id: string | number) => `/api/aimodels/${id}/loss_graph/`,
  images_view: (id: string | number) => `/api/aimodels/${id}/detail_view/`,
};

export const ResultEndpoint = {
  getTrainingResult: (id: string | number) => `/api/aimodels/${id}/mlresult/`,
  getImageDetail: (id: string | number) => `/api/aimodels/${id}/detail_view/`,
  getImage: (id: string | number) => `/api/images/${id}/`,
  getImageData: (id: string | number) => `/api/images/${id}/data/`,
  getAnnotationData: (id: string | number) => `/api/annotations/${id}/data/`,
  testResult: (testId: number) => `/api/aitests/${testId}/mlresult/`,
};

export const ResultDetailEndPoint = {
  test: (testId: number) => `/api/aitests/${testId}/detail_view/`,
};
export const GlobalSearchEndpoint = '/api/search/';

export const PathEndpoint = {
  pathInfo: (id: string | number) => '/api/paths/' + id + '/',
  deletePaths: '/api/paths/',
  imageListInPath: (id: string | number) => `/api/paths/${id}/images/`,
  createPath: '/api/paths/',
  movePaths: '/api/paths/move/',
  images_count: (id: string | number) => `/api/paths/${id}/images_count/`,
  imageInUseDetail: (id: string | number) => `/api/images/${id}/in_use_detail/`,
};

export const PlanEndpoint = {
  getPlan: (id: string | number) => '/api/users/' + id + '/plan/',
};

export const ServerEndpoint = {
  getDataServerGpuUsage: (type: string, page: any) => `/api/server/usage/gpu/?type=${type}&page=${page}`,
  getAllUser: `/api/server/users/`,
  getGetSystemDiskUsage: (type: string) => `/api/server/usage/disk/?type=${type}`,
  getGetDetailDiskUsageOfUser: (user_id: string) => `/api/server/usage/disk/user/?user_id=${user_id}`,
  getGetDetailDiskUsageOfTypes: (type: string) => `/api/server/usage/disk/type/?type=${type}`,
  getGetDetailDiskUsageOfProjects: (project_id: number) => `/api/server/usage/disk/project/?project_id=${project_id}`,
  getSignUpForMonth: '/api/server/signup/',
  getDetailDiskUsageOfUsers: (page: number, type: string) => `/api/server/usage/users/?page=${page}&type=${type}`,
  serverSignupRead: '/api/server/signup/',
  serverUsageDiskRead: (type: string) => `/api/server/usage/disk/?type=${type}`,
  serverUsageDiskGetUsageDiskUserDetail: (user_id?: number) => `/api/server/usage/disk/user/?user_id=${user_id}`,
  serverUsageDiskGetUsageDiskTypeDetail: (type?: string) => `/api/server/usage/disk/type/?type=${type}`,
  serverUsageDiskGetUsageDiskProjectDetail: (project_id?: number) =>
    `/api/server/usage/disk/project/?project_id=${project_id}`,
  serverUsageGpuRead: (type?: string, page?: number) => `/api/server/usage/gpu/?type=${type}&page=${page}`,
};

export { proxy } from '../../package.json';
