import { ComponentType, Fragment } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { AppRoutes } from 'src/helpers';
import { Helmet } from 'react-helmet';
import React from 'react';
import useAuthenticated from 'src/hooks/useAuthenticated';
import { useQuerystring } from 'src/hooks';

export interface PrivateRouteProps extends RouteProps {
  layout?: (props: any) => any;
  isPrivate?: boolean;
  isAuthRoute?: boolean;
  title?: string;
  component: ComponentType;
}

export const RouteLayout = ({
  layout: Layout,
  component: Component,
  isPrivate = false,
  title,
  path,
  isAuthRoute = false,
  ...props
}: PrivateRouteProps) => {
  const isAuthenticated = useAuthenticated();

  const { pathname } = useLocation();

  const { redirect } = useQuerystring<{ redirect: string }>();

  if (!isAuthenticated && isPrivate)
    return <Redirect key={path as string} exact={true} to={AppRoutes.login + '?redirect=' + pathname} />;
  if (isAuthenticated && isAuthRoute)
    return <Redirect key={path?.toString()} exact={true} to={redirect || AppRoutes.projectList} />;

  return (
    <Route
      {...props}
      path={path}
      render={(ownProps) => {
        return (
          <Fragment>
            <Helmet>
              <title>{title ? title + ' - ' : ''}Stratio AI</title>
            </Helmet>
            {Layout ? (
              <Layout>
                <Component />
              </Layout>
            ) : (
              <Component />
            )}
          </Fragment>
        );
      }}
    />
  );
};

export default RouteLayout;
