import { Form, Input, Select, Spin } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import React, { Dispatch, SetStateAction, useEffect, useReducer, useState } from 'react';
import AppButton from 'src/components/Button';
import { useAlert } from 'src/hooks/useAlert';
import { PolygonSVG } from 'src/icons';
import { PlanEntity } from 'src/services/queries/useGetPlanQuery';
import {
  CardEntity,
  useInitStripeCardUIMutation,
  useRetrieveCardMutation,
  useUpdateBillingDetailsMutation,
  useUpdateStripeCardMutation,
} from 'src/services/stripe';

import './change-card.less';
import countries from '../Billing/countries.json';
import { CardValidation } from 'src/pages/MyPage/Billing/AddCardForm';

export interface ChangeCardInfoProps {
  plan: PlanEntity;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  initialValue: CardEntity;
  onSaveCard: (card: CardEntity) => void;
  onCancel: () => void;
}

export default function ChangeCardInfo({
  plan,
  quantity,
  setQuantity,
  initialValue,
  onSaveCard,
  onCancel,
}: ChangeCardInfoProps) {
  const [card, setCard] = useState<Partial<CardEntity>>();

  const [{ isCardNumberEmpty, isCardCVCEmpty, isCardExpiryEmpty }, setCardValidation] = useReducer(
    (p: CardValidation, v: Partial<CardValidation>) => ({ ...p, ...v }),
    {
      isCardNumberEmpty: true,
      isCardCVCEmpty: true,
      isCardExpiryEmpty: true,
    },
  );

  const [form] = Form.useForm();

  const { mutate: initCard } = useInitStripeCardUIMutation({
    onSuccess: (res) => {
      res.cardNumber.on('change', (e) => {
        setCardValidation({ isCardNumberEmpty: e.empty });
      });
      res.cardCvc.on('change', (e) => {
        setCardValidation({ isCardCVCEmpty: e.empty });
      });
      res.cardExpiry.on('change', (e) => {
        setCardValidation({ isCardExpiryEmpty: e.empty });
      });

      retrieveCard(undefined);
    },
  });

  const enableEditCardValidation = !isCardNumberEmpty || !isCardCVCEmpty || !isCardExpiryEmpty;

  const { mutate: retrieveCard, isLoading: retrieveCardLoading } = useRetrieveCardMutation({
    onSuccess: (data) => {
      form.setFieldsValue({
        ...data,
        first_name: data.metadata?.first_name,
        last_name: data.metadata?.last_name,
      });
      setCard({
        first_name: data.metadata?.first_name,
        last_name: data.metadata?.last_name,
        city: data.city,
        country: data.country,
        state: data.state,
        street: data.street,
        zip: data.zip,
      });
    },
  });

  useEffect(() => {
    initCard({
      cardNumberOption: {
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
              color: '#DDDDDD',
            },
          },
        },
        placeholder: 'Card Number',
      },
      cvcOption: {
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
              color: '#DDDDDD',
            },
          },
        },
        placeholder: 'CVC',
      },
      expiryDateOption: {
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
              color: '#DDDDDD',
            },
          },
        },
        placeholder: 'MM/YY',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFieldsChange(changedFields: FieldData[]) {
    const field = changedFields[0]?.name as string;

    if (field) {
      setCard({
        ...card,
        [field]: changedFields[0].value,
      } as any);
    }
  }

  const { alert } = useAlert();

  const mutationOptions = {
    onError: () =>
      alert(
        <div className="text-16px leading-26px text-center px-40px pb-55px pt-4">
          <div className="text-center font-medium text-#FF0000">Something Went Wrong!</div>
          <div className="text-center mt-35px">
            Your Credit card information has not
            <br /> been saved.
            <br />
            Please make sure the information
            <br /> provided is correct.
            <br />
            <br />
            If you continue to see this message,
            <br /> please contact{' '}
            <a href={`mailto:${process.env.REACT_APP_STRATIO_AI_EMAIL}`} className="text-black">
              {process.env.REACT_APP_STRATIO_AI_EMAIL}
            </a>
          </div>
        </div>,
        {
          onOk: (close) => {
            close();
          },
          onCancel: (close) => {
            close();
          },
        },
      ),
    onSuccess: () => {
      onSaveCard({
        ...form.getFieldsValue(),
        country: card?.country,
      });
    },
  };

  const { mutate: updateBillingDetails, isLoading: updateBillingDetailLoading } = useUpdateBillingDetailsMutation(
    mutationOptions,
  );

  const { mutate: updateStripeCard, isLoading: updateStripeCardLoading } = useUpdateStripeCardMutation(mutationOptions);

  function triggerSubmit() {
    if (
      !card ||
      Object.keys(card).some((key) => !card?.[key]?.['trim']?.().length) ||
      !card?.country ||
      !countries[card.country] ||
      (enableEditCardValidation && (isCardExpiryEmpty || isCardNumberEmpty || isCardCVCEmpty))
    ) {
      let field = '';

      if (!card?.country || !countries[card.country]) field = 'Country';
      else if (!card?.city?.trim().length) field = 'City';
      else if (!card?.first_name?.trim().length) field = 'First Name';
      else if (!card?.last_name?.trim().length) field = 'Last Name';
      else if (!card?.state?.trim().length) field = 'State/Province';
      else if (!card?.street?.trim().length) field = 'Street address';
      else if (!card?.zip?.trim().length) field = 'Zip/Postal code';
      else if (isCardCVCEmpty) field = 'Card CVC';
      else if (isCardNumberEmpty) field = 'Card Number';
      else if (isCardExpiryEmpty) field = 'Card Expiry';

      alert(<div className="pb-30px text-center text-16px">{field} is required</div>);

      return;
    }

    if (isCardExpiryEmpty && isCardNumberEmpty && isCardCVCEmpty) {
      updateBillingDetails(null);
    } else updateStripeCard(null);
  }

  return (
    <div className="mt-65px select-none">
      <div className="rounded-30px bg-white text-center p-60px mt-30px">
        <div className="flex">
          <div>
            <div className="w-74px h-74px p-4 rounded-full border border-#00BEEB">
              <img src="/v2/images/cpu.png" alt="cpu" className="w-full h-full" />
            </div>
          </div>
          <div className="flex-grow pt-3">
            <div className="flex items-center pl-30px ml-6px pb-70px">
              <div className="text-left">
                <div className="text-24px font-medium leading-31px">Extra GPU Time</div>
                <div className="text-14px font-light">
                  <span className="text-#FF0000">*</span> Extra GPU Time purchased will not be refunded.
                </div>
              </div>
              <div className="text-24px font-medium ml-60px">${plan.prices.extra_gpu}/hour</div>
              <div className="text-20px font-medium flex items-center ml-auto space-x-24px">
                <Input
                  className="gpu-quantity-input"
                  suffix={
                    <div className="flex-col flex">
                      <PolygonSVG
                        fill="currentColor"
                        className="transform -rotate-90 text-#E4E4E4 cursor-pointer hover:text-#00BEEB"
                        onClick={() => setQuantity(quantity + 1 > 999 ? 999 : quantity + 1)}
                      />
                      <PolygonSVG
                        fill="currentColor"
                        className="transform rotate-90 text-#E4E4E4 cursor-pointer hover:text-#00BEEB"
                        onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
                      />
                    </div>
                  }
                  value={quantity}
                  onChange={(e) =>
                    setQuantity(
                      (+e.target.value.replace(/[^0-9.]/g, '') || 1) > 999
                        ? 999
                        : +e.target.value.replace(/[^0-9.]/g, '') || 1,
                    )
                  }
                />

                <span>hour(s)</span>
              </div>
            </div>

            <div className="mr-74px h-px ml-6px bg-#DDDDDD" />

            <div className="text-16px leading-20px pr-74px">
              <div className="flex justify-end">
                <table className="flex-grow">
                  <tr>
                    <td className="text-left"></td>
                    <td className="text-left text-transparent">&</td>
                  </tr>
                  <tr>
                    <td className="text-left text-transparent text-24px pt-3">&</td>
                    <td className="text-left text-22px font-normal"> </td>
                  </tr>
                </table>
                <table className="flex-shrink-0">
                  <tr>
                    <td className="text-left uppercase"></td>
                    <td className="text-left text-transparent">&</td>
                  </tr>
                  <tr>
                    <td className="text-left w-183px text-24px font-medium pt-3">Bag Subtotal</td>
                    <td className="text-left text-22px font-normal pt-3">
                      ${(quantity * +plan.prices.extra_gpu).toFixed(2)}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div className="text-left mt-51px update-stripe-card-form">
              <div className="text-22px font-medium leading-29px">Payment Information</div>
              <div className="text-20px font-light leading-26px mt-3">Billing Address</div>

              <Spin spinning={retrieveCardLoading}>
                <Form name="add-card" className="mt-30px" form={form} onFieldsChange={handleFieldsChange}>
                  <Form.Item name="country" noStyle>
                    <div className="border border-#DDDDDD rounded-xl flex items-center pl-8px mt-10px">
                      <div className="relative leading-none"></div>
                      <Select
                        placeholder="Country"
                        bordered={false}
                        className="flex-grow ml-10px"
                        value={card?.country}
                        options={Object.keys(countries).map((code) => ({
                          value: code,
                          label: countries[code],
                        }))}
                        onSelect={(country: string) => setCard({ ...card, country } as any)}
                      />
                    </div>
                  </Form.Item>
                  <Input id="country" type="hidden" value={card?.country} />
                  <div className="border border-#DDDDDD rounded-xl mt-10px">
                    <Form.Item name="street" noStyle normalize={(val: string) => val.slice(0, 99)}>
                      <Input
                        prefix={<div className="relative leading-none"></div>}
                        placeholder="Street Address"
                        id="street"
                        bordered={false}
                      />
                    </Form.Item>
                  </div>

                  <div className="border border-#DDDDDD rounded-xl mt-10px">
                    <Form.Item name="city" noStyle normalize={(val: string) => val.slice(0, 99)}>
                      <Input
                        prefix={<div className="relative leading-none"></div>}
                        placeholder="City"
                        id="city"
                        bordered={false}
                      />
                    </Form.Item>
                  </div>

                  <div className="flex -mx-6px mt-10px">
                    <div className="w-1/2 px-6px">
                      <div className="border border-#DDDDDD rounded-xl">
                        <Form.Item name="state" noStyle normalize={(val: string) => val.slice(0, 99)}>
                          <Input
                            prefix={<div className="relative leading-none"></div>}
                            placeholder="State/Province"
                            id="state"
                            bordered={false}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="w-1/2 px-6px">
                      <div className="border border-#DDDDDD rounded-xl">
                        <Form.Item name="zip" noStyle normalize={(val: string) => val.slice(0, 99)}>
                          <Input
                            prefix={<div className="relative leading-none"></div>}
                            placeholder="Zip/Postal Code"
                            id="zip"
                            bordered={false}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="mt-26px text-14px">Payment</div>

                  <div className="flex -mx-6px mt-4">
                    <div className="w-1/2 px-6px">
                      <div className="border border-#DDDDDD rounded-xl">
                        <Form.Item name="first_name" noStyle normalize={(val: string) => val.slice(0, 99)}>
                          <Input
                            prefix={<div className="relative leading-none"></div>}
                            placeholder="First Name"
                            id="first_name"
                            bordered={false}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="w-1/2 px-6px">
                      <div className="border border-#DDDDDD rounded-xl">
                        <Form.Item name="last_name" noStyle normalize={(val: string) => val.slice(0, 99)}>
                          <Input
                            prefix={<div className="relative leading-none"></div>}
                            placeholder="Last Name"
                            id="last_name"
                            bordered={false}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="border border-#DDDDDD rounded-xl flex mt-10px px-3 pl-6px pb-2 pt-2">
                    <div className="flex-grow pl-4 relative">
                      <div id="card-nubmer"></div>
                    </div>
                    <div className="w-60px relative">
                      <div id="card-expiry"></div>
                    </div>
                    <div className="w-40px relative">
                      <div id="card-cvc"></div>
                    </div>
                  </div>
                </Form>
              </Spin>
            </div>

            <div className="text-24px font-medium leading-20px flex justify-end space-x-20px mt-44px">
              <AppButton type="_FFFFFF" className="h-30px w-96px text-12px font-normal rounded-full" onClick={onCancel}>
                Cancel
              </AppButton>
              <AppButton
                type="_00BEEB"
                className="h-30px w-96px text-12px font-normal rounded-full"
                onClick={triggerSubmit}
                loading={updateStripeCardLoading || updateBillingDetailLoading}
                disabled={updateStripeCardLoading || updateBillingDetailLoading}
              >
                UPDATE
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
