import { useQuery, UseQueryOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { PlanEndpoint } from 'src/services/endpoints';
import { APIErrorResponse, GetUserQuotaResponse, PlanTypeEnum } from 'src/types';

export interface PlanEntity {
  quota: GetUserQuotaResponse;
  plan_type: PlanTypeEnum;
  created_at: string;
  current_period_start?: string;
  current_period_end?: string;
  will_renew_at?: string;
  will_change_at?: PlanTypeEnum;
  will_change_to?: PlanTypeEnum;
  prices: {
    free_plan: string;
    basic_plan: string;
    professional_plan: string;
    extra_gpu: string;
    upgrade_pro: string;
  };
}

export type GetPlanQueryResponse = PlanEntity;

export type GetPlanOptions = UseQueryOptions<GetPlanQueryFunc, APIErrorResponse, GetPlanQueryResponse>;
export type GetPlanQueryFunc = () => GetPlanQueryResponse;

export function useGetPlanQuery(id: string | number, options?: GetPlanOptions) {
  return useQuery<GetPlanQueryFunc, APIErrorResponse, GetPlanQueryResponse>(
    ['useGetPlanQuery', id],
    () => request.get(PlanEndpoint.getPlan(id)).then((res) => res.data),
    options,
  );
}
