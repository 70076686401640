import 'src/assets/styles/fonts.css';
import 'antd/dist/antd.less';
import 'src/assets/styles/index.less';
import 'react-toastify/dist/ReactToastify.css';
import 'src/assets/styles/tailwind.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider as AntdFormConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';

import { unregister } from './serviceWorker';

import { validateMessages } from 'src/helpers';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { RecoilRoot } from 'recoil';
import { ConfirmProvider } from 'src/hooks';
import Page500 from 'src/pages/Error/500';
import { NextConfirmProvider } from 'src/hooks/useNextConfirm';
import { AlertProvider } from 'src/hooks/useAlert';
import { ToastContainer } from 'react-toastify';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<Page500 />}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <AntdFormConfigProvider form={{ validateMessages }}>
            <NextConfirmProvider>
              <ConfirmProvider>
                <AlertProvider>
                  <App />
                  <ToastContainer autoClose={false} />
                </AlertProvider>
              </ConfirmProvider>
            </NextConfirmProvider>
          </AntdFormConfigProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
