import { LabelEntity } from 'src/types/label';

export interface ParamInput {
  aimodeId?: number;
}

export enum Color {
  RGB = 'rgb',
  Grayscale = 'grayscale',
}

export enum BatchSize {
  manual = 'manual',
  automatic = 'auto',
}
export enum BooleanType {
  true = 'true',
  false = 'false',
}

export enum FillColor {
  black = 'black',
  gray = 'gray',
  white = 'white',
}
export interface ICreateTrainingClassInput {
  name: string;
  description: string;
}
export interface ICreateTrainingClassResponsive {
  id: number;
  name: string;
  description: string;
}

export interface IUpdateTrainingSetting {
  unit: number;
  training_unit: number;
  validation_unit: number;
  test_unit: number;
  epochs: number;
  hyperparameters: string;
}
export interface IResultCodeResponsive {
  result: boolean;
  message: string;
  code: number;
}

export interface EstimatedTimeResponsive {
  result: boolean;
  message: EstimatedTimeMsg;
  code: number;
}
export interface EstimatedTimeMsg {
  estimate_time: number;
  user_gpu_time: number;
}

export interface ResumeTraining {
  epochs: number;
  hyperparameters?: string;
}
