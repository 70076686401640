import React from 'react';
import { TrashSVG } from 'src/assets/icons';

export interface TrashDeleteButtonProps {
  onClick: () => void;
}

export default function TrashDeleteButton({ onClick }: TrashDeleteButtonProps) {
  return (
    <div className="inline-block w-24px select-none relative group" onClick={onClick}>
      <TrashSVG className="w-24px h-26px text-#888888 cursor-pointer group-hover:text-#BD0034" />
      <div
        className="absolute -bottom-8 left-1/2 text-white text-12px py-1 px-4 bg-#707070 transform -translate-x-1/2 hidden group-hover:block"
        style={{ borderRadius: 4 }}
      >
        DELETE
      </div>
    </div>
  );
}
