import { Col, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { BackCircleSVG } from 'src/assets/icons';

export interface HeaderProps {}

export default function Header(props: HeaderProps) {
  const history = useHistory();

  return (
    <Row>
      <Col offset={1} span={3} className="flex items-center text-38px justify-end pr-8">
        <BackCircleSVG onClick={() => history.goBack()} className="cursor-pointer" />
      </Col>
      <Col className="flex items-center">
        <h2 className="uppercase text-24px font-bold mb-0 ml-20">image view</h2>
      </Col>
    </Row>
  );
}
