import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';

export interface TextTooltipProps {
  children?: any;
  lineClamp: number;
  className?: string;
  breakType?: 'break-all' | 'break-word';
  textStyle?: any;
}

export default function TextTooltip({
  children,
  lineClamp,
  breakType = 'break-all',
  className = '',
  textStyle,
}: TextTooltipProps) {
  const textRef = useRef<HTMLSpanElement>(null);
  const [allow, setAllow] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (textRef.current) {
      const span = textRef.current.getClientRects();

      setAllow(span.length > lineClamp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  function onResize() {
    if (textRef.current) {
      const span = textRef.current.getClientRects();

      setAllow(span.length > lineClamp);
    }
  }

  return (
    <motion.div className="relative" ref={containerRef}>
      {allow ? (
        <Tooltip title={children}>
          <div className={`line-clamp-${lineClamp} ${className} ${breakType}`}>
            <span ref={textRef} style={textStyle}>
              {children}
            </span>
          </div>
        </Tooltip>
      ) : (
        <div className={`line-clamp-${lineClamp} ${className} ${breakType}`}>
          <span ref={textRef} style={textStyle}>
            {children}
          </span>
        </div>
      )}
    </motion.div>
  );
}
