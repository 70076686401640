import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useUILogin } from '../context/modal';
import '../styles/modal.less';
import { TimesCircleSVGP } from 'src/components/icons';

const ForgotPassword = React.lazy(() => import('../elements/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../elements/ResetPassword'));
const AlmostThere = React.lazy(() => import('../elements/AlmostThere'));
const Activate = React.lazy(() => import('../elements/Activate'));

export const ModalLogin: React.FC = () => {
  const { displayModal, closeModal, modalView, modalAction, modalContent } = useUILogin();

  return (
    <Modal
      visible={displayModal}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
      centered
      className="modal-custom"
      closeIcon={<TimesCircleSVGP />}
      {...modalAction}
    >
      {modalView === 'RESET_PASSWORD' && <ResetPassword />}
      {modalView === 'FORGOT_PASSWORD' && <ForgotPassword />}
      {modalView === 'ALMOST_THERE' && <AlmostThere email={modalContent?.email} />}
      {modalView === 'ACTIVATE' && <Activate />}
    </Modal>
  );
};
