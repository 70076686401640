import { useQuery, UseQueryOptions } from 'react-query';
import { APIErrorResponse, ProjectAccessGrantsEntity } from 'src/types';
import { request } from 'src/helpers/request';
import { ProjectsEndpoint } from '../endpoints';

export type ProjectAccessGrantsResponse = ProjectAccessGrantsEntity[];
export type ProjectsQueryOptions = UseQueryOptions<
  ProjectAccessGrantsResponse,
  APIErrorResponse,
  ProjectAccessGrantsResponse
>;
export type ProjectsQueryFunc = () => ProjectAccessGrantsResponse;

export function useProjectAccessGrantsQuery(id: string, options?: ProjectsQueryOptions) {
  return useQuery(
    ['access_grants', id],
    () => request.get(ProjectsEndpoint.accessGrants(id)).then((res) => res.data),
    options,
  );
}
