import React, { Dispatch, FC, memo, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { Row, Spin } from 'antd';
import { extractDirectoryList, getImageThumbnailUrl, showUnknowErrorMessage } from 'src/utils';
import ThumbnailRow from 'src/pages/Images/Detail/components/ThumbnailRow';
import { useHistory, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { TypeModuleImageEnum } from 'src/types';
import { useGetImageListInPathLazyQuery } from 'src/services/queries/useGetImageListInPathQuery';
import { useGetPathInfoLazyQuery } from 'src/services/queries/useGetPathInfo';
import useCurrentProject from 'src/hooks/useCurrentProject';
import { AppRoutes } from 'src/helpers';
import LeftArrow from 'src/pages/Images/Detail/LeftArrow';
import RightArrow from 'src/pages/Images/Detail/RightArrow';
import Header from 'src/pages/Images/Detail/Header';
import { AnnotationEntity } from 'src/types/annotation';
import AnnotationBox from 'src/pages/Images/Detail/AnnotationBox';
import RightBar from 'src/pages/Images/Detail/RightBar';
import { ImageEntity } from 'src/types/image';
import { useDeleteAnnotationsMutation } from 'src/services/mutations/useDeleteAnnotationsMutation';

const containerWidth = 767;
const containerHeight = 600;

interface AnimatedScaleSVGProps {
  children: ReactNode;
  width: number;
  height: number;
  scale: number;
  image?: ImageEntity;
  zoomScale: number;
  setZoomScale: Dispatch<SetStateAction<number>>;
}

const AnimatedScaleSVG = ({
  children,
  width,
  height,
  zoomScale,
  setZoomScale,
  scale: initialScale,
  image,
}: AnimatedScaleSVGProps) => {
  const [mouseDown, setMouseDown] = useState(false);

  const [transform, setTransform] = useState({
    x: 0,
    y: 0,
  });

  const [holdPosition, setHoldPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setTransform({
      x: 0,
      y: 0,
    });
    setHoldPosition({
      x: 0,
      y: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    if (zoomScale === initialScale) {
      setTransform({
        x: 0,
        y: 0,
      });
      setHoldPosition({
        x: 0,
        y: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomScale, initialScale]);

  function handleWheel(e: React.WheelEvent<SVGSVGElement>) {
    e.preventDefault();

    const delta = e.deltaY * -0.001;

    const newScale = zoomScale + delta <= initialScale ? initialScale : zoomScale + delta;

    if ((image?.width || 0) / newScale >= 100 || (image?.height || 0) / newScale >= 78)
      setZoomScale(zoomScale + delta <= initialScale ? initialScale : zoomScale + delta);
  }

  const changeCursor = zoomScale > initialScale;

  function handleMouseDown(e: React.MouseEvent<SVGGElement, MouseEvent>) {
    e.preventDefault();

    setHoldPosition({
      x: e.pageX - transform.x,
      y: e.pageY - transform.y,
    });
    setMouseDown(true);
  }

  function handleMouseUp(e: any) {
    e.preventDefault();

    setMouseDown(false);
  }

  function handleMouseMove(e: React.MouseEvent<SVGGElement, MouseEvent>) {
    e.preventDefault();

    if (changeCursor && mouseDown) {
      setTransform({
        x: e.pageX - holdPosition.x,
        y: e.pageY - holdPosition.y,
      });
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);

    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, []);

  useEffect(() => {
    document
      .getElementById('svg-container')
      ?.addEventListener('mousewheel', (e) => e.preventDefault(), { passive: false });

    return () => document.getElementById('svg-container')?.removeEventListener('mousewheel', (e) => e.preventDefault());
  }, []);

  const contentWidth = width * zoomScale;
  const contentHeight = height * zoomScale;

  return (
    <motion.svg
      width={containerWidth}
      height={containerHeight}
      viewBox={`0 0 ${containerWidth} ${containerHeight}`}
      className="bg-transparent select-none relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onWheel={handleWheel}
      id="svg-container"
    >
      <svg width={contentWidth} height={contentHeight} x="50%" y="50%" id="overflow-visible" className="block">
        <g transform={`translate(-${contentWidth / 2}, -${contentHeight / 2})`}>
          <g
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            style={{
              cursor: changeCursor
                ? mouseDown
                  ? 'grabbing'
                  : zoomScale > initialScale
                  ? 'grab'
                  : undefined
                : undefined,
              transform: `translate(${transform.x}px, ${transform.y}px)`,
            }}
          >
            {children}
          </g>
        </g>
      </svg>
    </motion.svg>
  );
};

interface Props {
  typeActions?: TypeModuleImageEnum;
}

interface Params {
  projectId: string;
  pathId: string;
  imageId: string;
  testId: string;
}

const ImagesDetail: FC<Props> = (props) => {
  const [zoomScale, setZoomScale] = useState(1);

  const [hoverId, setHoverId] = useState<number>(-1);
  const [isShowLabel, setIsShowLabel] = useState(true);

  const [images, setImages] = useState<ImageEntity[]>([]);
  const [annotations, setAnnotations] = useState<AnnotationEntity[]>([]);

  const history = useHistory();

  const { pathId, imageId, projectId } = useParams<Params>();

  const { data: projectData } = useCurrentProject();

  const { mutate: getImages, isLoading } = useGetImageListInPathLazyQuery({
    onError: (err) => showUnknowErrorMessage(err.message),
    onSuccess: (res) => setImages(res),
  });

  const { data: folderData, mutate: getPath } = useGetPathInfoLazyQuery({
    onSuccess: (res) => {},
  });

  const { mutate: deleteAnnotations } = useDeleteAnnotationsMutation({
    onError: (err) => showUnknowErrorMessage(err.message),
  });

  const thumbnails =
    images.map((item) => ({
      id: item.id.toString(),
      src: getImageThumbnailUrl(item.id),
    })) || [];

  const selectedImageData = images.find((item) => item.id.toString() === imageId);

  const selectedImageUrl = selectedImageData ? getImageThumbnailUrl(selectedImageData.id, 'Preview') : null;

  useEffect(() => {
    setAnnotations(selectedImageData?.annotation || []);
  }, [selectedImageData]);

  function handleDeleteAnnotation() {
    const ids = selectedImageData?.annotation
      .filter((item) => !annotations.some((ann) => ann.id === item.id))
      .map((item) => item.id);

    if (ids?.length) {
      deleteAnnotations({
        ids,
      });

      setImages(
        images.map((image) => (image.id === selectedImageData?.id ? { ...image, annotation: annotations } : image)),
      );
    }
  }

  const { width = 0, height = 0 } = selectedImageData || {};

  useEffect(() => {
    getImages({
      id: pathId,
    });
    getPath({
      id: pathId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goToSlide(action: 'next' | 'previous') {
    let imageIndex = images.findIndex((i) => i.id.toString() === imageId) || 0;
    imageIndex += action === 'next' ? 1 : -1;

    const selectedImageId = images[imageIndex]?.id.toString();

    if (!selectedImageId) return;

    goToImageDetail(selectedImageId);
  }

  function goToImageDetail(imageId: string) {
    history.replace(
      AppRoutes.replaceParams(AppRoutes.images.detail, {
        projectId: projectId,
        imageId: imageId.toString(),
        pathId: pathId,
      }),
    );
  }

  const paths = extractDirectoryList(projectData!, pathId!, folderData!);

  const currentImageIndex = thumbnails.findIndex((item) => item.id.toString() === imageId);

  const scaleX = width / containerWidth > 1 ? containerWidth / width : 1;
  const scaleY = height / containerHeight > 1 ? containerHeight / height : 1;

  const scale = width > height ? scaleX : scaleY;

  useEffect(() => {
    setZoomScale(scale);
  }, [scale, width, height, selectedImageData]);

  const sortedAnnotations = annotations.find((item) => item.id === hoverId)
    ? [...annotations.filter((item) => item.id !== hoverId), annotations.find((item) => item.id === hoverId)!]
    : annotations;

  return (
    <Spin spinning={isLoading}>
      <div
        style={{ minWidth: 1900, minHeight: 'calc(100vh - 139px)' }}
        className="select-none py-8 flex flex-col justify-between"
      >
        <Header />

        <Row className="py-20px justify-between">
          <LeftArrow visible={currentImageIndex >= 1 && !isLoading} onClick={() => goToSlide('previous')} />

          <div className="flex">
            <div
              style={{ width: containerWidth, height: containerHeight }}
              className="flex-shrink-0 bg-#E4E4E4 overflow-hidden"
            >
              <AnimatePresence exitBeforeEnter>
                <AnimatedScaleSVG
                  width={width}
                  height={height}
                  scale={scale}
                  key={selectedImageUrl}
                  image={selectedImageData}
                  zoomScale={zoomScale}
                  setZoomScale={setZoomScale}
                >
                  {selectedImageUrl && <image width="100%" height="100%" href={selectedImageUrl} />}
                  {sortedAnnotations.map((annotation) => (
                    <AnnotationBox
                      annotation={annotation}
                      key={annotation.id}
                      scale={scale}
                      image={selectedImageData}
                      isHover={hoverId === annotation.id}
                      onHover={(val) => setHoverId(val === true ? annotation.id : -1)}
                      isShowLabel={isShowLabel}
                      zoomScale={zoomScale - (zoomScale - scale) * 0.01}
                    />
                  ))}
                </AnimatedScaleSVG>
              </AnimatePresence>
            </div>

            <RightBar
              annotations={annotations}
              onAnnotationsChange={setAnnotations}
              image={selectedImageData}
              paths={paths}
              hoverId={hoverId}
              onHover={setHoverId}
              onShowLabelChange={setIsShowLabel}
              isShowLabel={isShowLabel}
              onDeleteAnnotations={handleDeleteAnnotation}
            />
          </div>

          <RightArrow
            visible={thumbnails && currentImageIndex <= thumbnails.length && !isLoading}
            onClick={() => goToSlide('next')}
          />
        </Row>

        <div className="mt-6" style={{ minHeight: 173 }}>
          <ThumbnailRow thumbnails={thumbnails} selectedId={imageId} onSelect={(id) => goToImageDetail(id)} />
        </div>
      </div>
    </Spin>
  );
};

export default memo(ImagesDetail);
