import { Col } from 'antd';
import React from 'react';
import { LeftArrowSVG } from 'src/assets/icons';

export interface LeftArrowProps {
  visible?: boolean;
  onClick: () => void;
}

export default function LeftArrow({ visible, onClick }: LeftArrowProps) {
  return (
    <Col span={2} className="flex pl-12 items-center">
      {visible && <LeftArrowSVG className="cursor-pointer" onClick={onClick} />}
    </Col>
  );
}
