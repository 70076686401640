import { useQuery, UseQueryOptions } from 'react-query';
import { APIErrorResponse, ProjectDetailQueryResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { ProjectsEndpoint } from '../endpoints';

export type ProjectDetailQueryOptions = UseQueryOptions<
  ProjectDetailQueryFunc,
  APIErrorResponse,
  ProjectDetailQueryResponse
>;
export type ProjectDetailQueryFunc = () => ProjectDetailQueryResponse | null;

export function useProjectDetailQuery(id: string, options?: ProjectDetailQueryOptions) {
  return useQuery<ProjectDetailQueryFunc, APIErrorResponse, ProjectDetailQueryResponse>(
    ['project_detail_query', id],
    () =>
      id !== null && id !== undefined
        ? request.get(ProjectsEndpoint.projectDetail(id)).then((res) => res.data)
        : new Promise((res, rej) => res(() => null)),
    options,
  );
}
