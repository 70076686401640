import React from 'react';
import { Col, Row, Typography } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal';
import { ReactNode } from 'react';
import { CloseCircleSVG } from 'src/assets/icons';

const { Text } = Typography;

export interface AppModalProps extends ModalProps {
  textTitle?: string;
  children: ReactNode;
  header?: ReactNode;
}

function AppModal({
  onCancel,
  textTitle,
  children,
  width,
  header = (
    <Row justify="space-between" className="border-black-8 border-b pb-2 mb-4">
      <Col>
        <Text strong>{textTitle}</Text>
      </Col>
      <Col>
        <CloseCircleSVG className="cursor-pointer" onClick={onCancel as any} />
      </Col>
    </Row>
  ),
  bodyStyle = { padding: 16 },
  ...props
}: AppModalProps) {
  return (
    <Modal
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      title={null}
      footer={null}
      bodyStyle={bodyStyle}
      width={width}
      {...props}
    >
      {header}
      {children}
    </Modal>
  );
}

export default AppModal;
