import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';
import React from 'react';

export interface SpinnerProps {
  height?: number | string;
  loading?: boolean;
  size?: SpinSize;
  children?: any;
}

const Spinner: React.FC<SpinnerProps> = ({ height, loading = true, size, children }) => {
  if (loading)
    return (
      <Spin
        style={{
          width: '100%',
          height: height || 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        spinning={loading}
        size={size || 'default'}
      />
    );

  return children;
};

export default Spinner;
