import React, { Fragment } from 'react';
import Page from 'src/pages';
import { AppRoutes } from 'src/helpers';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from 'src/components';
import { PrivateRouteProps } from 'src/components/PrivateRoute';
import { CreateAnnotationLayout, MainLayout, CreateTrainingLayout } from 'src/layouts';
import { SelectTestLayout } from 'src/layouts/CreateAnnotationLayout';
import MyPageLayout from 'src/layouts/MyPageLayout';
import DiscoverLayout from 'src/layouts/Discover';
import StatisticLayout from 'src/layouts/StatisticLayout';

const exact = true;
const isPrivate = true;
const isAuthRoute = true;

export const routes: PrivateRouteProps[] = [
  {
    path: AppRoutes.login,
    exact,
    component: Page.Login,
    isAuthRoute,
    layout: DiscoverLayout,
  },

  // { path: AppRoutes.home, exact, title: AppTitles.home, component: Page.Home },

  { path: AppRoutes.discover.home, exact, component: Page.Discover.Home, layout: DiscoverLayout },
  { path: AppRoutes.discover.plan, exact, component: Page.Discover.Plan, layout: DiscoverLayout },
  { path: AppRoutes.discover.product, exact, component: Page.Discover.Product, layout: DiscoverLayout },
  { path: AppRoutes.discover.support, exact, component: Page.Discover.Support, layout: DiscoverLayout },

  {
    path: AppRoutes.projectList,
    exact,
    component: Page.ProjectList,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.projectDashboards(),
    exact,
    component: Page.ProjectDashboards,
    layout: MainLayout,
    isPrivate,
  },
  { path: AppRoutes.about, exact, component: Page.About },

  { path: AppRoutes.images.index(), exact, component: Page.ImageIndex, layout: MainLayout, isPrivate },
  { path: AppRoutes.images.list(), exact, component: Page.Images, layout: MainLayout, isPrivate },

  { path: AppRoutes.images.detail, exact, component: Page.ImagesDetail, layout: MainLayout, isPrivate },
  {
    path: AppRoutes.annotation.images.detail,
    exact,
    component: Page.Annotation.NewTask.ImageDetail,
    layout: MainLayout,
    isPrivate,
  },
  { path: AppRoutes.training.images.detail, exact, component: Page.Training.ViewImage, layout: MainLayout, isPrivate },
  {
    path: AppRoutes.annotation.task.selectProjects(),
    exact,
    component: Page.Annotation.NewTask.SelectProject,
    layout: CreateAnnotationLayout,
    isPrivate,
  },
  {
    path: AppRoutes.annotation.task.selectImages(),
    component: Page.Annotation.NewTask.SelectImage,
    layout: CreateAnnotationLayout,
    isPrivate,
  },
  { path: AppRoutes.annotation.task.jobList, exact, component: Page.Annotation.JobList, layout: MainLayout, isPrivate },
  {
    path: AppRoutes.annotation.task.jobAiModelList,
    exact,
    component: Page.Annotation.JobAiModelList,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.training.listAiModel(),
    exact,
    component: Page.Training.listAiModel,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.training.listClassPath,
    exact,
    component: Page.Training.listClass,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.training.classes.selectProjects(),
    exact,
    component: Page.Training.NewClasses.SelectProject,
    layout: CreateTrainingLayout,
    isPrivate,
  },
  {
    path: AppRoutes.training.classes.selectImages(),
    component: Page.Training.NewClasses.SelectImage,
    layout: CreateTrainingLayout,
    isPrivate,
  },
  {
    path: AppRoutes.training.setting(),
    exact,
    component: Page.Training.setting,
    layout: MainLayout,
    isPrivate,
  },
  // {
  //   path: AppRoutes.training.matrix,
  //   exact,
  //   component: Page.Training.matrix,
  //   layout: MainLayout,
  //   isPrivate,
  // },
  {
    path: AppRoutes.training.progress(),
    exact,
    component: Page.Training.Progress,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.training.result(),
    exact,
    component: Page.Training.Result,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.training.imageDetail(),
    exact,
    component: Page.Training.DetailView,
    layout: MainLayout,
    isPrivate,
  },

  {
    path: AppRoutes.annotation.task.list(),
    exact,
    component: Page.Annotation.TaskList,
    layout: MainLayout,
    isPrivate,
  },
  { path: AppRoutes.tests, exact, component: Page.Tests, layout: MainLayout, isPrivate },
  {
    path: AppRoutes.testsClasses.list,
    exact,
    component: Page.TestsClasses.List,
    layout: MainLayout,
    isPrivate,
  },
  {
    path: AppRoutes.myPage.account,
    exact,
    component: Page.MyPage.Account,
    layout: MyPageLayout,
    isPrivate,
  },
  {
    path: AppRoutes.myPage.plan,
    exact,
    component: Page.MyPage.Plan,
    layout: MyPageLayout,
    isPrivate,
  },
  {
    path: AppRoutes.myPage.admin,
    exact,
    component: Page.MyPage.NewAdmin,
    layout: MyPageLayout,
    isPrivate,
  },
  {
    path: AppRoutes.myPage.statistics,
    exact,
    component: Page.MyPage.Statistics,
    layout: StatisticLayout,
    isPrivate,
  },
  {
    path: AppRoutes.myPage.billing,
    exact,
    component: Page.MyPage.Billing,
    layout: MyPageLayout,
    isPrivate,
  },

  {
    path: AppRoutes.myPage.orderGPU,
    exact,
    component: Page.MyPage.OrderGPU,
    layout: MyPageLayout,
    isPrivate,
  },

  { path: AppRoutes.results, exact, component: Page.Results, layout: MainLayout, isPrivate },
  { path: AppRoutes.results, exact, component: Page.Results, layout: MainLayout },
  {
    path: AppRoutes.testMethod.imagesDetail,
    exact,
    component: Page.TestMethod.ImagesDetail,
    layout: MainLayout,
  },
  {
    path: AppRoutes.testResultDetail,
    exact,
    component: Page.ResultsDetail,
    layout: MainLayout,
  },
  // {
  //   path: AppRoutes.testMethod.evaluation,
  //   exact,
  //   component: Page.TestMethod.Evaluations,
  //   layout: SelectTestLayout,
  //   isPrivate,
  // },
  // {
  //   path: AppRoutes.testMethod.prediction,
  //   exact,
  //   component: Page.TestMethod.Predictions,
  //   layout: SelectTestLayout,
  //   isPrivate,
  // },
  {
    path: AppRoutes.testMethod.selectProject(),
    component: Page.TestMethod.SelectProject,
    exact,
    layout: SelectTestLayout,
    isPrivate,
  },
  {
    path: AppRoutes.testMethod.selectImage(),
    component: Page.TestMethod.SelectImage,
    layout: SelectTestLayout,
    exact,
    isPrivate,
  },

  { path: '*', isPrivate: false, component: Page[404] },
];

export const RootRouter = () => {
  return (
    <Fragment>
      <Switch>
        <Redirect exact from={AppRoutes.home} to={AppRoutes.discover.home} />
        <Redirect exact from={AppRoutes.annotation.index()} to={AppRoutes.annotation.task.list()} />
        <Redirect exact from={AppRoutes.training.index()} to={AppRoutes.training.listAiModel()} />

        {routes.map((route) => (
          <PrivateRoute key={route.path?.toString()} {...route} />
        ))}
      </Switch>
    </Fragment>
  );
};
