import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AppRoutes } from 'src/helpers';
import { sortedUniq, union } from 'lodash';
import { selectedPathForTraining, updatingState, useResetTraining } from 'src/recoil-stores/create-training';
import { useAiModelQuery } from 'src/services/queries/useAiModelQuery';
import { useSelectDataMlClassesMutation } from 'src/services/mutations/useSelectDataMlClassesMutation';
import { showUnknowErrorMessage } from 'src/utils';
import useCurrentProject from 'src/hooks/useCurrentProject';
import { useEffect } from 'react';

function useCreateTraining() {
  const [selectedPathTraining] = useRecoilState(selectedPathForTraining);

  const selectedList = selectedPathTraining.filter((s) => (s.selected && s.count) || (s.images?.length && !s.selected));

  const { projectId, classId, aimodeId } = useParams<{ projectId: string; classId: string; aimodeId: string }>();
  const { data: dataAimodel }: any = useAiModelQuery({ idUrl: Number(aimodeId) });
  const datasetAimodelClass = dataAimodel?.dataset_type === 'ANNOTATION_DATA' ? true : undefined;
  const history = useHistory();
  const reset = useResetTraining();

  const { mutate: selectDataMlClasses, isLoading } = useSelectDataMlClassesMutation({
    onError: (e: any) => showUnknowErrorMessage(e.message),
  });

  const setUpdating = useSetRecoilState(updatingState);

  useEffect(() => {
    setUpdating(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { data } = useCurrentProject();

  function handleCreateTraining() {
    const imageIds: number[] = [];

    selectedList
      .filter((s) => s.images?.length)
      .map((s) => s.images)
      .forEach((images) => imageIds.push(...images!));

    const pathIds = selectedList.filter((s) => s.selected).map((s) => s.path!.id);
    const labels = union(...selectedList.map((s) => s.labels || []));

    selectDataMlClasses(
      {
        id: Number(classId),
        images: !datasetAimodelClass ? sortedUniq(imageIds) : [],
        annotations: datasetAimodelClass ? sortedUniq(imageIds) : [],
        path: sortedUniq(pathIds),
        labels: data?.labels.filter((l) => labels.includes(l.name)).map((l) => l.id) || [],
      },
      {
        onSuccess: () => {
          history.push(
            AppRoutes.replaceParams(AppRoutes.training.listClassPath, {
              projectId: projectId,
              aimodeId: aimodeId,
            }),
          );
          reset();
        },
      },
    );
  }

  return {
    handleCreateTraining,
    isLoading,
  };
}

export default useCreateTraining;
