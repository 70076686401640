import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { APIErrorResponse, PlanTypeEnum } from 'src/types';
import {} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElement,
  StripeCardCvcElementOptions,
  StripeCardExpiryElement,
  StripeCardExpiryElementOptions,
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js';

declare const Payment: {
  GetCardInfo: (onSuccess: (card: CardEntity) => void, onError: ErrorResponse) => void;
  InitCardUI: (
    cardNumberOption: StripeCardNumberElementOptions,
    expiryDateOption: StripeCardExpiryElementOptions,
    cvcOption: StripeCardCvcElementOptions,
  ) => void;
  AddCard: (onSuccess: () => void, onError: ErrorResponse) => void;
  PayExtarGPU: (hour: number, onSuccess: () => void, onError: ErrorResponse) => void;
  DeleteCard: (onSuccess: () => void, onError: ErrorResponse) => void;
  UpdateCard: (onSuccess: () => void, onError: ErrorResponse) => void;
  RetrieveCard: (onSuccess: (card: CardInstance) => void, onError: ErrorResponse) => void;
  SubscribePlan: (planType: PlanTypeEnum, onSuccess: () => void, onError: ErrorResponse) => void;
  RetrievePrices: (onSuccess: (price: PriceEntity) => void, onError: ErrorResponse) => void;
  UpdateBillingDetails: (onSuccess: () => void, onError: ErrorResponse) => void;
};

export interface StripeElements {
  cardNumber: StripeCardNumberElement;
  cardExpiry: StripeCardExpiryElement;
  cardCvc: StripeCardCvcElement;
}

export type ErrorResponse = (errorCode: number, error?: { error: APIErrorResponse }) => void;
export type CardEntity = {
  hasCard: boolean;
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  state?: string;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  first_name?: string;
  last_name?: string;
};

export type PriceEntity = {
  free_plan: number;
  basic_plan: number;
  professional_plan: number;
  extra_gpu: number;
};

export interface CardInstance extends CardEntity {
  id?: string;
  metadata?: {
    first_name?: string;
    last_name?: string;
  };
}

export type RetrievePricesResponse = void;
export type RetrievePricesInput = undefined;
export type RetrievePricesMutationOptions = UseMutationOptions<
  RetrievePricesResponse,
  APIErrorResponse,
  RetrievePricesInput
>;
export function useRetrievePricesMutation(options?: RetrievePricesMutationOptions) {
  return useMutation<RetrievePricesResponse, APIErrorResponse, RetrievePricesInput>(
    () =>
      new Promise((res, rej) => {
        Payment.RetrievePrices(
          (price) => res(price as any),
          (code, error) => rej({ ...error, code }),
        );
      }),
    options,
  );
}

export type SubscribePlanResponse = void;
export type SubscribePlanInput = {
  plan: PlanTypeEnum;
};
export type SubscribePlanMutationOptions = UseMutationOptions<
  SubscribePlanResponse,
  APIErrorResponse,
  SubscribePlanInput
>;
export function useSubscribePlanMutation(options?: SubscribePlanMutationOptions) {
  return useMutation<SubscribePlanResponse, APIErrorResponse, SubscribePlanInput>(
    ({ plan }) =>
      new Promise((res, rej) => {
        Payment.SubscribePlan(plan, res, (code, error) => rej({ ...error, code }));
      }),
    options,
  );
}

export type GetCardInfoQueryResponse = CardEntity;
export type GetCardInfoQueryOptions = UseQueryOptions<GetCardInfoQueryFunc, APIErrorResponse, GetCardInfoQueryResponse>;
export type GetCardInfoQueryFunc = () => GetCardInfoQueryResponse;

export function useGetCardInfoQuery(options?: GetCardInfoQueryOptions) {
  return useQuery<GetCardInfoQueryFunc, APIErrorResponse, GetCardInfoQueryResponse>(
    'get_card_info',
    () =>
      new Promise((res, rej) => {
        Payment.GetCardInfo(
          (card) => res(card as any),
          (errorCode, error) => {
            rej({
              ...error,
              code: errorCode,
            });
          },
        );
      }),
    options,
  );
}

export type InitStripeCardUIResponse = StripeElements;
export type InitStripeCardUIInput = {
  cardNumberOption: StripeCardNumberElementOptions;
  expiryDateOption: StripeCardExpiryElementOptions;
  cvcOption: StripeCardCvcElementOptions;
};
export type InitStripeCardUIOptions = UseMutationOptions<
  InitStripeCardUIResponse,
  APIErrorResponse,
  InitStripeCardUIInput
>;

export function useInitStripeCardUIMutation(options?: InitStripeCardUIOptions) {
  return useMutation<InitStripeCardUIResponse, APIErrorResponse, InitStripeCardUIInput>(
    ({ cardNumberOption, cvcOption, expiryDateOption }) =>
      new Promise((res, rej) => {
        const elements = Payment.InitCardUI(cardNumberOption, expiryDateOption, cvcOption);
        res(elements as any);
      }),
    options,
  );
}

export type AddStripeCardResponse = void;
export type AddStripeCardInput = undefined | null;
export type AddStripeCardMutationOptions = UseMutationOptions<
  AddStripeCardResponse,
  APIErrorResponse,
  AddStripeCardInput
>;

export function useAddStripeCardMutation(options?: AddStripeCardMutationOptions) {
  return useMutation<AddStripeCardResponse, APIErrorResponse, AddStripeCardInput>(
    () =>
      new Promise((res, rej) => {
        try {
          Payment.AddCard(
            () => res(),
            (code, e) => rej({ ...e?.error, code }),
          );
        } catch (error) {
          rej(error);
        }
      }),
    options,
  );
}

export type RetrieveCardResponse = CardInstance;
export type RetrieveCardInput = undefined;
export type RetrieveCardMutationOptions = UseMutationOptions<RetrieveCardResponse, APIErrorResponse, RetrieveCardInput>;
export function useRetrieveCardMutation(options?: RetrieveCardMutationOptions) {
  return useMutation<RetrieveCardResponse, APIErrorResponse, RetrieveCardInput>(
    () =>
      new Promise((res, rej) => {
        Payment.RetrieveCard(
          (card) => res(card as any),
          (code, error) => rej({ ...error, code }),
        );
      }),
    options,
  );
}

export type UpdateStripeCardResponse = void;
export type UpdateStripeCardInput = undefined | null;
export type UpdateStripeCardMutationOptions = UseMutationOptions<
  UpdateStripeCardResponse,
  APIErrorResponse,
  UpdateStripeCardInput
>;
export function useUpdateStripeCardMutation(options?: UpdateStripeCardMutationOptions) {
  return useMutation<UpdateStripeCardResponse, APIErrorResponse, UpdateStripeCardInput>(
    () =>
      new Promise((res, rej) => {
        Payment.UpdateCard(res, (code, error) => rej({ ...error, code }));
      }),
    options,
  );
}

export type UpdateBillingDetailsResponse = void;
export type UpdateBillingDetailsInput = undefined | null;
export type UpdateBillingDetailsMutationOptions = UseMutationOptions<
  UpdateBillingDetailsResponse,
  APIErrorResponse,
  UpdateBillingDetailsInput
>;
export function useUpdateBillingDetailsMutation(options?: UpdateBillingDetailsMutationOptions) {
  return useMutation<UpdateBillingDetailsResponse, APIErrorResponse, UpdateBillingDetailsInput>(
    () =>
      new Promise((res, rej) => {
        Payment.UpdateBillingDetails(res, (code, error) => rej({ ...error, code }));
      }),
    options,
  );
}

export type DeleteStripeCardResponse = void;
export type DeleteStripeCardInput = undefined;
export type DeleteStripeCardMutationOptions = UseMutationOptions<
  DeleteStripeCardResponse,
  APIErrorResponse,
  DeleteStripeCardInput
>;
export function useDeleteStripeCardMutation(options?: DeleteStripeCardMutationOptions) {
  return useMutation<DeleteStripeCardResponse, APIErrorResponse, DeleteStripeCardInput>(
    () =>
      new Promise((res, rej) => {
        Payment.DeleteCard(res, (code, error) => rej({ ...error, code }));
      }),
    options,
  );
}

export type PayExtraGPUResponse = void;
export type PayExtraGPUInput = {
  hour: number;
};
export type PayExtraGPUMutationOptions = UseMutationOptions<PayExtraGPUResponse, APIErrorResponse, PayExtraGPUInput>;
export function usePayExtraGPUMutation(options?: PayExtraGPUMutationOptions) {
  return useMutation<PayExtraGPUResponse, APIErrorResponse, PayExtraGPUInput>(
    ({ hour }) =>
      new Promise((res, rej) => {
        Payment.PayExtarGPU(hour, res, (code, error) => rej({ ...error, code }));
      }),
    options,
  );
}

export type SubscribeNewPlanResponse = null;
export type SubscribeNewPlanInput = {
  plan: PlanTypeEnum;
};
export type SubscribeNewPlanOptions = UseMutationOptions<
  SubscribeNewPlanResponse,
  APIErrorResponse,
  SubscribeNewPlanInput
>;

export function useSubscribeNewPlanMutation(options?: SubscribeNewPlanOptions) {
  return useMutation<SubscribeNewPlanResponse, APIErrorResponse, SubscribeNewPlanInput>(
    ({ plan }) =>
      new Promise((res, rej) => {
        Payment.SubscribePlan(plan, res as any, (code, error) => rej({ ...error, code }));
      }),
    options,
  );
}
