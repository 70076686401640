import React, { FC, memo, SVGProps } from 'react';
interface Props extends SVGProps<SVGSVGElement> {}

const SearchSVGP: FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" {...props}>
      <path
        d="M24.487,22.013l-4.442-4.442a8.767,8.767,0,1,0-2.475,2.475l4.442,4.442a1.75,1.75,0,1,0,2.475-2.475ZM6.625,12.75a6.125,6.125,0,1,1,6.125,6.125A6.132,6.132,0,0,1,6.625,12.75Z"
        transform="translate(-4 -4)"
      />
    </svg>
  );
};

export default memo(SearchSVGP);
