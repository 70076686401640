import { useMutation, UseMutationOptions } from 'react-query';
import { APIErrorResponse, GetImageListInPathQueryParams, GetImageListInPathQueryResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { PathEndpoint } from 'src/services/endpoints';

export type GetImageListInPathLazyQueryOptions = UseMutationOptions<
  GetImageListInPathQueryResponse,
  APIErrorResponse,
  GetImageListInPathQueryParams
>;

export function useGetImageListInPathLazyQuery(options?: GetImageListInPathLazyQueryOptions) {
  return useMutation<GetImageListInPathQueryResponse, APIErrorResponse, GetImageListInPathQueryParams>(
    'GetImageListInPath_lazy_query',
    (params) =>
      request
        .get(PathEndpoint.imageListInPath(params.id), {
          params,
        })
        .then((res) => res.data),
    options,
  );
}
