import { DetailedHTMLProps, ReactNode, useRef } from 'react';
import { delay } from 'src/utils';

export interface DbClickProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  onClick?: (e: any) => void;
  onDoubleClick?: () => void;
}

function DbClick({ children, onClick = () => {}, onDoubleClick = () => {}, ...props }: DbClickProps) {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick);

  return (
    <div onClick={handleClick} onDoubleClick={handleDoubleClick} {...props}>
      {children}
    </div>
  );
}

export const cancellablePromise = (promise: Promise<any>) => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

const useCancellablePromises = () => {
  const pendingPromises = useRef<any>([]);

  const appendPendingPromise = (promise: any) => (pendingPromises.current = [...pendingPromises.current, promise]);

  const removePendingPromise = (promise: any) =>
    (pendingPromises.current = pendingPromises.current.filter((p: any) => p !== promise));

  const clearPendingPromises = () => pendingPromises.current.map((p: any) => p.cancel());

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };

  return api;
};

const useClickPreventionOnDoubleClick = (onClick: any, onDoubleClick: any) => {
  const api = useCancellablePromises();

  const handleClick = (e: any) => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(200));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick(e);
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = () => {
    api.clearPendingPromises();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
};

export const noop = () => {};

export default DbClick;
