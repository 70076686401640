import { Check } from 'akar-icons';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useEffect, useState } from 'react';
import { MinusSVG } from 'src/assets/icons';

export interface CheckboxOutlineProps {
  children?: ReactNode;
  checked?: boolean;
  onCheckedChange?: (val: boolean) => void;
  indeterminate?: boolean;
  size?: 'sm' | 'md';
  containerClassName?: string;
}

export default function CheckboxOutline({
  children,
  checked: check,
  onCheckedChange,
  indeterminate,
  size = 'md',
  containerClassName,
}: CheckboxOutlineProps) {
  const [checked, setChecked] = useState(!!check);

  // useEffect(() => {
  //   onCheckedChange && onCheckedChange(checked);
  // }, [checked]);

  useEffect(() => {
    setChecked(!!check);
  }, [check]);

  return (
    <div
      className={classNames('flex items-center cursor-pointer', containerClassName)}
      onClick={() => (onCheckedChange ? onCheckedChange(!checked) : setChecked(!checked))}
      style={{ userSelect: 'none' }}
    >
      <div
        className={classNames(
          'bg-white flex-center border border-#707070 hover:border-black',
          size === 'md' ? 'w-20px h-20px' : 'w-10px h-10px',
        )}
      >
        <AnimatePresence exitBeforeEnter>
          {checked ? (
            <motion.div
              className={classNames('flex-center', size === 'md' ? 'w-5 h-5' : 'h-10px w-10px')}
              key="_check"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Check className={classNames('text-black', size === 'md' ? 'w-4 h-4' : 'w-10px h-10px')} />
            </motion.div>
          ) : indeterminate ? (
            <motion.div className="bg-#0269B7 w-20px h-20px flex-center" key="_uncheck" exit={{ opacity: 0 }}>
              <MinusSVG className="w-8px text-white" />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
      {children}
    </div>
  );
}
