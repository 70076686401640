import { Cross } from 'akar-icons';
import { useRecoilState } from 'recoil';
import { FolderSVG, TrashSVG } from 'src/assets/icons';
import { Button } from 'src/components';
import { selectedPathForTraining } from 'src/recoil-stores/create-training';
import { extractDirectoryList } from 'src/utils';
import useCreateTraining from 'src/pages/Training/CreateClasses/useCreateTraining';
export interface SelectedSideBarProps {}

function SelectedSideBar(props: SelectedSideBarProps) {
  const [selectedPathTraining, setSelectedChange] = useRecoilState(selectedPathForTraining);

  const displayList = selectedPathTraining.filter(
    (item, index, self) =>
      (item.selected && self.findIndex((f) => f.path?.id === item.path?.id) === index) ||
      (item.images?.length && !item.selected),
  );

  const selectedList = selectedPathTraining.filter(
    (s, index, self) =>
      (s.selected && s.count && self.findIndex((f) => f.path?.id === s.path?.id) === index) ||
      (s.images?.length && !s.selected),
  );

  const { handleCreateTraining, isLoading } = useCreateTraining();

  const counts = displayList.map((s) => s.count || s.images?.length || 0).reduce((p, n) => p + n, 0);

  return (
    <div
      className="w-1/4 h-body-screen bg-white border-r border-#DDDDDD flex flex-col flex-shrink-0 sticky z-10"
      style={{ top: '139px', maxWidth: 462, minWidth: 462 }}
    >
      <div className="bg-#DDDDDD h-32px">
        <div className="h-full w-136px flex-center bg-white">
          <span className="text-12px font-light">SELECTED DATA</span>
        </div>
      </div>
      <div className="p-10px flex-grow overflow-y-auto">
        <div className="h-full border border-#DDDDDD overflow-y-auto py-40px px-10px space-y-20px">
          {!!displayList.length &&
            displayList.map((item) => (
              <div key={item.path?.id} className="flex items-center">
                <div
                  className="w-6 h-6 rounded-full flex-center cursor-pointer hover:bg-#888888 mr-2"
                  onClick={() => setSelectedChange((anns) => anns.filter((ann) => ann.path?.id !== item.path?.id))}
                >
                  <Cross className="w-11px h-11px cursor-pointer" />
                </div>
                <FolderSVG className="w-11 h-8 text-#1F94ED mr-2" />
                <div className="text-12px font-medium line-clamp-1">
                  {extractDirectoryList(item.project!, item.path?.id!, item.path!)?.map((path, index) => (
                    <span key={index}>
                      {path.name}
                      {extractDirectoryList(item.project!, item.path?.id!, item.path!)[index + 1] && '/'}
                    </span>
                  ))}
                </div>
                <div className="text-16px font-light ml-auto">({item.count || item.images?.length || 0})</div>
              </div>
            ))}
        </div>
      </div>
      <div className="px-10px pb-20px flex justify-end">
        <span className="mr-auto text-#888888 text-12px font-light">
          {displayList.map((s) => s.count || s.images?.length || 0).length ? counts : 'No'} images selected
        </span>
        <Button
          type="_00BEEB"
          className="rounded-full h-32px text-12px font-normal mr-4"
          style={{ minWidth: 60 }}
          disabled={!selectedList.length || isLoading}
          onClick={handleCreateTraining}
          loading={isLoading}
        >
          SAVE3
        </Button>
        <Button
          type="_EEEEEE"
          className="rounded-full h-32px w-110px text-12px font-normal"
          disabled={!displayList.length || isLoading}
          onClick={() => setSelectedChange([])}
        >
          <div className="flex-center space-x-2">
            <TrashSVG className="text-#AAAAAA w-4 h-5 leading-3" />
            <span className="text-#AAAAAA leading-3">Delete All</span>
          </div>
        </Button>
      </div>
    </div>
  );
}

export default SelectedSideBar;
