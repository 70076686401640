import { Progress } from 'antd';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { memo } from 'react';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { FolderSVG } from 'src/assets/icons';
import { AppRoutes } from 'src/helpers';
import { ERROR_CODE } from 'src/helpers/common';
import { useConfirm } from 'src/hooks';
import useCurrentProject from 'src/hooks/useCurrentProject';
import { useNextConfirm } from 'src/hooks/useNextConfirm';
import { UPLOAD_FAILED_TYPE } from 'src/pages/Images/List';
import isFileValid from 'src/pages/Images/List/UploadSection/isFileValid';
import { activeChangePlanModal, uploadedImages, uploadedPaths } from 'src/recoil-stores';
import { createPath } from 'src/services/mutations/useCreatePathMutation';
import { uploadImage } from 'src/services/mutations/useUploadImageMutation';
import { Permission, PlanTypeEnum } from 'src/types';

import './progress.less';

interface UploadFolderPopupContextProps {
  directories?: DirectoryEntity[];
  setDirectories?: Dispatch<SetStateAction<DirectoryEntity[]>>;
  projectId?: string | number;
  setProjectIdFolder?: any;
}

const UploadFolderPopupContext = createContext<UploadFolderPopupContextProps>({});

interface UploadFolderPopupProviderProps {
  children: ReactNode;
}

type DirectoryState = {
  parentPathId?: number;
  isFile?: boolean;
  isDirectory?: boolean;
  entity?: RcFile;
} & {
  [K: string]: DirectoryState;
};

interface DirectoryEntity {
  key: string;
  tree: DirectoryState;
  path: string;
  totalItems: number;
}

export function UploadFolderPopupProgressProvider({ children }: UploadFolderPopupProviderProps) {
  const [directories, setDirectories] = useState<DirectoryEntity[]>([]);
  const [projectId, setProjectIdFolder] = useState(0);

  return (
    <UploadFolderPopupContext.Provider value={{ directories, setDirectories, projectId, setProjectIdFolder }}>
      {directories.map((dir) => (
        <Node key={dir.key} directory={dir} projectId={projectId} />
      ))}
      {children}
    </UploadFolderPopupContext.Provider>
  );
}

const Node = memo(({ directory, projectId }: { directory: DirectoryEntity; projectId: number }) => {
  const [uploadedCount, setUploadedCount] = useState(0);
  const [uploadErrType, setUploadErrType] = useState('');

  const setUploadedPaths = useSetRecoilState(uploadedPaths);
  const setUploadedImage = useSetRecoilState(uploadedImages);

  const [unsupportedFiles, setUnsupportedFiles] = useState<File[]>([]);

  const [error, setError] = useState(false);

  const cancelSource = useMemo(() => axios.CancelToken.source(), []);

  useEffect(() => {
    toast(
      <div className="py-5 px-30px relative">
        <div className="flex items-center space-x-30px">
          <div className="h-90px flex-center rounded-full flex-shrink-0">
            <FolderSVG className="w-16 h-50px text-#AAAAAA" />
          </div>
          <div>
            <div className="text-12px font-light">Uploading...</div>
            <div className="flex items-end">
              <div className="text-20px font-medium line-clamp-1 mr-3 break-all">
                {Object.keys(directory?.tree).filter((d) => typeof directory?.tree[d] !== 'boolean')[0]}
              </div>
              <div className="text-12px font-medium">
                ({uploadedCount >= directory.totalItems ? directory.totalItems : uploadedCount}/{directory.totalItems})
              </div>
            </div>
          </div>
        </div>
        <div className="absolute text-#888888 text-12px font-light" style={{ bottom: '4px', right: '14px' }}>
          0%
        </div>
        <div className="absolute right-0 w-full text-8px" style={{ lineHeight: '8px', bottom: '-5px' }}>
          <Progress percent={0} size="small" showInfo={false} />
        </div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_LEFT,
        className: 'upload-wrapper',
        toastId: directory.key,
        closeOnClick: false,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directory]);

  const upload = async (name: string, dir: DirectoryState, parent: number, callbackErrorFile: any) => {
    if (dir.entity && typeof dir.entity !== 'string') {
      const image = dir.entity;
      const formData = new FormData();
      formData.append('name', image?.name || '');
      formData.append('file_content', image || '');
      formData.append('path', `${parent}`);

      const isValid = await isFileValid(image);

      if (!isValid) {
        setUnsupportedFiles((files) => [...files, image]);
        callbackErrorFile(image);
        setUploadedCount((count) => count + 1);
      } else {
        try {
          const { data: imageData } = await uploadImage(
            formData,
            (progress) => progress === 100 && setUploadedCount((count) => count + 1),
            {
              cancelToken: cancelSource.token,
            },
          );

          setUploadedImage({
            image: imageData,
            parentId: parent,
          });
        } catch (error: any) {
          const errCode = error?.response?.data?.code;

          if (errCode === ERROR_CODE.IMAGE_UPLOAD.UNSUPPORTED_IMAGE) {
            setUnsupportedFiles((files) => [...files, image]);
            callbackErrorFile(image);
            setUploadedCount((count) => count + 1);
            setUploadErrType(UPLOAD_FAILED_TYPE.FILE_TYPE_ERROR);
          } else {
            throw error;
          }
        }
      }
    } else {
      const { data } = await createPath(
        {
          name,
          parent,
        },
        {
          cancelToken: cancelSource.token,
        },
      );

      setUploadedPaths({
        parentId: parent,
        path: data,
      });

      for (const c_key of Object.keys(dir)) {
        if (typeof dir[c_key] !== 'boolean') {
          await upload(c_key, dir[c_key], data.id, callbackErrorFile);
        }
      }

      return {
        parentId: parent,
        path: data,
      };
    }
  };

  const { confirm: _confirm } = useConfirm();
  const { data: projectInfo } = useCurrentProject(projectId.toString());
  const ownerPlan = projectInfo?.owner_plan;
  const isOwner = projectInfo?.permission === Permission.OWNER;

  const isPlanPro = ownerPlan === PlanTypeEnum.PLAN_PRO;

  const history = useHistory();

  const setOpenPlanModal = useSetRecoilState(activeChangePlanModal);

  useEffect(() => {
    const execute = async () => {
      const internalFileTypeErrors: File[] = [];

      if (directory) {
        for (const key of Object.keys(directory.tree)) {
          await upload(key, directory.tree[key], +directory.path, (file: File) => internalFileTypeErrors.push(file));
        }
      }

      if (internalFileTypeErrors.length) {
        _confirm(
          <div>
            <p className="text-center mb-20px text-16px mx-30px font-bold">{'Some images were not uploaded.'}</p>
            <div className="mx-30px">
              <p className="text-center">
                Please ensure to upload uncorrupted images files in the following formats: jpg, jpeg, png, bmp, tif,
                tiff, webp.
              </p>
              <p className="mt-15px">{`Failed uploads (${internalFileTypeErrors?.length}):`}​</p>
              <div className="scrollbar-custom overflow-y-auto max-h-85px pr-10px">
                {internalFileTypeErrors?.map((e, i: number) => (
                  <div key={i} className="my-5px">
                    • {e.name}
                  </div>
                ))}
              </div>
            </div>
          </div>,
          {
            onOk: (close) => {
              close();
            },
          },
        );
      }
    };

    execute().catch(async (error) => {
      const errCode = error?.response?.data?.code;

      if (
        errCode === ERROR_CODE.PERMISSION.DISK_QUOTA_EXCEED ||
        errCode === ERROR_CODE.PERMISSION.UPLOAD_IMAGE_EXCEED
      ) {
        setUploadErrType(UPLOAD_FAILED_TYPE.STORAGE_EXCEEDED);
        _confirm(
          <div>
            <div className="">
              <p className="text-center">
                You don’t have enough storage to upload images.​
                <br />
                {isPlanPro ? (
                  <p>
                    ​Please contact{' '}
                    <a href={`mailto:${process.env.REACT_APP_STRATIO_AI_EMAIL}`} className="text-black underline">
                      {process.env.REACT_APP_STRATIO_AI_EMAIL}
                    </a>
                    <br />
                    if you want to have more storage. ​
                  </p>
                ) : (
                  <p>Please upgrade your plan to upload more images.​</p>
                )}
              </p>
            </div>
          </div>,
          !isPlanPro
            ? {
                okText: isOwner ? 'Upgrade' : 'OK',
                onOk: (close) => {
                  if (isOwner) {
                    setOpenPlanModal(true);
                    !!history && history.push(AppRoutes.myPage.plan);
                  }

                  close();
                },
                onCancel: isOwner ? (close) => close() : undefined,
                popupWidth: 374,
              }
            : {
                onOk: (close) => {
                  close();
                },
                popupWidth: 374,
              },
        );
        return;
      }
      setError(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPath, directory, uploadImage]);

  useEffect(() => {
    if (directory?.key) {
      setUploadedCount(0);
    }
  }, [directory?.key]);

  const { confirm } = useNextConfirm();

  useEffect(() => {
    if (directory) {
      let percent = Math.round((uploadedCount * 100) / (directory?.totalItems || 1));

      percent = percent < 0 ? 0 : percent > 100 ? 100 : percent;

      const strokeColor =
        uploadErrType === UPLOAD_FAILED_TYPE.FILE_TYPE_ERROR
          ? '#EB9A00'
          : uploadErrType === UPLOAD_FAILED_TYPE.STORAGE_EXCEEDED
          ? '#FF0000'
          : percent < 100
          ? '#2b94ed'
          : percent >= 100 && unsupportedFiles.length
          ? '#EB9A00'
          : '#52c41a';

      toast.update(directory.key, {
        render: (
          <div className="py-5 px-30px relative">
            <div className="flex items-center space-x-30px">
              <div className="h-90px flex-center rounded-full flex-shrink-0">
                <FolderSVG className="w-16 h-50px text-#AAAAAA" />
              </div>
              <div>
                <div className="text-12px font-light">
                  {error
                    ? 'Failed'
                    : percent < 100 || uploadErrType === UPLOAD_FAILED_TYPE.STORAGE_EXCEEDED
                    ? 'Uploading...'
                    : 'Uploaded'}
                </div>
                <div className="flex items-end">
                  <div className="text-20px font-medium line-clamp-1 mr-3 break-all">
                    {Object.keys(directory?.tree).filter((d) => typeof directory?.tree[d] !== 'boolean')[0]}
                  </div>
                  <div className="text-12px font-medium">
                    (
                    {(uploadedCount >= directory.totalItems ? directory.totalItems : uploadedCount) -
                      unsupportedFiles.length}
                    /{directory.totalItems - unsupportedFiles.length})
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute text-#888888 text-12px font-light" style={{ bottom: '4px', right: '14px' }}>
              {percent}%
            </div>
            <div className="absolute right-0 w-full text-8px" style={{ lineHeight: '8px', bottom: '-5px' }}>
              <Progress
                strokeColor={strokeColor}
                percent={percent}
                size="small"
                showInfo={false}
                status={error ? 'exception' : undefined}
              />
            </div>
          </div>
        ),
        position: toast.POSITION.BOTTOM_LEFT,
        className: 'upload-wrapper',
        toastId: directory.key,
        closeOnClick: false,
        autoClose: false,
        style: {
          width: 368,
        },
        closeButton: ({ closeToast }) => (
          <span
            className="text-20px cursor-pointer text-#888888 underline font-thin Toastify__close-button"
            onClick={() =>
              percent < 100 && !uploadErrType
                ? confirm(
                    <div className="text-center pb-60px pt-10px text-16px text-#BD0034">
                      Are you sure you want to
                      <br /> cancel upload reupload?
                    </div>,
                    {
                      onOk: (close) => {
                        setError(true);
                        cancelSource.cancel();
                        close();
                        closeToast();
                      },
                    },
                  )
                : closeToast()
            }
          >
            {percent < 100 && !uploadErrType ? 'CANCEL' : 'CLOSE'}
          </span>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directory, directory?.key, directory?.totalItems, uploadedCount, error, uploadErrType]);

  return null;
});

export const useUploadFolderPopupProgress = () => {
  const context = useContext(UploadFolderPopupContext);

  if (context === undefined) {
    throw new Error(`useUI must be used within a Provider`);
  }
  return context;
};
