import React, { Fragment } from 'react';
import { DotQuareSVG } from 'src/assets/icons';
import { PathDirectoryItem } from 'src/modules/DataSelected/SecondaryMenu';
import { ImageFilterEnum } from 'src/pages/Images/List';
import ImageFilterDropdown from 'src/pages/Images/List/SecondaryMenu/ImageFilterDropdown';
import SortDirection from 'src/pages/Images/List/SecondaryMenu/SortDirection';
import { ImageListInPathSortType } from 'src/types';
import Checkbox from '../Checkbox';
import AnnotatedFilterDropdown from './AnnotatedFilterDropdown';
import SortDropdown from './SortDropdown';

export interface SecondaryMenuProps {
  selectAll?: boolean;
  onSelectAllChange: (val: boolean) => void;
  onAnnotatedFilterChange?: (val?: boolean) => void;
  onSortChange: (val?: ImageListInPathSortType) => void;
  onSortDirectionChange: (val?: 'ASC' | 'DESC') => void;
  paths: PathDirectoryItem[];
  indeterminate?: boolean;
  isSelectPath?: boolean;
  sortDirection?: 'ASC' | 'DESC';
  sort?: ImageListInPathSortType;
  annotated?: boolean;
  filterType?: 'IMAGE' | 'ANNOTATE';
  imageFilters?: ImageFilterEnum[];
  onImageFilterChange?: (val: ImageFilterEnum[]) => void;
}

export default function SecondaryMenu({
  selectAll,
  onSelectAllChange,
  onAnnotatedFilterChange,
  onSortChange,
  onSortDirectionChange,
  paths,
  indeterminate,
  isSelectPath = true,
  sortDirection,
  sort,
  annotated,
  filterType = 'ANNOTATE',
  imageFilters = [],
  onImageFilterChange,
}: SecondaryMenuProps) {
  return (
    <div className="mt-24px flex justify-between relative" style={{ zIndex: 1 }}>
      <div className="flex space-x-24px items-center path">
        {isSelectPath && (
          <div
            className="flex items-center space-x-4 py-2 px-3 bg-#F5F5F5 cursor-pointer"
            onClick={() => onSelectAllChange(!selectAll)}
          >
            <Checkbox checked={selectAll} onCheckedChange={onSelectAllChange} indeterminate={indeterminate}>
              <span className="text-#707070 text-12px ml-4">Select All</span>
            </Checkbox>
          </div>
        )}
        <div className="flex items-center">
          {isSelectPath ? (
            <DotQuareSVG style={{ width: 4, height: 4 }} className="text-#707070 mr-2" />
          ) : (
            <span className={`text-12px cursor-pointer font-semibold text-#707070 inline-block mr-2`}>
              Current Folder Location:
            </span>
          )}
          {!!paths?.length &&
            paths.map((item, index) => (
              <Fragment key={item.id}>
                <span
                  className={`text-12px cursor-pointer font-light ${
                    paths[index + 1] ? 'text-#707070' : 'text-#00BEEB font-medium'
                  }`}
                  onClick={() => item.push()}
                >
                  {item.name}

                  {paths[index + 1] && <span className="inline-block mx-1">/</span>}
                </span>
              </Fragment>
            ))}
        </div>
      </div>
      <div className="flex space-x-4 action">
        {filterType === 'ANNOTATE' ? (
          <AnnotatedFilterDropdown onAnnotatedFilterChange={onAnnotatedFilterChange!} annotated={annotated} />
        ) : (
          <ImageFilterDropdown onFilter={onImageFilterChange!} filterValues={imageFilters} />
        )}
        <SortDropdown onSortChange={onSortChange} sort={sort} />
        <SortDirection onSortDirectionChange={onSortDirectionChange} direction={sortDirection} />
      </div>
    </div>
  );
}
