import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks';
import { CarretDownSVG } from 'src/assets/icons';
import { ImageListInPathSortType } from 'src/types';

export interface SortDropdownProps {
  onSortChange: (val?: ImageListInPathSortType) => void;
  sort?: ImageListInPathSortType;
}

const options = [
  { label: 'Upload User', value: 'created_by' },
  { label: 'Date', value: 'created_at' },
  { label: 'Name', value: 'name' },
];

export default function SortDropdown({ onSortChange, sort }: SortDropdownProps) {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState<{ label: string; value: ImageListInPathSortType }>(
    options.find((o) => o.value === sort)
      ? ({
          label: options.find((o) => o.value === sort)?.label,
          value: options.find((o) => o.value === sort)?.value,
        } as any)
      : undefined,
  );

  useEffect(() => {
    onSortChange(value?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => visible && setVisible(false));

  return (
    <div className="relative w-120px flex-shrink-0 select-none" ref={containerRef}>
      <div
        className="flex items-center text-#888888 justify-between py-10px px-4 cursor-pointer"
        onClick={() => setVisible(!visible)}
      >
        <span className="text-12px font-medium ">{value?.label || 'Sort'}</span>
        <CarretDownSVG className={`w-2 transform ${visible ? 'rotate-180' : 'rotate-0'} transition-all`} />
      </div>
      <AnimatePresence>
        {visible && (
          <motion.div
            key="popup"
            exit={{ opacity: 0 }}
            className="absolute bottom-0 right-0 w-100px py-1 flex flex-col bg-white shadow-drop transform translate-y-full"
          >
            <div
              className="text-12px cursor-pointer hover:bg-#F5F5F5 px-4 py-1"
              onClick={() => {
                setValue({ label: 'Name', value: 'name' });
                setVisible(false);
              }}
            >
              Name
            </div>
            <div
              className="text-12px cursor-pointer hover:bg-#F5F5F5 px-4 py-1"
              onClick={() => {
                setValue({ label: 'Date', value: 'created_at' });
                setVisible(false);
              }}
            >
              Date
            </div>
            <div
              className="text-12px cursor-pointer hover:bg-#F5F5F5 px-4 py-1"
              onClick={() => {
                setValue({ label: 'Upload User', value: 'created_by' });
                setVisible(false);
              }}
            >
              Upload User
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
