import { useMutation, UseMutationOptions } from 'react-query';
import { AuthEndpoint } from '../endpoints';
import { APIErrorResponse, LoginInput, LoginResponse } from 'src/types';
import { request } from 'src/helpers/request';

export type LoginMutationOptions = UseMutationOptions<LoginResponse, APIErrorResponse, LoginInput>;

export default function useLoginMutation(options?: LoginMutationOptions) {
  return useMutation<LoginResponse, APIErrorResponse, LoginInput>(
    (values) => request.post(AuthEndpoint.login, values).then((res) => res.data),
    options,
  );
}
