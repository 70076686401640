import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { useAlert } from 'src/hooks/useAlert';
import useQueryString from 'src/hooks/useQuerystring';
import { LoginTab, ModalLogin } from './components';
import { ManageUILoginContext } from './context/modal';

import './index.less';

export enum AuthTabEnum {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
}

export interface LoginProps {}

export default function Login(props: LoginProps) {
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const screen = useWindowSize();

  const { alert, close: closeAlertPopup } = useAlert();

  const { tab = AuthTabEnum.SIGN_IN } = useQueryString<{ tab?: AuthTabEnum }>();

  const activeTab = AuthTabEnum[tab] || AuthTabEnum.SIGN_IN;

  useEffect(() => {
    if (screen.width <= 1280 && !showAlertPopup) {
      alert(
        <div className="text-16px leading-20px text-center text-#BD0034 px-40px pb-55px">
          This website is optimized for viewing on desktops and laptops. It might render differently on tablets or
          phones
        </div>,
        {
          onOk: (close) => {
            setShowAlertPopup(true);
            close();
          },
          onCancel: (close) => {
            setShowAlertPopup(true);
            close();
          },
          modalClassName: 'auth-alert-popup',
        },
      );
    } else {
      closeAlertPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlertPopup, screen.width]);

  useEffect(() => {
    document.body.classList.add('bg-white');

    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <ManageUILoginContext>
      <div className="auth-screen">
        <img
          src="/v2/images/Sign-In-Page-Overlay.png"
          alt="Sign In Page Overlay.png"
          className={classNames('background-overlay', {
            'sign-up-form': activeTab === 'SIGN_UP',
          })}
        />
        <div className="max-w-1920px mx-auto">
          <div className="auth-container">
            <div className="auth-title">
              {activeTab === 'SIGN_UP' ? (
                <>
                  Sign up{' '}
                  <span>
                    for free
                    <br />{' '}
                    <span className="font-medium" style={{ fontWeight: 500 }}>
                      to get started.
                    </span>
                  </span>
                </>
              ) : (
                <>
                  Welcome <span>back!</span>
                </>
              )}
            </div>
            <LoginTab />
          </div>
        </div>
      </div>
      <ModalLogin />
    </ManageUILoginContext>
  );
}
