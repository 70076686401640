import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { AuthEndpoint } from 'src/services/endpoints';
import { APIErrorResponse, ResetPasswordConfirmInput, ResetPasswordConfirmResponse } from 'src/types';

export type ResetPasswordConfirmOptions = UseMutationOptions<
  ResetPasswordConfirmResponse,
  APIErrorResponse,
  ResetPasswordConfirmInput
>;

export default function useResetPasswordConfirmMutation(options?: ResetPasswordConfirmOptions) {
  return useMutation<ResetPasswordConfirmResponse, APIErrorResponse, ResetPasswordConfirmInput>(
    (values) => request.post(AuthEndpoint.resetPasswordConfirm, values).then((res) => res.data),
    options,
  );
}
