import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { ClassEndpoint } from 'src/services/endpoints';
import { APIErrorResponse, SelectDataMlClassesInput, SelectDataMlClassesResponse } from 'src/types';

export type SelectDataMlClassesMutationOptions = UseMutationOptions<
  SelectDataMlClassesResponse,
  APIErrorResponse,
  SelectDataMlClassesInput
>;

export function useSelectDataMlClassesMutation(options?: SelectDataMlClassesMutationOptions) {
  return useMutation<SelectDataMlClassesResponse, APIErrorResponse, SelectDataMlClassesInput>(
    (values) =>
      request.post(ClassEndpoint.selectedData(values.id), { ...values, id: undefined }).then((res) => res.data),
    options,
  );
}
