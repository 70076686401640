import React, { useEffect } from 'react';
import useQueryString from 'src/hooks/useQuerystring';
import useActivateRegisterMutation from 'src/services/mutations/useVerifyRegisterMutation';
import { useUILogin } from 'src/pages/Login/context/modal';
import { useNavigation, useConfirm } from 'src/hooks';
import { ActivateRegisterInput } from 'src/types';
import '../styles/modal.less';
import { Button, Spinner } from 'src/components';

const Activate: React.FC = () => {
  const query = useQueryString<ActivateRegisterInput>();
  const { closeModal } = useUILogin();
  const navigation = useNavigation();
  const { confirm } = useConfirm();

  const { mutate, isLoading } = useActivateRegisterMutation({
    onError: () => {
      confirm(<div className="text-center text-#00BEEB">The effective time has elapsed. Please sign up again.</div>);
      navigation.reset();
      closeModal();
    },
  });

  useEffect(() => {
    mutate({
      email: query.email,
      token: query.token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="modal-activate p-0">
      <h4>Your account has been activated!</h4>
      <span>You may now sign in to access your account.</span>
      <div className="button">
        <Button
          shape="round"
          type="primary"
          onClick={() => {
            navigation.reset();
            closeModal();
          }}
        >
          <span className="text-12px">SIGN IN</span>
        </Button>
      </div>
    </div>
  );
};

export default Activate;
