import React from 'react';
import { Text } from 'src/components';

export interface FooterProps {}

export default function Footer(props: FooterProps) {
  return (
    <section>
      <footer className="bg-#DCDCDC">
        <div className="max-w-1920px mx-auto pl-64px pt-62px pb-51px sm:pt-45px sm:pl-160px sm:pb-40px xl:pl-130px">
          <div>
            <img src="/v2/images/stlabs-logo.png" alt="STLabs-logo" className="w-86px sm:w-104px" />
            <div>
              <Text className="text9px leading-18px sm:text-13px sm:leading-26px text-black">© 2024 STLabs</Text>
            </div>
          </div>
          <div className="mt-19px sm:mt-20px">
            <Text className="text9px leading-18px font-light sm:text-13px sm:leading-26px text-black">
              대구광역시 동구 매여로 52 1층
            </Text>
            <Text className="text11px leading-20px block sm:text-14px sm:leading-26px">
              <a href={`mailto:${process.env.REACT_APP_STRATIO_AI_EMAIL}`} className="text-black">
                {process.env.REACT_APP_STRATIO_AI_EMAIL}
              </a>
            </Text>
          </div>
          <Text className="text9px leading-18px font-light inline-block mt-44px sm:mt-51px sm:text-12px sm:leading-26px">
            <a target="_blank" href={process.env.REACT_APP_CAMERAI_PRIVACY_URL || ''} className="text-black">
              Privacy Policy
            </a>
          </Text>
        </div>
      </footer>
    </section>
  );
}
