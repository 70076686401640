import { useMutation, UseMutationOptions } from 'react-query';
import { ImageEndpoint } from '../endpoints';
import { APIErrorResponse, MoveImagesInput, MoveImagesResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { stringify } from 'qs';

export type MoveImagesMutationOptions = UseMutationOptions<MoveImagesResponse, APIErrorResponse, MoveImagesInput>;

export default function useMoveImagesMutation(options?: MoveImagesMutationOptions) {
  return useMutation<MoveImagesResponse, APIErrorResponse, MoveImagesInput>(
    (params) =>
      request
        .put(ImageEndpoint.moveImages, params.ids, {
          params: {
            path: params.path,
          },
          paramsSerializer: (params) => {
            return stringify(params, { arrayFormat: 'comma' });
          },
        })
        .then((res) => res.data),
    options,
  );
}
