import React, { createContext, Suspense, useEffect } from 'react';
import { RootRouter } from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { ProgressLoading } from 'src/components';
import { useMeQuery } from 'src/services/queries/useMeQuery';
import { useRecoilSnapshot } from 'recoil';
import { RetrieveMyInformationResponse } from 'src/types';
import { ImageQueryProvider } from 'src/hooks/useImageQuery';
import { UploadFolderPopupProgressProvider } from 'src/pages/Images/List/UploadSection/UploadFolderProgressPopup';
import { UploadImagePopupProgressProvider } from 'src/pages/Images/List/UploadSection/UploadImageProgressPopup';
export const UserContext = createContext<RetrieveMyInformationResponse | undefined>(undefined);
const App = React.memo(() => {
  const { isLoading, data } = useMeQuery({
    onSuccess: (res) => localStorage.setItem('TZ', res.time_zone),
  });

  if (isLoading) {
    return <ProgressLoading />;
  }

  return (
    <Suspense fallback={<ProgressLoading />}>
      <UserContext.Provider value={data}>
        {/* <DebugObserver /> */}
        <Router>
          <UploadImagePopupProgressProvider>
            <UploadFolderPopupProgressProvider>
              <ImageQueryProvider>
                <RootRouter />
              </ImageQueryProvider>
            </UploadFolderPopupProgressProvider>
          </UploadImagePopupProgressProvider>
        </Router>
      </UserContext.Provider>
    </Suspense>
  );
});

function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node).contents);
    }
  }, [snapshot]);

  return null;
}

export default App;
