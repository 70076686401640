import { useQuery, UseQueryOptions } from 'react-query';
import { AiModelEntity, APIErrorResponse, UrlParam } from 'src/types';
import { request } from 'src/helpers/request';
import { AiModelsEndpoint } from '../endpoints';
import { DeveloperOption } from 'src/pages/Training/Setting/DeveloperOptions';
import { BatchSize } from 'src/types/tranning';

export type AiModelResponse = AiModelEntity;
export type AiModelsQueryOptions = UseQueryOptions<AiModelsQueryFunc, APIErrorResponse, AiModelResponse>;
export type AiModelsQueryFunc = () => AiModelResponse;

export interface HyperparameterOption extends DeveloperOption {}

export function useAiModelQuery(params: UrlParam, options?: AiModelsQueryOptions) {
  return useQuery<AiModelsQueryFunc, APIErrorResponse, AiModelResponse>(
    ['ai_model_query', params],
    () => {
      const data = params.idUrl
        ? request.get(AiModelsEndpoint.AiModelItem(params.idUrl)).then((res) => {
            const hyperparameters: HyperparameterOption = Object.assign(
              {},
              ...((res.data.hyperparameters as string) || '')
                .split(',')
                .map((item) => item.trim())
                .filter((item) => !!item)
                .map((item: string) => ({
                  [item.split(' ')[0].toUpperCase()]: item.split(' ')[1],
                })),
            );

            return {
              ...res.data,
              ...(res.data.hyperparameters && {
                hyperparameters: {
                  ...hyperparameters,
                  batch_size_type: hyperparameters.BATCH_SIZE === 'auto' ? BatchSize.automatic : BatchSize.manual,
                  BATCH_SIZE: hyperparameters.BATCH_SIZE === 'auto' ? 8 : hyperparameters.BATCH_SIZE,
                },
              }),
              hyperparameters_str: res.data.hyperparameters,
            };
          })
        : new Promise((resolve) => resolve(null)).then((value) => value);
      return data;
    },
    { cacheTime: 0, ...options },
  );
}

export function useUpdateAiModelItemQuery(params: AiModelResponse, options?: AiModelsQueryOptions) {
  return useQuery<AiModelsQueryFunc, APIErrorResponse, AiModelEntity>(
    ['ai_model_update_query', params],
    () => {
      const data = params.id
        ? request
            .put(AiModelsEndpoint.AiModelItem(params.id), {
              ...params,
              id: undefined,
            })
            .then((res) => res.data)
        : new Promise((resolve) => resolve(null)).then((value) => value);
      return data;
    },
    options,
  );
}
