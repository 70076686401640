import { UserEntity } from './user';
import { AnnotationJob } from './annotationJob';
import { ImageEntity } from './image';
import { Label, Annotation, Training, Test, User } from './projects';
import { AxiosError } from 'axios';
import { AnnotationTask } from 'src/types/annotationTask';
import { SelectedImage } from 'src/types/selectedImage';
import { PathEntity } from 'src/types/path';
export type Maybe<T> = T | null | undefined;

export enum Permission {
  OWNER = 'OWNER',
  STAFF = 'STAFF',
  DEVELOPER = 'DEVELOPER',
  DATAENGINEER = 'DATAENGINEER',
}
export enum AccountStatusEnum {
  RESET_REQUESTED = 'RESET_REQUESTED',
  ACTIVATED = 'ACTIVATED',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  DELETED = 'DELETED',
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface LoginResponse {
  user: UserEntity;
  time_zone: string;
  is_admin: boolean;
  account_status: AccountStatusEnum;
  plan: PlanTypeEnum;
}

export interface ErrorResponse {
  result: boolean;
  message: any;
  code: number;
}

export type APIErrorResponse = AxiosError<ErrorResponse>;

export interface ImagesQueryResponse {
  count: number;
  next?: string;
  previous?: string;
  results: ImageEntity[];
}

export interface ImagesQueryParams {
  sort?: string;
  page?: number;
  filter?: boolean;
}

export interface AnnotationJobResponse extends AnnotationJob {}

export enum AnnotationJobStatusEnum {
  CREATED = 'CREATED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum StatusModelEnum {
  New = 'NEWLY_CREATED',
  Select = 'SELECTING_TEST_DATA',
  Ready = 'READY_FOR_TEST',
  Inprogress = 'TEST_IN_PROGRESS',
  Completed = 'COMPLETED',
}

export enum TestMethodEnum {
  EVALUATION = 'EVALUATION',
  PREDICTION = 'PREDICTION',
}
export enum TypeEditClassEnum {
  NORMAL,
  SPECIAL,
}

export enum Unit {
  Percent = 1,
  Count = 2,
}

export enum ScopeEnum {
  CLASSIFICATION = 'CLASSIFICATION',
  DETECTION = 'DETECTION',
}

export interface IClassItem {}
export enum DataSetTypeEnum {
  ANNOTATION_DATA = 'ANNOTATION_DATA',
  IMAGE_DATA = 'IMAGE_DATA',
}

export enum BackboneEnum {
  RESNET18 = 'RESNET18',
  RESNET34 = 'RESNET34',
  RESNET50 = 'RESNET50',
  YOLOV5S = 'YOLOV5S',
  YOLOV5M = 'YOLOV5M',
  YOLOV5L = 'YOLOV5L',
  YOLOV5X = 'YOLOV5X',

  LABELRESNET18 = 'ResNet18',
  LABELRESNET34 = 'ResNet34',
  LABELRESNET50 = 'ResNet50',
  LABELYOLOV5S = 'YOLOv5s',
  LABELYOLOV5M = 'YOLOv5m',
  LABELYOLOV5L = 'YOLOv5l',
  LABELYOLOV5X = 'YOLO5x',
}
export interface TestsInput {
  projectId: number;
  search?: string;
}

export interface Metric {
  key: string;
  value: number;
}

export interface PredictionAnnotation {
  id: number;
  bx: number;
  by: number;
  bh: number;
  bw: number;
}

export interface Probability {
  prediction: number;
  probability: number;
}

export interface ImagePrediction {
  image: number;
  prediction_annotation: PredictionAnnotation;
  probabilities: Probability[];
  correct: number;
}

export interface Mlresult {
  aimodel_testcase?: number;
  result_root_path?: string;
  confusion_matrix: any[];
  detection_summary: any;
  prediction_count: any;
  metrics: Metric;
  image_prediction?: ImagePrediction[];
}

export interface CreatedBy {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface TestsEntity {
  id: number;
  project: number;
  created_at: Date;
  started_at: Date;
  completed_at: Date;
  name: string;
  status: StatusModelEnum;
  description: string;
  testmethod: TestMethodEnum;
  dataset_type: DataSetTypeEnum;
  created_by: CreatedBy;
  aimodel: AiModelEntity;
  classes: ClassesEntity[];
  mlresult: Mlresult;
  scope?: ScopeEnum;
}

export interface RegisterInput {
  user: {
    username: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  password1: string;
  password2: string;
  time_zone: string;
}

export interface RegisterResponse {
  user: UserEntity;
  time_zone: string;
  account_status: AccountStatusEnum;
}

// Get Projects response
export interface ProjectsEntity {
  id: number;
  name: string;
  description: string;
  labels: Label[];
  public: boolean;
  is_favorite: boolean;
  updated_at: any;
  created_at: string;
  root_path: number;
  permission: string;
  owner: string;
  owner_plan: PlanTypeEnum;
}

export interface FavoriteProjectInput {
  id: number;
  favorite: boolean;
}
export interface FavoriteProjectResponse {
  result: boolean;
  message: string;
  code: number;
}
export interface ProjectAccessGrantsEntity {
  user: User;
  permission: Permission;
  created_at: Date;
  created_by: User;
}

export interface PublicProjectInput {
  id: number;
  public: boolean;
}
export interface PublicProjectResponse {
  result: boolean;
  message: string;
  code: number;
}

export interface ProjectRecentActivitiesEntity {
  Annotation: Annotation;
  Training: Training;
  Test: Test;
}

export interface CreateProjectInput {
  name: string;
  description: string;
  labels: Label[];
  public: boolean;
}

export interface CreateProjectResponse {
  id: number;
  name: string;
  description: string;
  labels: Label[];
  public: boolean;
  is_favorite: boolean;
  updated_at: Date;
  root_path: number;
  permission: string;
  owner: string;
}

export interface DeleteProjectInput {
  id: string;
}

export interface DeleteProjectResponse {
  result: boolean;
  message: string;
  code: number;
}

export interface UpdateProjectInput {
  id: number;
}

export interface UpdateProjectResponse {
  id: number;
  name: string;
  description: string;
  labels: Label[];
  public: boolean;
  is_favorite: boolean;
  updated_at: Date;
  root_path: number;
  permission: string;
  owner: string;
}

export interface UpdateAccessGrantsInput {
  id: string;
}

export interface UpdateAccessGrantsResponse {
  user: number;
  permission: string;
  created_at: Date;
  created_by: number;
}

export interface SearchUsersEntity {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  time_zone: string;
}

export interface SearchUsersInput {
  username: string;
}

// Activate Register
export interface ActivateRegisterInput {
  email: string;
  token: string;
}

export interface ActivateRegisterResponse {
  result: string;
  message: string;
  code: string;
}

export interface ResetPasswordInput {
  email: string;
}

export interface ResetPasswordResponse {
  result: string;
  message: string;
  code: string;
}

export interface ResetPasswordConfirmInput {
  password1: string;
  password2: string;
  token: string;
}

export interface ResetPasswordConfirmResponse {
  result: string;
  message: string;
  code: string;
}

// Update Detail User
export interface UpdateUserProfileInput {
  time_zone: string;
  password: string;
  new_password: string;
  new_password_confirm: string;
}

export interface UpdateUserProfileResponse {
  user: UserEntity;
  time_zone: string;
  account_status: AccountStatusEnum;
}

// Get Detail User
export interface UserDetailResponse extends LoginResponse {}

//AI MODEL
export interface ParamItem {
  id: number;
}
export interface ClassesEntity {
  id: number;
  name: string;
  description: string;
  color: string;
  labels: Label[];
  size: number;
  images?: any[]; // Test
  annotation?: any[]; // Test
}

export interface UrlParam extends Omit<AiModelInput, 'project'> {
  idUrl: number | string;
  project?: number;
}
export interface AiModelInput {
  project: number;
  name?: string;
  description?: string;
  backbone?: BackboneEnum;
  dataset_type?: DataSetTypeEnum;
  search?: string;
  status?: StatusModelEnum;
  scope?: ScopeEnum;
}

export enum AIModelStatusEnum {
  NEWLY_CREATED = 'NEWLY_CREATED',
  TRAINING_READY = 'TRAINING_READY',
  TRAINING = 'TRAINING',
  COMPLETED = 'COMPLETED',
}

export interface AiModelEntity {
  id: number;

  classes?: ClassesEntity[];

  created_by?: CreatedBy;

  completed_flag?: CompletedFlagEnum;

  name: string;

  status: AIModelStatusEnum;

  description: string;

  scope: ScopeEnum;

  backbone: BackboneEnum;

  dataset_type: DataSetTypeEnum;

  unit?: 0 | 1;

  training_unit?: number;

  validation_unit?: number;

  test_unit?: number;

  epochs?: number;

  hyperparameters?: AIModelHyperparameter;
  hyperparameters_str?: string;
  created_at: Date;

  started_at?: Date;

  completed_at?: Date;

  project?: number;

  project_name: string;

  pid?: number; // Training

  updated_at?: Date; // Training

  save_path?: string; // Training
  mAP: string | null;
  total_size?: number;
}

export interface AIModelHyperparameter {
  BALANCE: 'undersampling' | 'oversampling' | 'None';
  IMG_WIDTH: number | string;
  IMG_HEIGHT: number | string;
  IMG_COLOR: 'rgb' | 'grayscale';
  BATCH_SIZE: 'auto' | number | string;
  ROTATION: number | string;
  SHIFT_WIDTH: number | string;
  SHIFT_HEIGHT: number | string;
  FLIP_HORIZONTAL: 'true' | 'false';
  FLIP_VERTICAL: 'true' | 'false';
  ZOOM: number | string;
  NUM_HOLES: number | string;
  HOLE_HEIGHT: number | string;
  HOLE_WIDTH: number | string;
  FILL_COLOR: 'black' | 'gray' | 'white';
  BRIGHTNESS: number | string;
  SATURATION: number | string;
  HUE: number | string;
  LR_INIT: number | string;
  LR_SCHEDULER: 'None' | 'steplr' | 'explr';
  DECAY_EPOCH: number | string;
  DECAY_RATE: number | string;
  WARMUP: number | string;
  OPTIM: 'sgd' | 'adam';
  MOMENTUM: number | string;
  BETA_1: number | string;
  BETA_2: number | string;
  LOSS_FUNCTION: 'cross_entropy' | 'mse' | 'l1' | 'l2';
  EARLY_CRITERION: 'loss' | 'accuracy';
  EARLY_PATIENCE: number | string;
  EARLY_THRESHOLD: number | string;
  EARLY_VAL_FREQ: number | string;
  MODEL: 'None' | number | string;
  IMG_SIZE: number | string;
  LR_END: number | string;
  SCORE_THR: number | string;
  IOU_THR: number | string;
}

export interface TestsAddNewInput {
  id: number;
  project: number;
  name: string;
  description: string;
  testmethod: TestMethodEnum;
  dataset_type: DataSetTypeEnum;
  aimodel: number;
}

export interface TestsAddNewResponse {
  project: number;
  created_at: Date;
  name: string;
  status: string;
  description: string;
  testmethod: TestMethodEnum;
  dataset_type: DataSetTypeEnum;
  created_by: number;
  aimodel: number;
}
export interface TestsUpdateResponse {
  project: number;
  created_at: Date;
  name: string;
  status: string;
  description: string;
  testmethod: TestMethodEnum;
  dataset_type: DataSetTypeEnum;
  created_by: number;
  aimodel: number;
}

export interface TestsDeleteInput {
  id: number;
}

export type AnnotationTasksQueryResponse = AnnotationTask[];

export enum AnnotationTaskStatusEnum {
  CREATED = 'CREATED',
  INPROGRESS = 'INPROGRESS',
  READYTOAPPLY = 'READYTOAPPLY',
  COMPLETED = 'COMPLETED',

  APPLYING = 'APPLYING',
}

export interface CreateAnnotationTaskMutationInput {
  name: string;
  description: string;
  images_id?: SelectedImage;
}

export interface SelectDataMlClassesInput {
  id: number;
  images: Array<number>;
  annotations: Array<number>;
  path: Array<number>;
  labels?: number[];
}
export interface SelectDataMlClassesResponse {
  id?: string;
  name?: string;
  description?: string;
  color?: string;

  paths?: Array<number>;
  images: Array<number>;
  annotations: Array<number>;
  path: Array<number>;
}

export type CreateAnnotationTaskMutationResponse = AnnotationTask;

export interface DeleteAnnotationTaskMutationInput {
  id: number | string;
}

export interface GlobalSearchLazyQueryResponse {
  Annotation: { id: string; name: string }[];
  Image: {
    id: string;
    name: string;
    project_id: string;
    project_name: string;
    path_id: string;
    path_name: string;
    path_parents: any;
  }[];
  Project: { id: string; name: string }[];
  Test: { id: string; name: string }[];
  Test_Class: { id: string; name: string; aimodel_id: string; aimodel_name: string }[];
  Training: { id: string; name: string }[];
  Training_Class: { id: string; name: string; aitest_id: string; aitest_name: string }[];
}

export interface GlobalSearchLazyQueryParams {
  search?: string;
}

export type ProjectDetailQueryResponse = ProjectsEntity;

export type GetPathInfoQueryResponse = PathEntity;

export type GetImageListInPathQueryResponse = ImageEntity[];

export type GetPlanResponse = PathEntity;

export interface GetImageListInPathQueryParams {
  sort?: ImageListInPathSortType;
  // Annotated filter
  filter?: AnnotatedFilterType;
  search?: string;
  id: string;
}

export interface GetPlanParams {
  id: string;
}

export type AnnotatedFilterType = true | false;
export type ImageListInPathSortType = 'name' | 'created_by' | 'created_at' | 'height' | 'width' | 'annotated';

export interface CreatePathInput {
  name: string;
  parent: number;
}

export interface UpdatePathInput {
  name: string;
  id: number | string;
}
export interface DeleteManyPathsInput {
  ids: number[];
}

export interface DeleteImagesInput {
  ids: number[];
}
export type CreatePathResponse = PathEntity;
export type UpdatePathResponse = PathEntity;
export type DeleteManyPathsResponse = {
  deleted: number[];
  in_use: number[];
};
export type DeleteImagesResponse = {
  deleted: number[];
  in_use: number[];
};
export interface RetrieveMyInformationResponse {
  user: UserEntity;
  time_zone: string;
  is_admin: boolean;
  account_status: AccountStatusEnum;
  plan: PlanTypeEnum;
}

export interface GetAllUserQueryResult {
  id: string;
  email: string;
  username: string;
  time_zone: string;
  is_admin: boolean;
  is_blocked: boolean;
  recent_signin_at: Date;
}

export interface GetAllUserQueryResponse {
  count: number;
  next?: any;
  previous?: any;
  results: GetAllUserQueryResult[];
}

export interface GetAllUserQueryParams {
  page?: number;
}

export interface UserStatusDetailForAdminPageActivity {
  name: string;
  count: number;
}

export interface UserStatusDetailForAdminPageMessage {
  project: string;
  role: string;
  activities: UserStatusDetailForAdminPageActivity[];
}

export interface GetUserStatusDetailForAdminPageResponse {
  result: boolean;
  message: UserStatusDetailForAdminPageMessage[];
  code: number;
}

export interface GetUserStatusDetailForAdminPageParams {
  id: string;
}

export type UploadImageMutationInput = FormData;

export interface UploadImageMutationResponse extends ImageEntity {}

export interface ProjectsQueryParams {
  sort?: ProjectsQuerySortType;
  search?: string;
  label?: string;
  public?: boolean;
}

export type ProjectsQuerySortType = 'name' | 'recent_activity' | 'role';
export enum TypeModuleImageEnum {
  IMAGE = 'IMAGE',
  TEST = 'TEST',
}

export interface GetPathInfoQueryParams {
  id: string | number;
}

export interface MovePathsInput {
  from: number[];
  to: number;
}

export type MovePathsResponse = APIErrorResponse;
export interface MoveImagesInput {
  ids: number[];
  path: number;
}

export type MoveImagesResponse = APIErrorResponse;
export type GetImageCountInPathResponse = {
  total_images_count: number;
};
export interface GetImageCountInPathInput {
  id: number | string;
  filter?: boolean;
  labels?: string[];
}
export interface DetailDiskUsageOfUserDisksAimodel {
  name: string;
  size: number;
}

export interface DetailDiskUsageOfUsersDiskProject {
  id: number;
  name: string;
  image_count: number;
  image_size: number;
  aimodels: DetailDiskUsageOfUserDisksAimodel[];
}

export interface DetailDiskUsageOfUsersDiskResult {
  id: number;
  username: string;
  total_size: number;
  used_size: number;
  projects: DetailDiskUsageOfUsersDiskProject[];
}

export interface GetDetailDiskUsageOfUsersGpuAimodel {
  name: string;
  training_running_time: string;
  test_running_time: string;
}

export interface GetDetailDiskUsageOfUsersGpuProject {
  id: number;
  name: string;
  aimodels: GetDetailDiskUsageOfUsersGpuAimodel[];
}

export interface GetDetailDiskUsageOfUsersGpuResult {
  id: number;
  username: string;
  projects: GetDetailDiskUsageOfUsersGpuProject[];
}

export interface DetailDiskUsageOfUsersResponse {
  count: number;
  next?: any;
  previous?: any;
  results: DetailDiskUsageOfUsersDiskResult[] & GetDetailDiskUsageOfUsersGpuResult[];
}

export interface DetailDiskUsageOfUsersInput {
  type: string;
}

export interface GetSystemDiskUsageServerTotal {
  total_disk_usage: number;
  image_data: number;
  aimodel_data: number;
  available: number;
}

export interface GetSystemDiskUsageUser {
  id: number;
  username: string;
  usage: number;
}

export interface GetSystemDiskUsageUsersUsageUsers {
  available: number;
  users: GetSystemDiskUsageUser[];
}

export interface GetSystemDiskUsageUsersUsageType {
  available: number;
  image_size: number;
  aimodel_size: number;
}

export interface GetSystemDiskUsageProject {
  id: number;
  name: string;
  usage: number;
}

export interface GetSystemDiskUsageUsersUsageProject {
  available: number;
  projects: GetSystemDiskUsageProject[];
}

export interface GetSystemDiskUsageResponse {
  server_total: GetSystemDiskUsageServerTotal;
  users_usage?: GetSystemDiskUsageUsersUsageUsers;
  types_usage?: GetSystemDiskUsageUsersUsageType;
  project_usage?: GetSystemDiskUsageUsersUsageProject;
}

export interface GetSystemDiskUsageInput {
  type: string;
}

export interface GetDetailDiskUsageOfUserProject {
  id: number;
  name: string;
  image_count: number;
  image_size: number;
  aimodel_count: number;
  aimodel_size: number;
}

export interface GetDetailDiskUsageOfUserResponse {
  projects: GetDetailDiskUsageOfUserProject[];
}

export interface GetDetailDiskUsageOfUserInput {
  user_id: string;
}

export interface GetDetailDiskUsageOfTypesAimodel {
  name: string;
  size: number;
}

export interface GetDetailDiskUsageOfTypesProjectAimodel {
  id: number;
  name: string;
  total_aimodels_usage: number;
  aimodels: GetDetailDiskUsageOfTypesAimodel[];
}

export interface GetDetailDiskUsageOfTypesProjectImage {
  image_count: number;
  original_image_size: number;
  crop_image_size: number;
  preview_image_size: number;
  thumnail_image_size: number;
  id?: number;
  name: string;
}

export interface GetDetailDiskUsageOfTypesResponse {
  projects: GetDetailDiskUsageOfTypesProjectImage[] & GetDetailDiskUsageOfTypesProjectAimodel[];
}

export interface GetDetailDiskUsageOfTypesInput {
  type: string;
}

export interface SetOrUnsetUserToAdminForAdminPageResponse {
  result: boolean;
  message: string;
  code: number;
}

export interface SetOrUnsetUserToAdminForAdminPageInput {
  id: string;
  set_admin: boolean;
}
export interface BlockOrUnblockUserForAdminPageResponse {
  result: boolean;
  message: string;
  code: number;
}

export interface BlockOrUnblockUserForAdminPageInput {
  id: string;
  block: boolean;
}

export interface GetSignUpForMonth {
  email: string;
  username: string;
  role: string;
  signup_at: string;
  signin_count: number;
  signin_fail_count: number;
  recent_signin_at: string;
}

export interface GetSignUpForMonthResponse {
  current_month: GetSignUpForMonth[];
  '1_month_ago': GetSignUpForMonth[];
  '2_month_ago': GetSignUpForMonth[];
  '3_month_ago': GetSignUpForMonth[];
  '4_month_ago': GetSignUpForMonth[];
  '5_month_ago': GetSignUpForMonth[];
  '6_month_ago': GetSignUpForMonth[];
  '7_month_ago': GetSignUpForMonth[];
  '8_month_ago': GetSignUpForMonth[];
  '9_month_ago': GetSignUpForMonth[];
  '10_month_ago': GetSignUpForMonth[];
  '11_month_ago': GetSignUpForMonth[];
}
export interface GetDetailDiskUsageOfProjectsAimodel {
  name: string;
  size: number;
}

export interface GetDetailDiskUsageOfProjectsResponse {
  id: number;
  name: string;
  image_count: number;
  original_image_size: number;
  crop_image_size: number;
  preview_image_size: number;
  thumnail_image_size: number;
  aimodels: GetDetailDiskUsageOfProjectsAimodel[];
}

export interface GetDetailDiskUsageOfProjectsInput {
  project_id: number;
}

export interface DeleteUserForAdminPageResponse {
  result: boolean;
  message: string;
  code: number;
}

export interface DeleteAccountResponse {
  result: boolean;
  message: string;
  code: number;
}

export interface DeleteUserForAdminPageInput {
  id: string;
}

export interface DeleteAccountInput {
  id: number;
}

export interface GetUserDiskUsageUsageTotal {
  total_disk_usage: number;
  image_data: number;
  aimodel_data: number;
  available: number;
}

export interface GetUserDiskUsageTypesUsage {
  available: number;
  image_size: number;
  aimodel_size: number;
}

export interface GetUserDiskUsageProjectsUsage {
  available: number;
  projects: {
    id: number;
    name: string;
    usage: number;
  }[];
}

export interface GetUserDiskUsageResponse {
  usage_total: GetUserDiskUsageUsageTotal;
  types_usage?: GetUserDiskUsageTypesUsage;
  projects_usage?: GetUserDiskUsageProjectsUsage;
}

export interface GetUserDiskUsageInput {
  id: any;
  type: string;
}

export interface UsersUsageDiskGetUsageDiskTypeDetailProject {
  image_count: number;
  total_images_usage: number;
  original_image_size: number;
  crop_image_size: number;
  preview_image_size: number;
  thumnail_image_size: number;
  id?: number;
  name: string;
  crop_image_count: number;
  original_image_count: number;
  thumbnail_image_count: number;
  thumbnail_image_size: number;
}

export interface UsersUsageDiskGetUsageDiskTypeDetailAimodel {
  name: string;
  size: number;
}

export interface UsersUsageDiskGetUsageDiskTypeDetailProjectAI {
  id: number;
  name: string;
  total_aimodels_usage: number;
  aimodels: UsersUsageDiskGetUsageDiskTypeDetailAimodel[];
}

export interface UsersUsageDiskGetUsageDiskTypeDetailResponse {
  projects: UsersUsageDiskGetUsageDiskTypeDetailProjectAI[] & UsersUsageDiskGetUsageDiskTypeDetailProject[];
}

export interface UsersUsageDiskGetUsageDiskTypeDetailInput {
  type: string;
  id: any;
}

export interface ProjectsUsageReadResponse {
  id: number;
  name: string;
  task_total_count: number;
  task_completed_count: number;
  job_total_count: number;
  job_completed_count: number;
  image_total_count: number;
  image_annotated_count: number;
  training_total_count: number;
  training_completed_count: number;
  testing_total_count: number;
  testing_completed_count: number;
  user_count: number;
  used_disk_size: number;
  total_disk_size: number;
  total_gpu_usage: string;
}

export interface UsersUsageGpuReadResponse {
  [K: string]: string;
}

export interface UsersUsageGpuReadInput {
  type: any;
  id: any;
  page: number;
}

export interface UsersUsageGpuProjectReadResponse {
  id: number;
  name: string;
  gpu_usage: string;
}

export interface UsersUsageGpuProjectReadInput {
  offset: number;
  id: any;
  type: string;
}

export interface UsersUsageDiskGetUsageDiskProjectDetailResponse {
  id: number;
  name: string;
  image_count: number;
  toal_image_usage: number;
  original_image_size: number;
  crop_image_size: number;
  preview_image_size: number;
  thumnbail_image_size: number;
  total_aimodels_usage: number;
  aimodels: {
    name: string;
    size: number;
  }[];
}

export interface UsersUsageDiskGetUsageDiskProjectDetailInput {
  project_id: string;
  id: any;
}

export interface ServerUsageGpuInput {
  type: string;
  page: number;
}

export enum PlanTypeEnum {
  PLAN_FREE = 'PLAN_FREE',
  PLAN_BASIC = 'PLAN_BASIC',
  PLAN_PRO = 'PLAN_PRO',
}

export interface GetUserQuotaResponse {
  DATA_STORAGE_MAX: number;
  DATA_STORAGE_USER: number;
  IMAGE_COUNT_MAX: number;
  IMAGE_COUNT_USER: number;
  GPU_TIME_MAX: number;
  GPU_TIME_USER: number;
  EXTRA_GPU_TIME: number;
  EXTRA_GPU_TIME_MAX: number;
  MODEL_COUNT_MAX: number;
  MODEL_COUNT_USER: number;
  NUMBER_OF_CLASS_MAX: number;
  NUMBER_OF_COLLABORATOR_MAX: number;
  NUMBER_OF_SIMULTANEOUS_TRAINING_MAX: number;
  NUMBER_OF_SIMULTANEOUS_TRAINING_USER: number;
}

export interface GetUserQuotaParams {
  id?: number | string;
}

export enum CompletedFlagEnum {
  N = 'N',
  E = 'E',
  G = 'G',
}
