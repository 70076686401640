import React, { useState } from 'react';
import { Input, Form, Button } from 'antd';
import { useUILogin } from '../context/modal';
import { RULE_EMAIL } from '../helper/validate';
import { useConfirm, useNavigation } from 'src/hooks';
import useResetPasswordMutation from 'src/services/mutations/useResetPasswordMutation';
import { ResetPasswordInput } from 'src/types';
import { EnvelopeSVGP } from 'src/components/icons';
import '../styles/modal.less';

const ForgotPassword: React.FC = () => {
  const [form] = Form.useForm<ResetPasswordInput>();
  const { closeModal } = useUILogin();
  const navigation = useNavigation();
  const { confirm } = useConfirm();
  const [fields, setFields] = useState<ResetPasswordInput>({
    email: '',
  });

  const { mutate, isLoading } = useResetPasswordMutation({
    onError: ({ response }) => {
      handleClose();
      confirm(
        <div className="text-center">
          {response?.data.code === 404 ? 'Email does not exist. Please try again.' : response?.data.message}
        </div>,
      );
    },
    onSuccess: () => {
      handleClose();
      confirm(<div className="text-center text-#00BEEB">Success</div>);
    },
  });

  const handleClose = () => {
    closeModal();
    navigation.reset();
  };

  return (
    <div className="modal-register">
      <div className="mb-32px text-36px text-center text-#707070">Forgot Your Password?</div>
      <span className="text-20px text-#00BEEB">E-mail</span>
      <Form
        form={form}
        onFinish={mutate}
        className="form-custom"
        validateTrigger={false}
        onValuesChange={(_, allValues) => {
          setFields(allValues);
        }}
      >
        <Form.Item name="email" rules={RULE_EMAIL} className="mb-10px">
          <Input
            prefix={<EnvelopeSVGP />}
            placeholder="example@example.com"
            className={`mb-14px ${fields?.email?.length > 0 ? 'selected' : ''}`}
            bordered={false}
          />
        </Form.Item>
        <Form.Item className="text-right">
          <div className="space-x-2">
            <Button htmlType="button" type="text" className="text-black" onClick={handleClose}>
              Return to Sign In
            </Button>
            <Button loading={isLoading} htmlType="submit" shape="round" type="primary">
              Confirm
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPassword;
