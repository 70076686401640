import { ArrowLeft } from 'akar-icons';
import { Input, Spin } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CarretDownSVG, CloseSVG, FolderFillSVG, MoveDirectionSVG, UploadFolderSVG } from 'src/assets/icons';
import { AppModal, Button } from 'src/components';
import { FolderData, ImageData } from 'src/pages/Images/List';
import { useGetPathInfoLazyQuery } from 'src/services/queries/useGetPathInfo';
import { nanoid } from 'nanoid';
import useCreatePathMutation from 'src/services/mutations/useCreatePathMutation';
import useCurrentProject from 'src/hooks/useCurrentProject';
import { PathEntity } from 'src/types/path';
import useMoveImagesMutation from 'src/services/mutations/useMoveImagesMutation';
import { showUnknowErrorMessage } from 'src/utils';
import useMovePathsMutation from 'src/services/mutations/useMovePathsMutation';

export interface MoveButtonProps {
  onCreateNewFolder: (folder: FolderData) => void;
  selectedItems: {
    images: ImageData[];
    folders: FolderData[];
  };
  onCompleteMoveImages: () => void;
  onCompleteMovePaths: () => void;
}

export default function MoveButton({
  selectedItems,
  onCreateNewFolder,
  onCompleteMoveImages,
  onCompleteMovePaths,
}: MoveButtonProps) {
  const { pathId } = useParams<{ pathId: string }>();
  const [currentPathId, setCurrentPathId] = useState<string>();

  const [visible, setVisible] = useState(false);

  const [folders, setFolders] = useState<FolderData[]>([]);

  const [data, setData] = useState<PathEntity>();

  const [selected, setSelected] = useState<FolderData>();

  const { mutate, isLoading } = useGetPathInfoLazyQuery({
    onSuccess: setData,
    onError: (e) => showUnknowErrorMessage(e.message),
  });

  useEffect(() => {
    setFolders(
      data?.children
        .filter((f) => !selectedItems.folders.find((folder) => folder.entity?.id === f.id))
        .map((f) => ({
          entity: f,
          name: f.name,
        })) || [],
    );
  }, [data, selectedItems]);

  useEffect(() => {
    visible &&
      mutate({
        id: currentPathId || pathId,
      });
  }, [pathId, currentPathId, mutate, visible]);

  useEffect(() => {
    setSelected(undefined);
  }, [currentPathId]);

  useEffect(() => {
    if (!visible) {
      setSelected(undefined);
      setCurrentPathId(undefined);
      setData(undefined);
    }
  }, [visible]);

  const { data: projectData } = useCurrentProject();

  const total = selectedItems.images.length + selectedItems.folders.length;

  function handleCreateNewFolder(folder: FolderData) {
    if (folder.entity && data) {
      setData({
        ...data,
        children: [...(data?.children || []), { ...folder.entity, id: +folder.entity.id }],
      });
    }

    if (!currentPathId || currentPathId === pathId) {
      onCreateNewFolder({ ...folder, isDraft: false });
    }

    // mutate({
    //   id: currentPathId || pathId,
    // });
  }

  const { mutate: moveImages, isLoading: moveImagesLoading } = useMoveImagesMutation({
    onError: (res) => showUnknowErrorMessage(res.message),
    onSuccess: () => {
      onCompleteMoveImages();
      setSelected(undefined);
      setVisible(false);
    },
  });

  const { mutate: movePaths, isLoading: movePathsLoading } = useMovePathsMutation({
    onError: (res) => showUnknowErrorMessage(res.message),
    onSuccess: () => {
      onCompleteMovePaths();
      setSelected(undefined);
      setVisible(false);
    },
  });

  function handleMove() {
    const imageIds = selectedItems.images.map((i) => i.entity.id);
    if ((selected?.entity?.id || +(currentPathId || 0) || projectData?.root_path) === +pathId) {
      return;
    }

    imageIds.length &&
      moveImages({
        ids: imageIds,
        path: selected?.entity?.id || +(currentPathId || 0) || projectData?.root_path || 0,
      });

    const pathIds = selectedItems.folders.map((i) => i.entity?.id || 0);
    pathIds.length &&
      movePaths({
        from: pathIds,
        to: selected?.entity?.id || +(currentPathId || 0) || projectData?.root_path || 0,
      });
  }

  return (
    <div>
      <Button type="_FFFFFF" className="rounded-full" disabled={!total} onClick={() => setVisible(true)}>
        <div className="flex space-x-10px">
          <MoveDirectionSVG className="w-4" />
          <span className="text-12px text-inherit font-normal">MOVE</span>
        </div>
      </Button>
      <AppModal
        header={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={550}
        style={{ minWidth: 550 }}
        bodyStyle={{ padding: 0, borderRadius: 0 }}
        wrapClassName="border-none"
      >
        <div className="p-30px">
          <div className="relative flex justify-between items-start">
            <span className="text-16px font-medium">
              Move {total} item{total > 1 ? 's' : ''} to...
            </span>
            <CloseSVG className="cursor-pointer" onClick={() => setVisible(false)} />
          </div>
          <div className="flex space-x-10px items-center mt-30px">
            {+(currentPathId || pathId) !== projectData?.root_path && (
              <ArrowLeft
                className="w-5 cursor-pointer hover:text-#155F70 select-none"
                onClick={() => setCurrentPathId(data?.parent_id?.toString())}
              />
            )}
            <span className="font-light text-26px text-#00BEEB">
              {projectData?.root_path === data?.id ? projectData?.name : data?.name}
            </span>
          </div>
          <div className="h-440px overflow-auto pl-20px py-4 pr-30px border border-#DDDDDD mt-20px">
            {!isLoading && !folders.length && <div className="text-center mt-24">There are no folder to select</div>}
            <Spin spinning={isLoading}>
              {folders.map((item, index) => (
                <Fragment key={item.entity?.id || item.draftId}>
                  <FolderCard
                    selected={selected}
                    item={item}
                    onSelect={(item) =>
                      setSelected((selected) => (selected?.entity?.id === item.entity?.id ? undefined : item))
                    }
                    onOpenPath={() => !item.isDraft && setCurrentPathId(item.entity?.id.toString())}
                  />
                  <hr className="my-1 border-#DDDDDD" />
                </Fragment>
              ))}
            </Spin>
          </div>
          <div className="mt-30px flex justify-between">
            <NewFolderButton onCreateFolder={handleCreateNewFolder} currentPathId={currentPathId || pathId} />
            {
              <Button
                type="_42DABF"
                className="rounded-full"
                onClick={handleMove}
                loading={movePathsLoading || moveImagesLoading}
                disabled={
                  movePathsLoading ||
                  moveImagesLoading ||
                  (selected &&
                    [
                      ...selectedItems.images.map((s) => s.entity.path),
                      ...selectedItems.folders.map((s) => s.entity?.parent_id),
                    ].includes(selected.entity?.id)) ||
                  (!selected &&
                    +(currentPathId || pathId) !== projectData?.root_path &&
                    !![
                      ...selectedItems.images.map((s) => s.entity.path),
                      ...selectedItems.folders.map((s) => s.entity?.parent_id),
                    ].find((p) => p !== projectData?.root_path))
                }
              >
                <span className="text-12px text-black font-normal">Move</span>
              </Button>
            }
          </div>
        </div>
      </AppModal>
    </div>
  );
}

interface FolderCardProps {
  selected?: FolderData;
  item: FolderData;
  onSelect?: (val: FolderData) => void;
  onOpenPath: () => void;
}

function FolderCard({ selected, item, onSelect, onOpenPath }: FolderCardProps) {
  return (
    <div
      className={`px-3 py-2 flex items-center cursor-pointer select-none ${
        selected?.entity?.id === item?.entity?.id ? 'bg-#42DABF' : ''
      }`}
      onClick={() => onSelect && onSelect(item)}
      onDoubleClick={onOpenPath}
    >
      <FolderFillSVG className="w-35px text-#1F94ED" />
      <span
        className={`text-16px ml-30px font-light ${
          selected?.entity?.id === item?.entity?.id ? 'text-black' : 'text-#707070'
        }`}
      >
        {item.name}
      </span>
      {selected?.entity?.id === item.entity?.id && (
        <div className="ml-auto flex-center w-30px h-30px rounded-full" onClick={onOpenPath}>
          <CarretDownSVG className="w-4 h-5 text-black hover:text-#155F70 transform -rotate-90 cursor-pointer select-none" />
        </div>
      )}
    </div>
  );
}

interface NewFolderButtonProps {
  onCreateFolder: (folder: FolderData) => void;
  currentPathId: number | string;
}

function NewFolderButton({ onCreateFolder, currentPathId }: NewFolderButtonProps) {
  const [visible, setVisible] = useState(false);
  const [folderName, setFolderName] = useState('');

  useEffect(() => {
    if (!visible) {
      setFolderName('');
    }
  }, [visible]);

  const { mutate, isLoading } = useCreatePathMutation();

  function handleCreateNewFolder() {
    mutate(
      {
        name: folderName,
        parent: +currentPathId,
      },
      {
        onSuccess: (res) => {
          setVisible(false);
          onCreateFolder({ isDraft: false, draftId: nanoid(), name: folderName, entity: res, selected: false });
          setFolderName('');
        },
      },
    );
  }

  return (
    <div>
      <Button type="_888888" className="w-35px h-35px rounded-full px-0 pt-2" onClick={() => setVisible(!visible)}>
        <UploadFolderSVG className="w-20px text-#FFFFFF" />
      </Button>
      <AppModal
        header={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={400}
        bodyStyle={{ padding: 0 }}
        wrapClassName="border-none"
      >
        <div className="p-30px flex flex-col">
          <div className="relative flex justify-end">
            <CloseSVG className="cursor-pointer" onClick={() => setVisible(false)} />
          </div>
          <div className="flex space-x-20px items-center">
            <FolderFillSVG className="w-35px text-#1F94ED" />
            <span className="font-light text-16px">Create Folder</span>
          </div>

          <div className="flex space-x-8 items-center mt-4">
            <span className="text-16px font-medium text-#707070">NAME</span>
            <div className="border-b ml-30px flex-grow">
              <Input
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder="Folder name"
                bordered={false}
                className="text-16px font-light px-0 placeholder-italic"
              />
            </div>
          </div>
          <div className="ml-auto flex flex-end space-x-4 mt-40px">
            <Button
              type="_FFFFFF"
              className="h-30px px-8 rounded-full flex-center"
              style={{ paddingTop: 2 }}
              onClick={() => setVisible(false)}
              disabled={isLoading}
            >
              <span className="text-10px font-normal text-inherit">CANCEL</span>
            </Button>
            <Button
              type="primary"
              className="h-30px px-8 rounded-full flex-center"
              style={{ paddingTop: 2 }}
              disabled={!folderName || isLoading}
              loading={isLoading}
              onClick={handleCreateNewFolder}
            >
              <span className="text-10px font-normal text-inherit">CREATE</span>
            </Button>
          </div>
        </div>
      </AppModal>
    </div>
  );
}
