import { useLocation } from 'react-router-dom';
import qs from 'qs';

export default function useQueryString<T>() {
  const location = useLocation();

  return (qs.parse(location.search, { ignoreQueryPrefix: true, comma: true }) as unknown) as {
    [K in keyof T]: T[K];
  };
}
