import { atom } from 'recoil';
import { ImageEntity } from 'src/types/image';
import { PathEntity } from 'src/types/path';

export const uploadedPaths = atom<UploadedPathEntity | undefined>({
  key: 'uploadedPaths',
  default: undefined,
});

export interface UploadedPathEntity {
  parentId: number;
  path: PathEntity;
}

export interface UploadedImageEntity {
  parentId: number;
  image: ImageEntity;
}

export const uploadedImages = atom<UploadedImageEntity | undefined>({
  key: 'uploadedImages',
  default: undefined,
});

export const selectedAnnotationItems = atom<{ paths: number[]; images: number[] }>({
  key: 'selectedAnnotationItems',
  default: {
    paths: [],
    images: [],
  },
});

// export const selectedProjectItems = atom<{ paths: number[]; images: number[] }>({
//   key: 'selectedProjectItems',
//   default: {
//     paths: [],
//     images: [],
//   },
// });

export const activeChangePlanModal = atom<boolean>({
  key: 'activeChangePlanModal',
  default: false,
});
