import React, { useEffect, useState } from 'react';
import { GridViewSVG, ListViewSVG } from 'src/assets/icons';

export interface ListTypeProps {
  value: 'GRID' | 'LIST';
  onChange: (type?: 'GRID' | 'LIST') => void;
}

export default function ListType({ value, onChange }: ListTypeProps) {
  const [type, setType] = useState<'GRID' | 'LIST'>(value);

  useEffect(() => {
    onChange(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <div className="flex">
      <div
        className={`w-32px h-32px flex-center cursor-pointer ${
          type === 'GRID' ? 'bg-#888888 text-white' : 'text-#888888'
        } transition-all`}
        onClick={() => setType('GRID')}
      >
        <GridViewSVG className="w-3 h-3 text-inherit" />
      </div>
      <div
        className={`w-32px h-32px flex-center cursor-pointer transition-all ${
          type === 'LIST' ? 'bg-#888888 text-white' : 'text-#888888'
        }`}
        onClick={() => setType('LIST')}
      >
        <ListViewSVG className="w-3 h-3 text-inherit" />
      </div>
    </div>
  );
}
