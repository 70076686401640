import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { APIErrorResponse, RegisterInput, RegisterResponse } from 'src/types';
import { AuthEndpoint } from '../endpoints';

export type RegisterMutationOptions = UseMutationOptions<RegisterResponse, APIErrorResponse, RegisterInput>;

export default function useRegisterMutation(options?: RegisterMutationOptions) {
  return useMutation<RegisterResponse, APIErrorResponse, RegisterInput>(
    (values) => request.post(AuthEndpoint.register, values).then((res) => res.data),
    options,
  );
}
