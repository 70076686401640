export default {
  required: 'Required',
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    range: 'Please input ${min} - ${max} characters',
  },
  types: {
    email: 'Invalid email',
  },
};
