interface Mime {
  mime: string;
  pattern: number[];
  mask: number[];
}

export default function isFileValid(file: File): Promise<boolean> {
  return new Promise((res, rej) => {
    const mimes: Mime[] = [
      {
        mime: 'image/jpeg',
        pattern: [0xff, 0xd8, 0xff],
        mask: [0xff, 0xff, 0xff],
      },
      {
        mime: 'image/jpg',
        pattern: [0xff, 0xd8, 0xff],
        mask: [0xff, 0xff, 0xff],
      },
      {
        mime: 'image/png',
        pattern: [0x89, 0x50, 0x4e, 0x47],
        mask: [0xff, 0xff, 0xff, 0xff],
      },
      {
        mime: 'image/bmp',
        pattern: [0x42, 0x4d],
        mask: [0xff, 0xff],
      },
      {
        mime: 'image/webp',
        pattern: [0x52, 0x49, 0x46, 0x46],
        mask: [0xff, 0xff, 0xff, 0xff],
      },
      {
        mime: 'image/tiff',
        pattern: [0x49, 0x49, 0x2a],
        mask: [0xff, 0xff, 0xff],
      },
      {
        mime: 'image/tif',
        pattern: [0x4d, 0x4d],
        mask: [0xff, 0xff],
      },
      // https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    ];

    function check(bytes: Uint8Array, mime: Mime) {
      for (let i = 0, l = mime.mask.length; i < l; ++i) {
        if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
          return false;
        }
      }
      return true;
    }

    const blob = file.slice(0, 4); //read the first 4 bytes of the file

    const reader = new FileReader();
    reader.onloadend = function (e) {
      if (e.target?.readyState === FileReader.DONE) {
        if (e.target?.result) {
          const bytes = new Uint8Array(e.target.result as any);

          for (let i = 0, l = mimes.length; i < l; ++i) {
            if (check(bytes, mimes[i])) return res(true);
          }

          return res(false);
        }

        return rej(false);
      }
    };
    reader.readAsArrayBuffer(blob);
  });
  //List of known mimes
}
