import { atom, useResetRecoilState } from 'recoil';
import { CreateAnnotationTaskMutationInput, ProjectsEntity } from 'src/types';
import { PathEntity } from 'src/types/path';

export const createTestInput = atom<CreateAnnotationTaskMutationInput | undefined>({
  key: 'createTestInput',
  default: undefined,
});

export interface FolderSelect {
  project: ProjectsEntity;
  selected?: boolean;
  count?: number;
  path?: PathEntity;
  images?: number[];
  dirs?: number[];
  labels?: string[];
}

export const selectedPathForTest = atom<FolderSelect[]>({
  key: 'selectedPathForTest',
  default: [],
});

export const initSelectedTestData = atom<boolean>({
  key: 'initSelectedTestData',
  default: false,
});

export const correctTestRoute = atom<boolean>({
  key: 'correctTestRoute',
  default: false,
});

export function useResetTest() {
  const resetCreateTestInput = useResetRecoilState(createTestInput);
  const resetSelectedPathForTest = useResetRecoilState(selectedPathForTest);
  const resetInit = useResetRecoilState(initSelectedTestData);
  const resetRoute = useResetRecoilState(correctTestRoute);

  return () => {
    resetCreateTestInput();
    resetSelectedPathForTest();
    resetInit();
    resetRoute();
  };
}

export const updatingStateTest = atom<boolean>({
  key: 'updatingStateTest',
  default: false,
});
