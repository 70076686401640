import ImagesDetail from './Images/Detail';
import Login from './Login';

import { ProgressLoading } from 'src/components';
import React, { Suspense } from 'react';
import { lazy } from 'react';
import OrderGPU from 'src/pages/MyPage/OrderGPU';

const LazyLayout = (importStatement: () => Promise<any>) => {
  const Component = lazy(importStatement);

  return (
    <Suspense fallback={<ProgressLoading />}>
      <Component />
    </Suspense>
  );
};

export default {
  404: lazy(() => import('./Error/404')),
  500: lazy(() => import('./Error/500')),

  Discover: {
    Home: () => LazyLayout(() => import('./Discover/Home')),
    Plan: () => LazyLayout(() => import('./Discover/Plan')),
    Product: () => LazyLayout(() => import('./Discover/Product')),
    Support: () => LazyLayout(() => import('./Discover/Support')),
  },

  Login: () => <Login />,
  About: () => LazyLayout(() => import('./About')),
  ProjectList: () => LazyLayout(() => import('./Projects/ProjectList')),
  ProjectDashboards: () => LazyLayout(() => import('./Projects/ProjectDashBoards')),

  Home: () => LazyLayout(() => import('./Home')),

  ImageIndex: () => LazyLayout(() => import('./Images')),
  Images: () => LazyLayout(() => import('./Images/List')),
  ImagesDetail: () => <ImagesDetail />,

  Annotation: {
    NewTask: {
      SelectProject: () => LazyLayout(() => import('./Annotation/CreateTask/SelectProject')),
      SelectImage: () => LazyLayout(() => import('./Annotation/CreateTask/SelectImage')),
      ImageDetail: () => LazyLayout(() => import('./Annotation/CreateTask/Detail')),
    },
    TaskList: () => LazyLayout(() => import('./Annotation/TaskList')),
    JobList: () => LazyLayout(() => import('./Annotation/Job/job-list')),
    JobAiModelList: () => LazyLayout(() => import('./Annotation/Job/job-aimodel-list')),
  },
  Training: {
    NewClasses: {
      SelectProject: () => LazyLayout(() => import('./Training/CreateClasses/SelectProject')),
      SelectImage: () => LazyLayout(() => import('./Training/CreateClasses/SelectImage')),
    },
    listAiModel: () => LazyLayout(() => import('./Training/AiModel')),
    listClass: () => LazyLayout(() => import('./Training/Class')),
    setting: () => LazyLayout(() => import('./Training/Setting')),
    // matrix: () => LazyLayout(() => import('./Training/Matrix')),
    Result: () => LazyLayout(() => import('./Training/Result')),
    DetailView: () => LazyLayout(() => import('./Training/ImageDetailView')),
    Progress: () => LazyLayout(() => import('./Training/TrainingProgressChart')),
    ViewImage: () => LazyLayout(() => import('./Training/CreateClasses/ViewImageDetail')),
  },
  Tests: () => LazyLayout(() => import('./Test/List')),
  TestsClasses: {
    List: () => LazyLayout(() => import('./Test/Class')),
  },
  TestMethod: {
    SelectProject: () => LazyLayout(() => import('src/modules/DataSelected/SelectProject')),
    SelectImage: () => LazyLayout(() => import('src/modules/DataSelected/SelectImage')),
    ImagesDetail: () => LazyLayout(() => import('src/modules/DataSelected/ImageDetail')),
  },

  MyPage: {
    Statistics: () => LazyLayout(() => import('./MyPage/Statistics')),
    Account: () => LazyLayout(() => import('./MyPage/Account')),
    NewAdmin: () => LazyLayout(() => import('./MyPage/NewAdminPage')),
    Plan: () => LazyLayout(() => import('./MyPage/Plan')),
    Billing: () => LazyLayout(() => import('./MyPage/Billing')),
    OrderGPU: () => <OrderGPU />,
  },

  Results: () => LazyLayout(() => import('./Results')),
  ResultsDetail: () => LazyLayout(() => import('./Results/Details')),
};
