import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks';
import { FilterSVG } from 'src/assets/icons';

export interface AnnotatedFilterDropdownProps {
  onAnnotatedFilterChange: (val?: boolean) => void;
  annotated?: boolean;
}

export default function AnnotatedFilterDropdown({ onAnnotatedFilterChange, annotated }: AnnotatedFilterDropdownProps) {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState<'Unannotated' | 'Annotated' | undefined>(
    annotated === true || annotated === false ? (annotated === false ? 'Unannotated' : 'Annotated') : undefined,
  );

  useEffect(() => {
    onAnnotatedFilterChange(value === 'Annotated' ? true : value === 'Unannotated' ? false : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => visible && setVisible(false));

  return (
    <div className="relative select-none" ref={containerRef}>
      <div
        className="flex items-center text-#888888 justify-between py-10px px-4 w-120px cursor-pointer"
        onClick={() => setVisible(!visible)}
      >
        <span className="text-12px font-medium ">{value || 'Filter'}</span>
        <FilterSVG className="w-2" />
      </div>
      <AnimatePresence>
        {visible && (
          <motion.div
            key="popup"
            exit={{ opacity: 0 }}
            className="absolute bottom-0 right-0 w-full py-1 flex flex-col bg-white shadow-drop transform translate-y-full"
          >
            <div
              className="text-12px cursor-pointer hover:bg-#F5F5F5 px-4 py-1"
              onClick={() => {
                setValue(undefined);
                setVisible(false);
              }}
            >
              ALL
            </div>
            <div
              className="text-12px cursor-pointer hover:bg-#F5F5F5 px-4 py-1"
              onClick={() => {
                setVisible(false);
                setValue('Annotated');
              }}
            >
              Annotated
            </div>
            <div
              className="text-12px cursor-pointer hover:bg-#F5F5F5 px-4 py-1"
              onClick={() => {
                setValue('Unannotated');
                setVisible(false);
              }}
            >
              Unannotated
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
