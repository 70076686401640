import { atom, useResetRecoilState } from 'recoil';
import { CreateAnnotationTaskMutationInput, ProjectsEntity } from 'src/types';
import { PathEntity } from 'src/types/path';

export const createTaskInput = atom<CreateAnnotationTaskMutationInput | undefined>({
  key: 'createTaskInput',
  default: undefined,
});

export interface FolderSelect {
  project: ProjectsEntity;
  selected?: boolean;
  count?: number;
  path?: PathEntity;
  images?: number[];
  dirs?: number[];
}

export const selectedPathForAnnotation = atom<FolderSelect[]>({
  key: 'selectedPathForAnnotation',
  default: [],
});

export const initSelectedAnnotationData = atom<boolean>({
  key: 'initSelectedAnnotationData',
  default: false,
});

export function useResetAnnotation() {
  const resetCreateTaskInput = useResetRecoilState(createTaskInput);
  const resetSelectedPathForAnnotation = useResetRecoilState(selectedPathForAnnotation);
  const resetInit = useResetRecoilState(initSelectedAnnotationData);

  return () => {
    resetCreateTaskInput();
    resetSelectedPathForAnnotation();
    resetInit();
  };
}

export const updatingState = atom<boolean>({
  key: 'updatingAnnotationState',
  default: false,
});
