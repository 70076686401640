import { AnimatePresence, motion } from 'framer-motion';
import { capitalize } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FilterSVG } from 'src/assets/icons';
import { useClickOutside } from 'src/hooks';
import { ImageFilterEnum } from 'src/pages/Images/List';
import CheckboxOutline from 'src/pages/Images/List/CheckboxOutline';

export interface ImageFilterDropdownProps {
  onFilter: (val: ImageFilterEnum[]) => void;
  filterValues: ImageFilterEnum[];
}

export default function ImageFilterDropdown({ filterValues, onFilter }: ImageFilterDropdownProps) {
  const [visible, setVisible] = useState(false);

  const [value, setValue] = useState<ImageFilterEnum[]>(filterValues);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onFilter(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useClickOutside(containerRef, () => visible && setVisible(false));

  return (
    <div className="relative select-none" ref={containerRef}>
      <div
        className="flex items-center text-#888888 justify-between py-10px px-4 w-130px cursor-pointer"
        onClick={() => setVisible(!visible)}
      >
        <span className="text-12px font-medium ">Filter</span>
        <FilterSVG className="w-2" />
      </div>
      <AnimatePresence>
        {visible && (
          <motion.div
            key="popup"
            exit={{ opacity: 0 }}
            className="absolute bottom-0 right-0 w-full py-1 flex flex-col bg-white shadow-drop transform translate-y-full"
          >
            {Object.keys(ImageFilterEnum).map((item) => (
              <div className="text-12px cursor-pointer hover:bg-#F5F5F5 py-1" key={item}>
                <CheckboxOutline
                  size="sm"
                  onCheckedChange={(checked) =>
                    checked
                      ? setValue([...value, ImageFilterEnum[item]])
                      : setValue(value.filter((val) => val !== item))
                  }
                  checked={value.includes(ImageFilterEnum[item])}
                  containerClassName="px-4"
                >
                  <div className="ml-2">{capitalize(item)}</div>
                </CheckboxOutline>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
