import React, { FC, useContext, useMemo, useReducer } from 'react';
import produce from 'immer';

export interface State {
  displayModal: boolean;
  modalView: string;
  modalAction: MODAL_ACTION;
  modalContent: MODAL_CONTENT;
}

const initialState = {
  displayModal: false,
  modalView: 'RESET_PASSWORD',
  modalAction: {
    width: 950,
    closable: false,
  },
  modalContent: {},
};

type Action =
  | {
      type: 'OPEN_MODAL';
    }
  | {
      type: 'CLOSE_MODAL';
    }
  | {
      type: 'SET_MODAL_VIEW';
      view: MODAL_VIEWS;
    }
  | {
      type: 'SET_MODAL_ACTION';
      action: MODAL_ACTION;
    }
  | {
      type: 'SET_MODAL_CONTENT';
      content: MODAL_CONTENT;
    };

type MODAL_VIEWS = 'RESET_PASSWORD' | 'FORGOT_PASSWORD' | 'ALMOST_THERE' | 'ACTIVATE';
type MODAL_ACTION = {
  width?: number;
  closable?: boolean;
};

type MODAL_CONTENT = {
  email?: string;
};
export const UILoginContext = React.createContext<State | any>(initialState);

UILoginContext.displayName = 'UILoginContext';

const loginReducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      draft.displayModal = true;
      break;
    }
    case 'CLOSE_MODAL': {
      draft.displayModal = false;
      break;
    }
    case 'SET_MODAL_VIEW': {
      draft.modalView = action.view;
      break;
    }
    case 'SET_MODAL_ACTION': {
      draft.modalAction = action.action;
      break;
    }
    case 'SET_MODAL_CONTENT': {
      draft.modalContent = action.content;
      break;
    }
  }
});

export const UILoginProvider: FC = (props) => {
  const [state, dispatch] = useReducer(loginReducer, initialState);

  const openModal = () => dispatch({ type: 'OPEN_MODAL' });
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });
  const setModalView = (view: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', view });
  const setModalAction = (action: MODAL_ACTION) => dispatch({ type: 'SET_MODAL_ACTION', action });
  const setModalContent = (content: MODAL_CONTENT) => dispatch({ type: 'SET_MODAL_CONTENT', content });

  const value = useMemo(() => ({ ...state, openModal, closeModal, setModalView, setModalAction, setModalContent }), [
    state,
  ]);
  return <UILoginContext.Provider value={value} {...props} />;
};

export const useUILogin = () => {
  const context = useContext(UILoginContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a LoginProvider`);
  }
  return context;
};

export const ManageUILoginContext: FC = ({ children }) => <UILoginProvider>{children}</UILoginProvider>;
