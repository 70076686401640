import { Link, NavLink, useParams } from 'react-router-dom';
import { useClickOutside } from 'src/hooks';
import { CarretDownSVG } from 'src/assets/icons';
import { Fragment, useRef, useState } from 'react';
import UserDropdown from './UserDropdown';
import { AppRoutes } from 'src/helpers';
import useCurrentProject from 'src/hooks/useCurrentProject';

export interface HeaderProps {}

export default function Header(props: HeaderProps) {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const detailContainerRef = useRef<HTMLDivElement>(null);

  useClickOutside(detailContainerRef, () => isDetailOpen && setIsDetailOpen(false));

  const { projectId } = useParams<{ projectId: string }>();

  const { data } = useCurrentProject();

  const NavList = [
    {
      label: 'Upload',
      href: AppRoutes.images.index(projectId),
    },
    {
      label: 'Annotate',
      href: AppRoutes.annotation.index(projectId),
    },
    {
      label: 'Train',
      href: AppRoutes.training.index(projectId),
    },
    {
      label: 'Test',
      href: AppRoutes.testsParam(projectId),
    },
  ];

  return (
    <Fragment>
      <div className="sticky top-0 left-0 w-full z-50" style={{ minWidth: 1280 }}>
        <div className="h-74px flex bg-white">
          <div className="w-82px flex-center flex-shrink-0">
            <Link to={AppRoutes.discover.home}>
              <img src="/v2/logo/Icon-x4.png" className="w-40px relative top-1" alt="logo" />
            </Link>
          </div>
          <div className="bg-#1C3F49 pl-40px pr-80px flex justify-between items-center flex-grow">
            <div className="flex items-center space-x-4">
              <Link to={AppRoutes.projectList}>
                <span className="text-36px font-medium text-white whitespace-nowrap">MY PROJECTS</span>
              </Link>
              {data && (
                <Fragment>
                  <span className="text-36px font-medium text-white">|</span>
                  <Link to={AppRoutes.projectDashboards(data.id)}>
                    <span className="text-36px font-medium text-white line-clamp-1">{data?.name}</span>
                  </Link>
                  <CarretDownSVG
                    className={`text-white w-6 cursor-pointer transform ${
                      isDetailOpen ? 'rotate-180' : 'rotate-0'
                    } transition-transform`}
                    onClick={() => setIsDetailOpen(!isDetailOpen)}
                  />
                </Fragment>
              )}
            </div>
            <div className="flex items-center">
              <div className="text-36px font-medium mr-30px text-white">
                <Link to={AppRoutes.discover.product} className="hover:text-white">
                  PRODUCTS
                </Link>
              </div>
              <div className="text-36px font-medium mr-30px text-white">
                <Link to={AppRoutes.discover.plan} className="hover:text-white">
                  PLANS
                </Link>
              </div>
              <div className="text-36px font-medium mr-74px text-white">
                <Link to={AppRoutes.discover.support} className="hover:text-white">
                  SUPPORT
                </Link>
              </div>
              <UserDropdown />
            </div>
          </div>
        </div>
        <div
          className={`bg-#EEEEEE ${isDetailOpen ? 'h-392px opacity-100' : 'h-0 opacity-0'} overflow-hidden`}
          style={{
            boxShadow: '0px 3px 10px #0000004D',
            transitionProperty: 'height opacity',
            transitionDuration: '0.3s',
          }}
          ref={detailContainerRef}
        >
          <div className="flex pl-162px pr-28 py-35px space-x-24px overflow-hidden">
            <div className="w-259px flex-shrink-0">
              <span className="text-12px font-medium leading-14px">LABEL</span>
              <hr className="mb-5" />
              <div className="overflow-y-auto" style={{ height: 300 }}>
                <span className="text-12px text-#888888 leading-20px break-words whitespace-pre-wrap">
                  {data?.labels.map((item: any, index: number) => item.name + (data.labels[index + 1] ? ', ' : ''))}
                </span>
              </div>
            </div>
            <div className="flex-grow">
              <span className="text-12px font-medium leading-14px">PROJECT DESCRIPTION</span>
              <hr className="mb-5" />
              <span className="text-12px text-#AAAAAA font-light leading-20px">{data?.description}</span>
            </div>
          </div>
        </div>
      </div>
      {data && (
        <div className="fixed top-74px left-0 w-full z-40" style={{ minWidth: 875 }}>
          <div className="h-65px bg-#E4E4E4 pl-140px">
            <ul className="flex m-0 h-full">
              {NavList.map((item) => (
                <li
                  key={item.label}
                  className="text-24px cursor-pointer hover:text-white hover:bg-#00BEEB mr-30px font-light"
                >
                  <NavLink
                    to={item.href}
                    className="text-inherit block px-20px h-full pt-4"
                    activeClassName="text-white bg-#00BEEB"
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
}
