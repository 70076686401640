import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { PathEndpoint } from 'src/services/endpoints';
import { APIErrorResponse } from 'src/types';

export interface GetGetImageInUseDetailParams {
  id: string | number;
}

export interface GetGetImageInUseDetailResponse {
  annotations: string[];
  tasks: string[];
  aimodels: string[];
  aitests: string[];
}

export type GetPathInfoLazyQueryOptions = UseMutationOptions<
  GetGetImageInUseDetailResponse,
  APIErrorResponse,
  GetGetImageInUseDetailParams
>;

export function useGetImageInUseDetail(options?: GetPathInfoLazyQueryOptions) {
  return useMutation<GetGetImageInUseDetailResponse, APIErrorResponse, GetGetImageInUseDetailParams>(
    'GetPathInfo_lazy_query',
    (params) => request.get(PathEndpoint.imageInUseDetail(params.id)).then((res) => res.data),
    options,
  );
}
