import React, { useEffect, useState } from 'react';
import { FolderOutSVG } from 'src/assets/icons';
import { Button, SimpleSearchBar } from 'src/components';
import MoveButton from './MoveButton';
import UploadButton from '../UploadSection';
import ListType from 'src/pages/Images/List/ListType';
import { FolderData, ImageData } from 'src/pages/Images/List';
import { useDebounce } from 'src/hooks/useDebounce';
import Checkbox from '../Checkbox';

export interface HeadMenuProps {
  displayType: 'GRID' | 'LIST';
  onDisplayTypeChange: (type?: 'GRID' | 'LIST') => void;
  selectedItems: {
    images: ImageData[];
    folders: FolderData[];
  };
  paths: string[];
  onNewFolderButtonClick?: () => void;
  searchString?: string;
  onSearchStringChange: (val?: string) => void;
  onDeleteSelected: () => void;
  onCreateNewFolder: (folder: FolderData) => void;
  onCompleteMoveImages: () => void;
  onCompleteMovePaths: () => void;

  indeterminate?: boolean;
  selectAll?: boolean;
  onSelectAllChange: (val: boolean) => void;
}

export default function HeadMenu({
  displayType,
  onDisplayTypeChange,
  selectedItems,
  onNewFolderButtonClick,
  paths,
  onDeleteSelected,
  onSearchStringChange,
  onCreateNewFolder,
  onCompleteMoveImages,
  onCompleteMovePaths,

  selectAll,
  onSelectAllChange,
  indeterminate,
}: HeadMenuProps) {
  const [keyword, setKeyword] = useState('');
  const debouncedValKeyword = useDebounce(keyword, 1000);
  useEffect(() => {
    onSearchStringChange(debouncedValKeyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValKeyword]);

  return (
    <div className="flex justify-between relative" style={{ zIndex: 2 }}>
      <div className="flex items-center space-x-10px">
        <Button type="_FFFFFF" className="rounded-full" onClick={onNewFolderButtonClick}>
          <div className="flex space-x-10px">
            <FolderOutSVG className="w-4" /> <span className="text-12px text-inherit font-normal">NEW FOLDER</span>
          </div>
        </Button>
        <div style={{ width: 1, height: 22 }} className="bg-#DDDDDD" />
        <UploadButton paths={paths} />
        <MoveButton
          selectedItems={selectedItems}
          onCreateNewFolder={onCreateNewFolder}
          onCompleteMoveImages={onCompleteMoveImages}
          onCompleteMovePaths={onCompleteMovePaths}
        />
        <Button
          type="_EEEEEE_D"
          className="rounded-full border-none"
          disabled={!selectedItems.images.length && !selectedItems.folders.length}
          onClick={onDeleteSelected}
        >
          <span className="text-12px text-inherit font-normal">DELETE</span>
        </Button>
        <div
          className="flex items-center space-x-4 py-2 px-3 bg-#F5F5F5 cursor-pointer"
          onClick={() => onSelectAllChange(!selectAll)}
        >
          <Checkbox checked={selectAll} onCheckedChange={onSelectAllChange} indeterminate={indeterminate}>
            <span className="text-#707070 text-12px ml-4">Select All</span>
          </Checkbox>
        </div>
      </div>
      <div className="flex space-x-10px">
        <SimpleSearchBar value={keyword} onChange={(e) => setKeyword(e?.target?.value || '')} />
        <ListType value={displayType} onChange={onDisplayTypeChange} />
      </div>
    </div>
  );
}
