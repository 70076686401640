import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { CloseSVG } from 'src/assets/icons';
import './index.less';
import classNames from 'classnames';

type ContextTypes = {
  content: string | ReactNode;
  setContent: React.Dispatch<React.SetStateAction<string | ReactNode>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onOk: () => void;
  setOnOk: React.Dispatch<React.SetStateAction<() => void>>;
  setOnCancel: any;
  width: number;
  setWidth: any;
  footer: ReactNode;
  setFooter: any;
  okButtonProps?: ButtonProps;
  setOkButtonProps?: any;
  cancelButtonProps?: ButtonProps;
  setCancelButtonProps?: any;
  setOkText?: any;
  setCancelText?: any;
  okText?: string;
  cancelText?: string;
  setIsReverseActionButtons?: any;
};

const ConfirmContext = createContext<ContextTypes>({
  visible: false,
  setVisible: () => {},
  content: '',
  setContent: () => {},
  onOk: () => {},
  setOnOk: () => {},
  setOnCancel: () => {},
  width: 450,
  setWidth: () => {},
  footer: '',
  setFooter: () => {},
});

type Props = {
  children: ReactNode;
};

export const NextConfirmProvider = ({ children }: Props) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<string | ReactNode>('');
  const [onOk, setOnOk] = useState<(option?: any) => void>(() => {});
  const [onCancel, setOnCancel] = useState<(option?: any) => void>();
  const [width, setWidth] = useState(450);
  const [footer, setFooter] = useState();
  const [okButtonProps, setOkButtonProps] = useState();
  const [cancelButtonProps, setCancelButtonProps] = useState();
  const [okText, setOkText] = useState('OK');
  const [cancelText, setCancelText] = useState('Cancel');
  const [isReverseActionButtons, setIsReverseActionButtons] = useState('Cancel');

  useEffect(() => {
    if (!visible) {
      setCancelText('Cancel');
      setOkText('OK');
    }
  }, [visible]);

  return (
    <ConfirmContext.Provider
      value={{
        visible,
        setVisible,
        content,
        setContent,
        onOk,
        setOnOk,
        setOnCancel,
        width,
        setWidth,
        footer,
        setFooter,
        okButtonProps,
        cancelButtonProps,
        setOkButtonProps,
        setCancelButtonProps,
        setOkText,
        okText,
        cancelText,
        setCancelText,
        setIsReverseActionButtons,
      }}
    >
      <Modal
        visible={visible}
        title={null}
        closable={false}
        maskClosable={false}
        width={374}
        footer={null}
        bodyStyle={{ padding: 0, borderRadius: 30, overflow: 'hidden' }}
        style={{ top: '30%' }}
        className="modal-rounded-30"
        wrapClassName="z-99999"
      >
        <div className="p-20px flex justify-end">
          <CloseSVG
            className="cursor-pointer"
            onClick={() => (onCancel ? onCancel(() => setVisible(false)) : setVisible(false))}
          />
        </div>
        {content}
        <div className={classNames('flex border-t border-#E2E4E5', { 'flex-row-reverse': isReverseActionButtons })}>
          <div
            className={classNames(
              'w-1/2 flex-center cursor-pointer select-none py-20px border-#E2E4E5',
              isReverseActionButtons ? 'border-l' : 'border-r',
            )}
            onClick={() => (onOk ? onOk(() => setVisible(false)) : setVisible(false))}
          >
            <span className="text-16px text-#000000">{okText}</span>
          </div>
          <div
            className="w-1/2 flex-center cursor-pointer select-none py-20px"
            onClick={() => (onCancel ? onCancel(() => setVisible(false)) : setVisible(false))}
          >
            <span className="text-16px text-#000000">{cancelText}</span>
          </div>
        </div>
      </Modal>
      {children}
    </ConfirmContext.Provider>
  );
};

export const useNextConfirm = () => {
  const {
    setContent,
    setOnOk,
    setVisible,
    setOnCancel,
    setWidth,
    setFooter,
    setOkButtonProps,
    setCancelButtonProps,
    setOkText,
    setCancelText,
    setIsReverseActionButtons,
  } = useContext(ConfirmContext);

  const confirm = (
    content: string | ReactNode,
    action?: {
      onOk: (close: () => void) => void;
      onCancel?: (close: () => void) => void;
      popupWidth?: number;
      footer?: ReactNode;
      okButtonProps?: ButtonProps;
      cancelButtonProps?: ButtonProps;
      setModalType?: any;
      modalType?: string;
      okText?: string;
      cancelText?: string;
      isReverseActionButtons?: boolean;
    },
  ) => {
    setContent(content);
    setVisible(true);

    if (action) {
      const {
        onOk,
        onCancel,
        popupWidth,
        footer,
        okButtonProps,
        cancelButtonProps,
        okText,
        cancelText,
        isReverseActionButtons,
      } = action;

      setOnOk(() => onOk);
      setOnCancel(() => onCancel);
      setWidth(popupWidth);
      setFooter(footer);
      setOkButtonProps(okButtonProps);
      setCancelButtonProps(cancelButtonProps);
      okText && setOkText(okText);
      cancelText && setCancelText(cancelText);
      isReverseActionButtons && setIsReverseActionButtons(true);
    }
  };

  return {
    confirm,
  };
};
