import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AppRoutes } from 'src/helpers';
import { sortedUniq, union } from 'lodash';
import { useSelectDataMlClassesMutation } from 'src/services/mutations/useSelectDataMlClassesMutation';
import { showUnknowErrorMessage } from 'src/utils';
import useCurrentProject from 'src/hooks/useCurrentProject';
import { useEffect } from 'react';
import { selectedPathForTest, useResetTest } from 'src/recoil-stores/create-test';
import { updatingState } from 'src/recoil-stores/create-annotation';
import { useTestQuery } from 'src/services/queries/useTestQuery';
import { TestMethodEnum } from 'src/types';

function useCreateTest() {
  const [selectedPathTraining] = useRecoilState(selectedPathForTest);

  const selectedList = selectedPathTraining.filter((s) => (s.selected && s.count) || (s.images?.length && !s.selected));

  const { projectId, classId, testId = '' } = useParams<{ projectId: string; classId: string; testId: string }>();
  const { data: dataTestDetail } = useTestQuery({ testId: Number(testId) });

  const datasetAimodelClass = dataTestDetail?.dataset_type === 'ANNOTATION_DATA' ? true : undefined;
  const history = useHistory();
  const reset = useResetTest();

  const { mutate: selectDataMlClasses, isLoading } = useSelectDataMlClassesMutation({
    onError: (e: any) => showUnknowErrorMessage(e.message),
  });

  const setUpdating = useSetRecoilState(updatingState);

  useEffect(() => {
    setUpdating(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { data } = useCurrentProject();

  function handleSelectDataForTestClass() {
    const imageIds: number[] = [];

    selectedList
      .filter((s) => s.images?.length)
      .map((s) => s.images)
      .forEach((images) => imageIds.push(...images!));

    const pathIds = selectedList.filter((s) => s.selected).map((s) => s.path!.id);
    const labels = union(...selectedList.map((s) => s.labels || []));

    selectDataMlClasses(
      {
        id: Number(classId),
        images: !datasetAimodelClass ? sortedUniq(imageIds) : [],
        annotations: datasetAimodelClass ? sortedUniq(imageIds) : [],
        path: sortedUniq(pathIds),
        labels: data?.labels.filter((l) => labels.includes(l.name)).map((l) => l.id) || [],
      },
      {
        onSuccess: () => {
          if (dataTestDetail?.testmethod === TestMethodEnum.PREDICTION) {
            history.push(AppRoutes.testsParam(projectId));
          } else {
            history.push(
              AppRoutes.testsClasses.listParam(Number(projectId), Number(testId), Number(dataTestDetail?.aimodel?.id)),
            );
          }
          reset();
        },
      },
    );
  }

  return {
    handleSelectDataForTestClass,
    isLoading,
  };
}

export default useCreateTest;
