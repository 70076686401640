export function hexToRGBA(hex: string, alpha = 1) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${[parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), alpha].join(',')})`
    : hex;
}

export function changeOpacityRGBA(rgba: string, opacity: number = 1) {
  return rgba.replace(/[^,]+(?=\))/, opacity.toString());
}
