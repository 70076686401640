import { Typography } from 'antd';

export const Text = Typography.Text;
export const Title = Typography.Title;
export { default as Spinner } from './Spinner';

export { default as PrivateRoute } from './PrivateRoute';
export { default as ProgressLoading } from './ProgressLoading';
export { default as Button } from './Button';
export { default as InfinityScroll } from './InfinityScroll';
export { default as AppModal } from './AppModal';
export { default as SimpleSearchBar } from './SimpleSearchBar';
export { default as TrashDeleteButton } from './TrashDeleteButton';
export { default as EditButton } from './EditButton';
export { default as SimpleBackButton } from './SimpleBackButton';
