import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'src/helpers/request';
import { AuthEndpoint } from 'src/services/endpoints';
import { APIErrorResponse, ResetPasswordInput, ResetPasswordResponse } from 'src/types';

export type ResetPasswordOptions = UseMutationOptions<ResetPasswordResponse, APIErrorResponse, ResetPasswordInput>;

export default function useResetPasswordMutation(options?: ResetPasswordOptions) {
  return useMutation<ResetPasswordResponse, APIErrorResponse, ResetPasswordInput>(
    (values) => request.post(AuthEndpoint.resetPassword, values).then((res) => res.data),
    options,
  );
}
