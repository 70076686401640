import { Breadcrumb, Switch } from 'antd';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CloseOutLinedSVG } from 'src/assets/icons';
import TextTooltip from 'src/components/TextTooltip';
import { AnnotationEntity } from 'src/types/annotation';
import { ImageEntity } from 'src/types/image';
import { Color } from 'src/utils';

export interface RightBarProps {
  image?: ImageEntity;
  annotations: AnnotationEntity[];
  onHover: Dispatch<SetStateAction<number>>;
  hoverId: number;
  onAnnotationsChange: Dispatch<SetStateAction<AnnotationEntity[]>>;
  paths: {
    name: string;
    id: number;
  }[];
  onShowLabelChange: Dispatch<SetStateAction<boolean>>;
  isShowLabel: boolean;
  onDeleteAnnotations: () => void;
}

export default function RightBar({
  image,
  annotations,
  onHover,
  onAnnotationsChange,
  paths,
  hoverId,
  onShowLabelChange,
  isShowLabel,
  onDeleteAnnotations,
}: RightBarProps) {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEditMode(false);
  }, [image]);

  function handleCancelEdit() {
    setEditMode(false);
    onAnnotationsChange(image?.annotation || []);
  }

  function deleteAnnotations() {
    onDeleteAnnotations();
    setEditMode(false);
  }

  return (
    <div className="flex-shrink-0 h-full" style={{ width: 434 }}>
      <div className="relative p-64px flex flex-col justify-between bg-white rounded-r-30px break-words h-full">
        <div style={{ height: 345 }} className="p-4">
          {!!image?.annotation.length && (
            <div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <span className="text-15px font-light mr-4">View label names</span>
                  <Switch size="small" onChange={onShowLabelChange} checked={isShowLabel} />
                </div>
                {!editMode && (
                  <div
                    className="text-15px font-light cursor-pointer pb-px border-b border-black leading-none hover:text-#35A7CF"
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </div>
                )}
                {editMode && (
                  <div className="flex items-center text-15px font-light cursor-pointer leading-none">
                    <div className="pr-1 border-r hover:text-#35A7CF border-black" onClick={handleCancelEdit}>
                      <div className="pb-px border-b border-black">Cancel</div>
                    </div>
                    <div className="pl-1 hover:text-#35A7CF" onClick={deleteAnnotations}>
                      <div className="pb-px border-b border-black">Save</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="overflow-y-auto overflow-x-hidden" style={{ height: 340 }}>
                {annotations.map((annotation) => (
                  <motion.div
                    key={annotation.id}
                    onHoverStart={() => onHover(annotation.id)}
                    onHoverEnd={() => onHover(-1)}
                    className={classNames(
                      'py-10px pl-8px pr-4 border-t border-#E2E4E5 cursor-pointer flex items-center justify-between',
                      { 'bg-#E2E4E5': hoverId === annotation.id },
                    )}
                  >
                    <div className="text-12px">
                      <TextTooltip
                        className="text-12px"
                        lineClamp={1}
                        textStyle={{
                          color: annotation.label.color === Color.WHITE ? Color.BLACK : annotation.label.color,
                        }}
                      >
                        {annotation.label.name}
                      </TextTooltip>
                    </div>
                    {editMode && (
                      <div
                        className="w-4 h-4 ml-4 flex-shrink-0 rounded-full bg-#F51111 flex-center"
                        onClick={() => onAnnotationsChange(annotations.filter((item) => item.id !== annotation.id))}
                      >
                        <CloseOutLinedSVG className="text-white fill-current w-8px" />
                      </div>
                    )}
                  </motion.div>
                ))}
                <div className="h-px bg-#E2E4E5" />
              </div>
            </div>
          )}
        </div>
        <div>
          <TextTooltip className="text-28px leading-36px font-bold" lineClamp={2}>
            {image?.name}
          </TextTooltip>

          <TextTooltip lineClamp={2}>
            <Breadcrumb separator="/" className="text-20px leading-26px">
              {paths.map((path, index) => {
                return (
                  <Breadcrumb.Item key={index}>
                    <span
                      className={classNames('font-light leading-26px', {
                        'text-#00BEEB font-medium': paths.length - 1 === index,
                      })}
                    >
                      {path?.name}
                    </span>
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </TextTooltip>
        </div>
      </div>
    </div>
  );
}
