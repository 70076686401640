import { stringify } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import useQueryString from './useQuerystring';

export default function useNavigation<T>() {
  const params = useQueryString();
  const history = useHistory();
  const { pathname } = useLocation();

  return {
    navigate: (updated: { [K in keyof T]?: T[K] }) => {
      history.push({
        pathname,
        search: stringify({ ...params, ...updated }),
      });
    },
    reset: () => {
      history.push(pathname);
    },
    replace: (updated: { [K in keyof T]?: T[K] }) => {
      history.replace({
        pathname,
        search: stringify({ ...params, ...updated }),
      });
    },
  };
}
