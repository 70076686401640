import { AnimatePresence, motion } from 'framer-motion';

export interface ToastProps {
  counts: number;
  label?: string;
  keepNo?: boolean;
}

export default function Toast({ keepNo, counts, label = `Item${counts > 1 ? 's' : ''} Selected` }: ToastProps) {
  if (keepNo) {
    return (
      <div className="fixed bottom-40px left-1/2 transform -translate-x-1/2">
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-white py-4 px-35px rounded-full shadow-drop text-20px font-normal"
          >
            {counts || 'No'} {label}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  }

  return (
    <div className="fixed bottom-40px left-1/2 transform -translate-x-1/2">
      <AnimatePresence>
        {counts > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-white py-4 px-35px rounded-full shadow-drop text-20px font-normal"
          >
            {counts || 'No'} {label}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
