import { EditSVG } from 'src/assets/icons';

export interface EditButtonProps {
  onClick: () => void;
  className?: string;
}

export default function EditButton({ onClick, className }: EditButtonProps) {
  return (
    <div className={`inline-block w-24px select-none relative group ${className || ''}`} onClick={onClick}>
      <EditSVG className="w-24px h-26px text-#888888 cursor-pointer group-hover:text-#0E7E99" />
      <div
        className="absolute -bottom-8 left-1/2 text-white text-12px py-1 px-4 bg-#707070 transform -translate-x-1/2 hidden group-hover:block"
        style={{ borderRadius: 4 }}
      >
        EDIT
      </div>
    </div>
  );
}
