import { useMutation, UseMutationOptions } from 'react-query';
import { ImageEndpoint } from '../endpoints';
import { APIErrorResponse, DeleteImagesInput, DeleteImagesResponse } from 'src/types';
import { request } from 'src/helpers/request';
import { stringify } from 'qs';

export type DeleteImagesMutationOptions = UseMutationOptions<DeleteImagesResponse, APIErrorResponse, DeleteImagesInput>;

export default function useDeleteImagesMutation(options?: DeleteImagesMutationOptions) {
  return useMutation<DeleteImagesResponse, APIErrorResponse, DeleteImagesInput>(
    (params) =>
      request
        .delete(ImageEndpoint.images, {
          params,
          paramsSerializer: (params) => {
            return stringify(params, { arrayFormat: 'comma' });
          },
        })
        .then((res) => res.data),
    options,
  );
}
